import axios from "axios";
import { internetBankServerAddress } from "../../common/constant";
import { customerAxiosInstance } from "../../common/axiosInstance";

export function customerRegistrationRequest(postData) {
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/subscription/request`,
    postData,
  );
}

export function customerLogin(email, password) {
  const postData = {
    userLogin: email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/authenticate`,
    postData,
  );
}

export function verifyCustomerOtp(guid, body) {
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/verifyOtp/${guid}`,
    body,
  );
}

export function authForgotPassword(login) {
  return axios.get(
    `${internetBankServerAddress}/api/v1/client/auth/forgot/password/${login}`,
  );
}

export function resetPassword(guid, body) {
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/reset-password/${guid}`,
    body,
  );
}

export function subscribe(body) {
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/subscribe`,
    body,
  );
}

export function getConfigurations() {
  return customerAxiosInstance.get(`/config`);
}

export function listSecurityQuestions() {
  return customerAxiosInstance.get(`/config/listSecurityQuestions`);
}

export function setSecurityQuestions(questionGroups) {
  const body = questionGroups.map((v) => ({
    securityQuestionId: v.questionId,
    securityAns: v.answer,
  }));
  return customerAxiosInstance.post(`/config/setSecurityQuestions`, body);
}

export function updateVerificationQuestions(questionGroups, securityQuestions) {
  const body = questionGroups.map((v) => ({
    securityQuestionId: v.questionId,
    securityAns: v.answer,
  }));
  return customerAxiosInstance.post(`/config/updateSecurityQuestions`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
}

export function getVerificationQuestions() {
  return customerAxiosInstance.get("/config/getVerificationQuestions");
}

export function verifySecurityQuestions(body) {
  return customerAxiosInstance.post("/config/verifySecurityQuestions", body);
}

export function otpVerification(body) {
  return customerAxiosInstance.post(`/otp/verify/${body.guid}`, body);
}

export function updateClientPassword(body, securityQuestions) {
  return customerAxiosInstance.put(`/updatePassword`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
}

export function accountResquest(body) {
  return customerAxiosInstance.post("/auth/account-request", body);
}

export function verifyAccountResquest(uuid) {
  return customerAxiosInstance.get(`/auth/verify-account-request/${uuid}`);
}

export function uploadMedia(uuid, formData) {
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/upload-media/${uuid}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

export function accountIdentification(uuid, body) {
  return axios.post(
    `${internetBankServerAddress}/api/v1/client/auth/account-identification/${uuid}`,
    body,
  );
}

export function resendOtp(uuid, guid) {
  return axios.get(
    `${internetBankServerAddress}/api/v1/client/auth/resend-otp/${uuid}/${guid}`,
  );
}

export function resendOtpSecured(uuid, guid) {
  return customerAxiosInstance.get(`/otp/resend-otp/${uuid}/${guid}`);
}

export function getTermsByCode(code) {
  return axios.get(
    `${internetBankServerAddress}/api/v1/client/auth/find-term/${code}`,
  );
}
