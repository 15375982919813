import React, { useState } from "react";
import PropTypes from "prop-types";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

const PasswordInput = ({
  value,
  onChange,
  placeholder = "",
  name,
  id,
  className,
  ariaLabel,
  autoComplete = "off",
  required,
  disabled,
  maxLength,
  minLength,
  pattern,
  autoFocus,
  showPasswordToggle = false,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [visibleInput, setVisibleInput] = useState(false);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleToggleVisibility = () => {
    setVisibleInput((prev) => !prev);
  };

  // const displayValue = inputValue.replace(/./g, '•')

  return (
    <>
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
        id={id}
        className={`${!visibleInput && "masked-input"} ${className}`}
        aria-label={ariaLabel}
        autoComplete={autoComplete}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        pattern={pattern}
        autoFocus={autoFocus}
        {...props}
      />
      {inputValue && showPasswordToggle && (
        <div
          className="position-absolute"
          style={{
            right: "15px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={handleToggleVisibility}
        >
          {visibleInput ? <RiEyeOffLine size={20} /> : <RiEyeLine size={20} />}
        </div>
      )}
    </>
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  pattern: PropTypes.string,
  showPasswordToggle: PropTypes.bool,
};

export default PasswordInput;
