import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { clientLoginConfirmedAction } from "../../actions/clientAuthActions";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  customerLogin,
  getTermsByCode,
} from "../../services/clientAuthService";
import {
  savePortal,
  saveTokenInSessionStorage,
} from "../../../common/services";
import { CUSTOMERPORTAL } from "../../../common/store/selectors/authSelectors";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
// import * as RemixIcon from 'react-icons/ri';
import * as Spinners from "react-loader-spinner";
import "../../../styles/login.css";
import ImageLogin from "../../../common/images/login/1.svg";
import AppTranslation from "../../../common/jsx/components/AppTranslation";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import i18next from "i18next";
import { encryptData } from "../../../utils/utilsCrypto";
import logo from "../../../common/images/logo/logo.png";
import PasswordInput from "../../../common/jsx/components/PasswordInput";
import { RiKeyLine, RiLoginBoxLine, RiUserLine } from "react-icons/ri";
import { COMPANY_NAME_FULL } from "../../../config";

const CustomerLogin = (props) => {
  const { tb } = AppTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = sessionStorage.getItem("lang");

  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [termsMessage, setTermsMessage] = useState(<></>);
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
  });
  const dispatch = useDispatch();
  const { setShowLoading, showLoading, setSecurityQuestionUrl } =
    useContext(ThemeContext);

  savePortal(CUSTOMERPORTAL);

  useEffect(() => {
    const detectBrowserLanguage = () => {
      const userLang = navigator.language.slice(0, 2); // Get first two letters of user's language
      if (
        !sessionStorage.getItem("lang") ||
        sessionStorage.getItem("lang") === ""
      ) {
        sessionStorage.setItem("lang", userLang);
      }

      i18next.changeLanguage(sessionStorage.getItem("lang"));
    };

    detectBrowserLanguage();
  }, [lang]);

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setErrors((prev) => ({
      ...prev,
      userName: "",
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors((prev) => ({
      ...prev,
      password: "",
    }));
  };

  const onLogin = (e) => {
    e.preventDefault();

    if (userName === "") {
      setErrors((prev) => ({
        ...prev,
        userName: t("login_username_error"),
      }));
    }
    if (password === "") {
      setErrors((prev) => ({
        ...prev,
        password: t("user_pass_error"),
      }));
    } else {
      setShowLoading(true);
      customerLogin(userName, password)
        .then((res) => {
          const resData = res.data.data;
          const dataCrypted = encryptData(resData);
          if (!resData.userDao.firstLogin) {
            resData.portal = CUSTOMERPORTAL;
            navigate(`/auth/otp-verification?m=${dataCrypted}`);
          } else if (resData.userDao.doubleAuthentication) {
            resData.portal = CUSTOMERPORTAL;
            navigate(`/auth/otp-verification?m=${dataCrypted}`);
          } else {
            saveTokenInSessionStorage(resData);
            dispatch(clientLoginConfirmedAction(resData));
            setSecurityQuestionUrl(dataCrypted);
            navigate("/home");
            toast.success(t("Welcome_login"), { position: "top-right" });
          }
        })
        .catch((err) => {
          setErrors({
            userName: "",
            password: "",
          });
          if (err.response) {
            if (err.response.status === 401) {
              toast.warning(err.response.data.message, {
                position: "top-right",
              });
            } else {
              ErrorManager(err, navigate);
            }
          } else {
            toast.warning(t("check_your_internet_connection"), {
              position: "bottom-center",
            });
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const handleShowTermsCondition = () => {
    setShowLoading(true);
    const code = "LOGIN";
    getTermsByCode(code)
      .then((res) => {
        setTermsMessage(res.data.data.text);
        setShowModal(true);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });

  return (
    <div className="conteneur">
      <div className="content">
        <form onSubmit={onLogin} className="left">
          <div className="sm-welcome">
            {/* <h1>{t('login_title ')}</h1> */}
            {/* <p>{t('login_description')}</p> */}
          </div>
          <img src={logo} width="35%" alt="logo" />
          <h2 className="head-title fw-bold">{t("login")}</h2>
          <span
            className="not-account"
            onClick={() => navigate("/auth/subscribe")}
          >
            {t("register_internet_banking")}
          </span>
          <div className="input-box">
            <div className="icon">
              <RiUserLine size={20} />
            </div>
            <input
              type="text"
              value={userName}
              onChange={handleUsernameChange}
              placeholder={t("login_username")}
              autoComplete="off"
              autoFocus
            />
          </div>
          <small className="input-error">{errors.userName}</small>
          <div className="input-box">
            <div className="icon">
              <RiKeyLine size={20} />
            </div>
            <PasswordInput
              value={password}
              onChange={handlePasswordChange}
              placeholder={t("login_userpass")}
              name="password"
              showPasswordToggle={true} // Affiche le toggle pour le mot de passe
              className="form-control-profil"
            />
          </div>
          <small className="input-error">{errors.password}</small>

          <div className="more">
            <span
              className="forgot-password"
              onClick={() => navigate("/auth/forgot-password")}
            >
              {t("forgot_pass")}
            </span>
          </div>
          <p className="termz" onClick={handleShowTermsCondition}>
            {t("agree_terms")}
            <NavLink className="tandc" to="">
              {t("terms_text")}
            </NavLink>
          </p>
          <button className="btn-custom" type="submit" disabled={showLoading}>
            {showLoading ? (
              <Spinners.TailSpin
                height="20"
                width="20"
                ariaLabel="tail-spin-loading"
                radius="4"
                color="#fff"
              />
            ) : (
              <RiLoginBoxLine size={18} />
            )}
            {t("login_button")}
          </button>
          <span
            className="register"
            onClick={() => navigate("/auth/page-register")}
          >
            {t("create_account_button ")}
          </span>
        </form>
        <div className="right">
          <img className="image-login" src={ImageLogin} alt={ImageLogin} />
          <h1 className="fw-bold">{COMPANY_NAME_FULL}</h1>
          <p>{tb("login_page_intro")}</p>
          <button onClick={() => navigate("/auth/page-register")}>
            {t("create_account_button ")}
          </button>
        </div>
      </div>

      <Modal className="fade" size="md" show={showModal} centered>
        <Modal.Header style={{ height: "3rem" }} className="bg-primary">
          <Modal.Title className="text-white">{t("sub_tou")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {termsMessage ? (
            <div dangerouslySetInnerHTML={{ __html: termsMessage }} />
          ) : (
            <h5 className="mb-2 fs-5 text-center">
              {termsMessage ?? t("not_set")}
            </h5>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button className="btn-custom" onClick={() => setShowModal(false)}>
            {t("confirm")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(CustomerLogin);
