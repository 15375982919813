import swal from "sweetalert";
import {
  adminAxiosInstance,
  customerAxiosInstance,
} from "../../common/axiosInstance";
import { t } from "i18next";

export const DELETE_PATH = "DELETE_PATH";
export const DELETE_ROLE = "DELETE_ROLE";
export const ROLES_PATHS = "ROLES_PATHS";

export const DEFAULT_DELETE = "DEFAULT_DELETE";

export function executeGet(path, dispatch, action, callBack) {
  adminAxiosInstance
    .get(path)
    .then((v) => {
      const data = v.data.data;
      callBack(data);
      dispatch({
        payload: v.data.data,
        type: action ?? "GET ACTION",
      });
    })
    .catch((err) => {});
}

export function executeDelete(path, dispatch, action, callBack) {
  swal({
    title: t("Areyousure?"),
    text: t("delete_alert"),
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      adminAxiosInstance
        .delete(path)
        .then((v) => {
          callBack();
          dispatch({
            payload: v.data.data,
            type: action ?? "DELETE ACTION",
          });
        })
        .catch((err) => {
          // Toast({
          //     text: err.message ?? 'error'
          // })
        });
    }
  });
}

export function executeClientDelete(
  path,
  dispatch,
  action,
  callBack,
  questions,
) {
  return swal({
    title: t("Areyousure?"),
    text: t("delete_alert"),
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      return customerAxiosInstance
        .delete(path, {
          headers: {
            "X-User-Security": questions ?? "",
          },
        })
        .then((v) => {
          callBack();
          dispatch({
            payload: v.data.data,
            type: action ?? "DELETE ACTION",
          });
        })
        .catch((err) => {
          // Toast({
          //     text: err.message ?? 'error'
          // })
        });
    }
  });
}
