import React from "react";
import { Link } from "react-router-dom";

import { SVGICON } from "../../constant/theme";

const MainPageTitle = ({ pageTitle, parentTitle, mainTitle }) => {
  return (
    <>
      <div className="page-titles px-5">
        <ol className="breadcrumb">
          <li>
            <h5 className="bc-title">{mainTitle}</h5>
          </li>
          <li className="breadcrumb-item">
            <Link to={"#"}>
              {SVGICON.HomeSvg} {parentTitle}
            </Link>
          </li>
          <li className="breadcrumb-item active">
            <Link to={"#"}>{pageTitle}</Link>
          </li>
        </ol>
      </div>
    </>
  );
};

export default MainPageTitle;
