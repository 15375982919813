export const CUSTOMERPORTAL = "CUSTOMERPORTAL";
export const ADMINPORTAL = "ADMINPORTAL";

export const isAuthenticated = (state) => {
  return !!state.auth.auth.token;
};

export const isAuthenticatedAdmin = (state) => {
  return state.auth.accessPortal === ADMINPORTAL;
};

export const isAuthenticatedCustomer = (state) => {
  return state.auth.accessPortal === CUSTOMERPORTAL;
};
