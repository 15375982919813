import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";
import {
  otpVerification,
  resendOtpSecured,
} from "../services/clientAuthService";
import ErrorManager from "../errors";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { toast } from "react-toastify";
import ModalAfterOperation from "./ModalAfterOperation";

const OtpModal = () => {
  const {
    verifyOtp,
    setVerifyOtp,
    otpVerificationCallBack,
    setOtpVerificationCallBack,
    setShowLoading,
    showLoading,
    setWalletOpLoading,
    setWalletOp,
    walletDeposit,
  } = useContext(ThemeContext);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTimeExpired, setIsTimeExpired] = useState(false);

  const [trialsLeft, setTrialsLeft] = useState(0);
  const [maxTrails, setMaxTrails] = useState(0);
  const [trialsClass, setTrialsClass] = useState("");
  const [msg, setMsg] = useState("");

  const [modalMessage, setModalMessage] = useState("");
  const [status, setStatus] = useState("");

  const [otpDigits, setOtpDigits] = useState(["", "", "", "", ""]);
  const inputRefs = useRef([]);

  const Navigate = useNavigate();

  const validateOtp = (e) => {
    e.preventDefault();

    let otp = otpDigits.join("");
    setShowLoading(true);
    setMsg("");
    otpVerificationCallBack.verificationObject.otp = otp;
    otpVerification(otpVerificationCallBack.verificationObject)
      .then((v) => {
        const data = v.data.data;
        if (data.status !== "PENDING") {
          setStatus(data.status);
          setModalMessage(data.message);
          setVerifyOtp(false);
          setOtpVerificationCallBack(undefined);
          setOtpDigits(["", "", "", "", ""]);
        } else {
          data.walletToBank = false;
          const newWalletOp = { ...walletDeposit, data };

          setVerifyOtp(false);
          setOtpVerificationCallBack(undefined);
          setOtpDigits(["", "", "", "", ""]);
          setWalletOp(newWalletOp);
          setWalletOp(data);
          setWalletOpLoading(true);
        }
      })
      .catch((err) => {
        if (err) {
          if (err.response) {
            if (err.response.status === 403) {
              const object = err.response.data.object;
              setMaxTrails(object.maxTrials);
              setTrialsLeft(object.trials);
              setMsg(err.response.data.message);
            } else if (otpVerificationCallBack.handleError) {
              otpVerificationCallBack.handleError(err);
              setOtpDigits(["", "", "", "", ""]);
            }
          } else {
            ErrorManager(err, Navigate);
            setVerifyOtp(false);
            setOtpVerificationCallBack(undefined);
            setOtpDigits(["", "", "", "", ""]);
          }
        } else {
          ErrorManager(err, Navigate);
          setVerifyOtp(false);
          setOtpVerificationCallBack(undefined);
          setOtpDigits(["", "", "", "", ""]);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setOtpDigits(["", "", "", "", ""]);
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    if (otpVerificationCallBack) {
      setTimeLeft(
        otpVerificationCallBack.verificationObject.minBeforeExpire * 60,
      );
    } else {
      setTimeLeft(0);
    }
  }, [otpVerificationCallBack]);

  useEffect(() => {
    if (timeLeft === 0) {
      // setVerifyOtp(false)
      // setOtpVerificationCallBack(undefined)
      setOtpDigits(["", "", "", "", ""]);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (otpVerificationCallBack) {
      setMaxTrails(otpVerificationCallBack.maxTrials);
      setTrialsLeft(otpVerificationCallBack.trials);
    }
  }, [otpVerificationCallBack]);

  useEffect(() => {
    if (trialsLeft === 1) {
      setTrialsClass("text-danger");
    } else if (trialsLeft < maxTrails / 2) {
      setTrialsClass("text-warning");
    } else {
      setTrialsClass("");
    }
  }, [trialsLeft, maxTrails]);

  const handleChange = (index, e) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOTP = [...otpDigits];
    newOTP[index] = value;
    setOtpDigits(newOTP);

    if (value && index < otpDigits.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const cancelProcess = () => {
    setVerifyOtp(false);
    setOtpDigits(["", "", "", "", ""]);
    setMsg("");
  };

  const handleSubmitResendOtp = (e) => {
    e.preventDefault();
    setIsTimeExpired(false);
    setShowLoading(true);
    setMsg("");
    resendOtpSecured(
      otpVerificationCallBack.verificationObject.uuid,
      otpVerificationCallBack.verificationObject.guid,
    )
      .then((res) => {
        const data = res.data.data;
        setOtpVerificationCallBack(data.authInfo);
        toast.success(t("check_your_email"), { position: "top-right" });
        setTimeout(() => {
          toast.success(t("check_your_email_details"), {
            position: "top-right",
          });
        }, 1000);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          const object = err.response.data.object;
          setMaxTrails(object.maxTrials);
          setTrialsLeft(object.trials);
        } else {
          ErrorManager(err, Navigate);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const isOTPComplete = otpDigits.every((digit) => digit !== "");
  const isButtonDisabled = !isOTPComplete || showLoading || isTimeExpired;

  return (
    <>
      <Modal
        className="fade"
        size="md"
        // show={true}
        show={verifyOtp && otpVerificationCallBack}
        centered
      >
        <Modal.Header
          style={{ height: "3rem" }}
          className="bg-primary rounded-0"
        >
          <Modal.Title className="text-white">
            {t("otp_verification")}
          </Modal.Title>
          {/* <Button variant="" onClick={((e) => setVerifyOtp(false))} className="btn-close"></Button> */}
        </Modal.Header>
        <form onSubmit={validateOtp}>
          <Modal.Body>
            <h5 className="mb-2 fs-5">{t("modal_1time_verify_info")}</h5>
            <div className="inputs d-flex justify-content-center mb-3">
              {otpDigits.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={digit}
                  maxLength="1"
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
            <p className="text-center text-danger">{msg}</p>
            <div className="text-center">
              {timeLeft > 0 ? (
                <span>
                  {t("verify_expire_info")}{" "}
                  <strong>{formatTime(timeLeft)}</strong>
                </span>
              ) : (
                <span>
                  {t("verify_expire_quest")}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={handleSubmitResendOtp}
                  >
                    {" "}
                    {t("click_here_to_resend")}
                  </span>
                </span>
              )}
              <h4 className={trialsClass}>
                {t("verify_trials")} {trialsLeft}
              </h4>
            </div>
          </Modal.Body>
          <Modal.Footer className="rounded-0">
            <button
              className="btn btn-primary btn-sm me-3"
              disabled={isButtonDisabled}
            >
              {t("validate_button")}
            </button>
            <button
              onClick={cancelProcess}
              type={"button"}
              className="btn btn-outline-primary btn-sm"
            >
              {t("cancel_button")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <ModalAfterOperation
        modalMessage={modalMessage}
        status={status}
        setModalMessage={setModalMessage}
      />
    </>
  );
};

export default OtpModal;
