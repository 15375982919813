/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { getCustomerAccounts } from "../../services/acountServices";
import { FormInput } from "../../../common/jsx/components/FormInput";
import Benefit from "../../../common/images/svg/benefit.svg";
import ErrorManager from "../../errors";
import { useNavigate } from "react-router-dom";
import {
  getTransactionBilling,
  makeDiaporaDeposit,
} from "../../services/mobileBankService";
import { t } from "i18next";
import { formatAmount } from "../../../utils/utilsFormattedAmount";

const TYPE_OP = "OACTRF";

const initialInputs = {
  accountID: "",
  currency: "",
  amount: 0,
  memo: "",
  fee_amount: 0,
  tax: 0,
};

const currency = [
  { label: "XAF", value: "XAF" },
  { label: "NGN", value: "NGN" },
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "XOF", value: "XOF" },
  { label: "ZAR", value: "ZAR" },
  { label: "CARD", value: "CARD" },
  { label: "GBP", value: "GBP" },
  { label: "AUD", value: "AUD" },
  { label: "GHS", value: "GHS" },
];

const DiasporaDeposit = (props) => {
  const scrollRef = useRef(null);

  const [optionAccounts, setOptionAccounts] = useState([]);
  // const [hasCurrentAccount, setHasCurrentAccount] = useState(false)
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);
  const [canSubmit, setCanSubmit] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [errorMsg, setErrorMsg] = useState({});
  const [step, setStep] = useState(1);
  const [currencyDb, setCurrencyDb] = useState("");

  const Navigate = useNavigate();

  const getPageData = async () => {
    setShowLoading(true);
    try {
      try {
        const v = await getCustomerAccounts();
        const data = v.data.data;
        setOptionAccounts(
          data.map((act) => ({
            value: act.accountID,
            label: `${act.accountID} -${act.accountName}`,
          })),
        );
        return data;
      } catch (err) {
        if (err.response.status === 422) {
          setErrorMsg({
            accountID: err.response.data.message,
          });
        } else {
          ErrorManager(err, Navigate);
        }
      }
    } finally {
      setShowLoading(false);
    }
  };

  const openNewWindow = (url) => {
    var screenWidth = window.screen.width;
    var screenHeight = window.screen.height;

    var leftPosition = (screenWidth - 1000) / 2;
    var topPosition = (screenHeight - 600) / 2;

    window.open(
      url,
      "_blank",
      "width=1000,height=800,left=" + leftPosition + ",top=" + topPosition,
    );
  };

  useEffect(() => {
    getPageData().then((v) => setShowLoading(false));
  }, []);

  useEffect(() => {
    let val = true;
    Object.keys(inputs).forEach((i) => {
      if (inputs[i] === "" || inputs[i] === undefined || inputs[i] === null) {
        val = false;
      }
    });
    setCanSubmit(val);
  }, [inputs]);

  useEffect(() => {
    setInputs((prevState) => {
      return {
        ...prevState,
        accountType: (
          optionAccounts.find((v) => v.value === inputs.accountID) ?? {}
        ).label,
      };
    });
  }, [inputs.accountID]);

  const executeInternalTransfer = async (securityQuestion) => {
    setShowLoading(true);
    try {
      try {
        const res = await makeDiaporaDeposit(inputs, securityQuestion);
        Navigate("/home");
        openNewWindow(res.data.data.data.links.paymentAuthUrl);
      } catch (err) {
        if (err.response.status === 422) {
          setErrorMsg({
            err: err.response.data.message,
          });
        } else {
          throw err;
        }
      }
    } finally {
      setShowLoading(false);
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    await getTransactionBilling({
      amount: inputs.amount,
      currency: inputs.currency,
      account: inputs.accountID,
      typeOp: TYPE_OP,
    })
      .then((v) => {
        setInputs((prevState) => {
          return {
            ...prevState,
            fee_amount: v.data.data.retAmount,
            tax: v.data.data.mntTva,
          };
        });
        setCurrencyDb(v.data.data.currency);
        setStep(2);
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMsg({
            amount: err.response.data.message,
          });
        } else {
          ErrorManager(err, Navigate);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleConfirmOperation = (e) => {
    e.preventDefault();
    setVerifyCallback(executeInternalTransfer);
    setVerifySecureQuestions(true);
  };

  const handleInputsChange = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      const topPosition =
        scrollRef.current.getBoundingClientRect().top + window.scrollY - 200;
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  }, [step]);

  return (
    <div ref={scrollRef} className="container-fluid">
      <h4 className="fw-bold"> {t("diaspora_deposit")}</h4>
      <div className="card bg-white border-0 shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-4">
              {step === 1 ? (
                <form
                  onSubmit={handleValidate}
                  className="border-custom p-3 h-100 "
                >
                  <div>
                    <div className="col-xl-12">
                      <FormInput
                        name="accountID"
                        label={t("select_account_to_credit")}
                        data={optionAccounts}
                        value={inputs.accountID}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("accountID", e.value)
                        }
                        errorMsg={errorMsg.accountID}
                      />
                    </div>
                    <div className="col-xl-12">
                      <small>{t("diaspora_deposit_quest")}</small>
                      <FormInput
                        name="currency"
                        label={t("currency")}
                        data={currency}
                        value={inputs.currency}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("currency", e.value)
                        }
                      />
                    </div>
                    <div className="col-xl-12">
                      <FormInput
                        value={inputs.amount}
                        onchange={(e) =>
                          handleInputsChange("amount", e.target.value)
                        }
                        label={t("amount")}
                        required={true}
                        name="amount"
                        type="number"
                        errorMsg={errorMsg.amount}
                      />
                    </div>
                    <div className="col-xl-12 ">
                      <FormInput
                        value={inputs.memo}
                        onchange={(e) =>
                          handleInputsChange("memo", e.target.value)
                        }
                        label={t("popos_of_trnfs")}
                        required={true}
                        name="memo"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className=" mt-3 ">
                    <button
                      type="submit"
                      disabled={showLoading || !canSubmit}
                      className="btn btn-primary btn-sm "
                    >
                      {t("submit_button")}
                    </button>
                  </div>
                </form>
              ) : step === 2 ? (
                <div className="border-custom p-3 h-100">
                  <span>{t("you_are_abt_to")} </span>
                  {errorMsg.err && (
                    <p className="mt-3 text-center text-white fs-bold p-2 bg-danger">
                      {errorMsg.err}
                    </p>
                  )}
                  <div className="mt-5 d-flex justify-content-between">
                    <h5>{t("account_number")} </h5>
                    <h5>{inputs.accountID}</h5>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("acc_type_title")}</h5>
                    <h5> {inputs.accountType} </h5>
                  </div>
                  <hr />
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("amount")}</h5>
                    <h5>{formatAmount(inputs.amount, inputs.currency)}</h5>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("trx_fee")} </h5>
                    <h5>{formatAmount(inputs.fee_amount, currencyDb)}</h5>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("value_added_task")}</h5>
                    <h5>{formatAmount(inputs.tax, currencyDb)}</h5>
                  </div>
                  <div className="pt-3">
                    <button
                      className="btn btn-outline-primary btn-sm me-3"
                      onClick={(e) => setStep(1)}
                      type="button"
                    >
                      {t("cancel_button")}
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleConfirmOperation}
                      type="button"
                      disabled={showLoading}
                    >
                      {t("proceed_button")}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-xl-1 mb-5"></div>

            <div className="col-xl-7">
              <div className="card border-0 pb-0">
                <div className="card-body border-0 ">
                  <div className="media me-2 pb-5 d-flex justify-content-center">
                    <img width="100" height="100" src={Benefit} alt="benefit" />
                  </div>
                  <div
                    style={{ height: "300px" }}
                    id="DZ_W_Todo3"
                    className="widget-media dz-scroll height270  ps--active-y"
                  >
                    <ul className="timeline">
                      <li>
                        <p className="pl-5 fs-5 text-center">
                          {t("other_deposit_info")}{" "}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiasporaDeposit;
