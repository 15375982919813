import { t } from "i18next";

export const sortOption = [
  { value: "ASC", label: t("ascending") },
  { value: "DESC", label: t("descending") },
];

export const filterType = [
  { value: "Physique", label: t("individual") },
  { value: "Morale", label: t("bussiness") },
];
