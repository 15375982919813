/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getAccountConfiguration,
  saveClientConfig,
  updateClientConfig,
} from "../../services/acountServices";
import { useQuery } from "../../../common/services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
import { useTranslation } from "react-i18next";

const CustomNotification = () => {
  const { t } = useTranslation();

  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const [errors, setErros] = useState({});
  const [initConfig, setInitConfig] = useState({
    id: undefined,
    alertAmount: false,
    minAmount: 0,
    maxAmount: 0,
    alertTrans: false,
    transLimit: 0,
  });

  const handleValueChange = (e) => {
    const { name, value, type, checked } = e.target;

    setInitConfig((prevInitConfig) => ({
      ...prevInitConfig,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const query = useQuery();
  const Navigate = useNavigate();

  const accountId = query.get("an");
  const id = query.get("idc");

  const handleSaveConfig = () => {
    const minAmount = Number(initConfig.minAmount);
    const maxAmount = Number(initConfig.maxAmount);
    const transLimit = Number(initConfig.transLimit);

    if (initConfig.alertAmount && !initConfig.alertTrans) {
      if (minAmount >= maxAmount) {
        setErros((prevState) => ({
          ...prevState,
          amount: t("min_max_value"),
        }));
      } else {
        initConfig.accountNumber = accountId;
        setShowLoading(true);
        saveClientConfig(initConfig)
          .then((res) => {
            Navigate(`/notification`);
            toast.success("Success", { position: "top-right" });
          })
          .catch((err) => {
            ErrorManager(err, Navigate);
          })
          .finally(() => {
            setShowLoading(false);
          });
      }
    } else if (!initConfig.alertAmount && initConfig.alertTrans) {
      if (transLimit <= 0) {
        setErros((prevState) => ({
          ...prevState,
          transLimit: t("min_max_value"),
        }));
      } else {
        initConfig.accountNumber = accountId;
        setShowLoading(true);
        saveClientConfig(initConfig)
          .then((res) => {
            Navigate(`/notification`);
            toast.success("Success", { position: "top-right" });
          })
          .catch((err) => {
            ErrorManager(err, Navigate);
          })
          .finally(() => {
            setShowLoading(false);
          });
      }
    } else if (initConfig.alertAmount && initConfig.alertTrans) {
      if (transLimit <= 0 || minAmount >= maxAmount) {
        setErros((prevState) => ({
          ...prevState,
          amount: t("min_max_value"),
          transLimit: t("min_max_value"),
        }));
      } else {
        initConfig.accountNumber = accountId;
        setShowLoading(true);
        saveClientConfig(initConfig)
          .then((res) => {
            Navigate(`/notification`);
            toast.success("Success", { position: "top-right" });
          })
          .catch((err) => {
            ErrorManager(err, Navigate);
          })
          .finally(() => {
            setShowLoading(false);
          });
      }
    }
  };

  const handleUpdateConfig = () => {
    const minAmount = Number(initConfig.minAmount);
    const maxAmount = Number(initConfig.maxAmount);
    const transLimit = Number(initConfig.transLimit);

    if (minAmount >= maxAmount || transLimit <= 0) {
      if (minAmount >= maxAmount) {
        setErros((prevState) => ({
          ...prevState,
          amount: t("min_max_value"),
        }));
      }

      if (transLimit <= 0) {
        setErros((prevState) => ({
          ...prevState,
          transLimit: t("limit_value"),
        }));
      }
    } else {
      setShowLoading(true);
      updateClientConfig(initConfig)
        .then((res) => {
          Navigate(`/notification`);
          toast.success("Success", { position: "top-right" });
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const getListAccount = () => {
    if (id > 0) {
      setShowLoading(true);
      getAccountConfiguration(id)
        .then((res) => setInitConfig(res.data.data))
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  useEffect(() => {
    getListAccount();
  }, []);

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{t("custom_notification")}</h4>
      <div className="row top-50">
        <div className="col-xl-12">
          <div className="card bg-white border-0 shadow">
            <div className="card-body">
              <div className="row ">
                <div className="col-xl-8 pb-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={initConfig.alertAmount}
                      name="alertAmount"
                      id="flexCheckChecked"
                      checked={initConfig.alertAmount}
                      onChange={handleValueChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      {t("receive_notification_specific_amt")}
                    </label>

                    {initConfig.alertAmount && ( // Conditionally render controls based on state
                      <div className="border-custom p-3">
                        <div className="d-flex">
                          <div className="me-2">
                            <label className="me-2 form-label">
                              {t("min_value")}
                            </label>
                            <input
                              className="form-control rounded-0"
                              value={initConfig.minAmount}
                              name="minAmount"
                              onChange={handleValueChange}
                            />
                          </div>
                          <div>
                            <label className="me-2 form-label">
                              {t("max_value")}
                            </label>
                            <input
                              className="form-control rounded-0"
                              value={initConfig.maxAmount}
                              name="maxAmount"
                              onChange={handleValueChange}
                            />
                          </div>
                        </div>
                        <span className="text-danger">{errors.amount}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-8 pb-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="alertTrans"
                      value={initConfig.alertTrans}
                      id="flexCheckChecked2"
                      checked={initConfig.alertTrans} // Set checked attribute based on state
                      onChange={handleValueChange} // Toggle state on change
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked2"
                    >
                      {t("receive_notification_no_trx")}
                    </label>

                    {initConfig.alertTrans && ( // Conditionally render controls based on state
                      <div className="border-custom p-3">
                        <div className="d-flex me-2">
                          <div>
                            <label className="me-2 form-label">
                              {t("number_transaction")}
                            </label>
                            <input
                              className="form-control rounded-0"
                              value={initConfig.transLimit}
                              name="transLimit"
                              onChange={handleValueChange}
                            />
                          </div>
                        </div>
                        <span className="text-danger">{errors.transLimit}</span>
                      </div>
                    )}
                  </div>
                  {id > 0 ? (
                    <button
                      className="btn btn-primary btn-sm rounded-0 mt-2"
                      onClick={handleUpdateConfig}
                      disabled={showLoading}
                    >
                      {t("update_button")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-sm rounded-0 mt-2"
                      onClick={handleSaveConfig}
                      disabled={showLoading}
                    >
                      {t("save_button")}
                    </button>
                  )}
                </div>
                <div className="col-xl-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomNotification;
