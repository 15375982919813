import axios from "axios";
import swal from "sweetalert";
import { adminAxiosInstance } from "../../common/axiosInstance";
import { internetBankServerAddress } from "../../common/constant";

export function login(email, password) {
  const postData = {
    userLogin: email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `${internetBankServerAddress}/api/v1/admin/auth/authenticate`,
    postData,
  );
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function setModuleInLocalStorage(moduleId) {
  sessionStorage.setItem("moduleId", JSON.stringify(moduleId));
}

//authorization
export function getAuthorizedMenus(moduleId) {
  return adminAxiosInstance.get(`/authz/authorized-menus/${moduleId}`);
}

export function getAppPaths() {
  return adminAxiosInstance.get(`/authz/listAllPaths`);
}

export function getAppRoles() {
  return adminAxiosInstance.get(`/authz/listAllRoles`);
}

export function getAuthorizedModules() {
  return adminAxiosInstance.get(`/authz/authorized-modules`);
}

export function getInstitutionConfig() {
  return adminAxiosInstance.get("/instConfig");
}

export function getAllSecurityQuestions() {
  return adminAxiosInstance.get("/instConfig/listQuestions");
}

export function listAllUsers() {
  return adminAxiosInstance.get("/user/listAll");
}

export function saveNewUser(formData) {
  return adminAxiosInstance.post("/user/create", formData);
}

export function listNotificationConfigs() {
  return adminAxiosInstance.get("/instConfig/list-templates");
}

export function updateNotificationTemplate(data) {
  return adminAxiosInstance.put(
    "/instConfig/update-notification-template",
    data,
  );
}

export function listTermsAndConditions() {
  return adminAxiosInstance.get("/instConfig/list-term");
}

export function updateTermsAndConditions(data) {
  return adminAxiosInstance.put("/instConfig/update-term", data);
}

export function listTransactionMessage() {
  return adminAxiosInstance.get("/instConfig/list-transaction-message");
}

export function updateTransactionMessage(id, text) {
  return adminAxiosInstance.put("/instConfig/update-transaction-message", {
    id,
    text,
  });
}
