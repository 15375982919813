/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { Modal } from "react-bootstrap";
import { DataTable } from "../../../common/jsx/components/DataTable";
import ReactQuill from "react-quill";
import {
  listTermsAndConditions,
  updateTermsAndConditions,
} from "../../services/authService";
import { ThemeContext } from "../../../context/ThemeContext";
import { RiPencilLine } from "react-icons/ri";
import ErrorManager from "../../../appClient/errors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [termsTemplate, setTermsTemplate] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [templateType, setTemplateType] = useState();
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableHeaders = [
    { label: t("event_code") },
    { label: t("status") },
    { label: t("actions") },
  ];

  const editTemplate = (id, type) => {
    const event = termsAndConditions.find((v) => v.id === id);
    if (event) {
      setSelectedEvent((prevState) => {
        return event;
      });
      setTemplateType(type);
    }
  };

  useEffect(() => {
    setShowLoading(true);
    getPageData();
  }, []);

  useEffect(() => {
    setTermsTemplate(
      termsAndConditions.map((templ) => [
        templ.code,
        templ.text ? t("CONFIGURED") : t("NOT_CONFIGURED"),
        <button
          type="button"
          onClick={(e) => editTemplate(templ.id, true)}
          className="btn text-primary btn-sm"
        >
          <RiPencilLine />
        </button>,
      ]),
    );
    setShowLoading(false);
  }, [editTemplate, termsAndConditions]);

  const getPageData = () => {
    setShowLoading(true);
    return listTermsAndConditions()
      .then((v) => {
        const data = v.data.data;
        setTermsAndConditions(data);
        return data;
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => setShowLoading(false));
  };

  const handleTemplateChange = (value) => {
    setSelectedEvent((prevState) => {
      return {
        ...prevState,
        text: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateTermsAndConditions(selectedEvent)
      .then((v) => {
        toast.success(t("save_term_message"));
        getPageData();
        setTemplateType(false);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      });
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  return (
    <>
      <MainPageTitle
        mainTitle={t("Configurations")}
        pageTitle={t("terms_and_conditions_menu")}
        parentTitle={t("terms_and_conditions_menu")}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card bg-white border-0 shadow">
              <div className="card-body">
                <div className="custom-tab-1 bg-white">
                  <DataTable
                    tableData={termsTemplate}
                    headersTitle={tableHeaders}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" size="lg" show={templateType} centered>
        <Modal.Header className="bg-primary rounded-0">
          <Modal.Title className="text-white">
            {t("set_term_and_condition")} : {selectedEvent.code}{" "}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="row">
            <div className="col-xl-12" style={{ minHeight: "250px" }}>
              <ReactQuill
                id="templateContent"
                value={selectedEvent.text ?? ""}
                onChange={handleTemplateChange}
                name="template"
                formats={formats}
                className="h-75"
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                    [{ color: [] }, { background: [] }], // Add color options
                  ],
                }}
                placeholder={t("compose_your_text")}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="rounded-0">
            <button
              className="btn btn-primary btn-sm me-3"
              disabled={selectedEvent.text === "" || showLoading}
            >
              {t("save_button")}
            </button>
            <button
              type={"button"}
              onClick={(e) => setTemplateType(false)}
              className="btn btn-outline-primary btn-sm"
            >
              {t("cancel_button")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default TermsAndConditions;
