import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Alert from "../../common/images/icon/alerte.png";
import Success from "../../common/images/icon/succes.png";

const ModalAfterOperation = ({ modalMessage, status, setModalMessage }) => {
  const Navigate = useNavigate();
  const getImageStatus = () => {
    if (status === "CANCELLED" || status === "FAILED") {
      return Alert;
    } else if (status === "SUCCESS") {
      return Success;
    } else {
      return Alert;
    }
  };

  const okButton = () => {
    setModalMessage("");
    Navigate("/home");
  };

  return (
    <Modal
      className="fade"
      size="md"
      show={modalMessage ? true : false}
      centered
    >
      <Modal.Body className="row ">
        <div className="col-xl-12 d-flex gap-2 text-center flex-column justify-content-center align-items-center mb-3 ">
          <img
            className="object-fit-cover"
            src={getImageStatus()}
            alt="icone message"
            width={100}
          />
          <p className="ps-3 pe-3">{modalMessage}</p>
          <button className="btn btn-dark" onClick={okButton}>
            Ok
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAfterOperation;
