/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { Modal } from "react-bootstrap";
import { DataTable } from "../../../common/jsx/components/DataTable";
import {
  listTransactionMessage,
  updateTransactionMessage,
} from "../../services/authService";
import { ThemeContext } from "../../../context/ThemeContext";
import { RiPencilLine } from "react-icons/ri";
import ErrorManager from "../../../appClient/errors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import SearchInputFilter from "../../../common/jsx/components/SearchInputFilter";
import SelectOptionFilter from "../../../common/jsx/components/SelectOptionFilter";
import { sortOption } from "../../../data/optionFilter";

const TransactionMessages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const [transactionMessages, setTransactionMessages] = useState([]);
  const [termsTemplate, setTermsTemplate] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [templateType, setTemplateType] = useState();
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("asc");
  const [filter, setFilter] = useState("typeOp");
  const [search, setSearch] = useState("");
  const [textOperation, setTextOperation] = useState("");

  // FILTER SELECT TAG DATA
  const filterOptions = [
    { value: "typeOp", label: t("type_operation") },
    { value: "status", label: t("status") },
  ];

  const statusType = [
    { value: "", label: t("all") },
    { value: "CANCELLED", label: t("cancelled") },
    { value: "SUCCESS", label: t("approuved") },
    { value: "FAILED", label: t("failed") },
  ];
  const tableHeaders = [
    { label: t("type_operation") },
    { label: t("text_content") },
    { label: t("status") },
    { label: t("actions") },
  ];

  useEffect(() => {
    setShowLoading(true);
    getPageData();
  }, [status, order, filter, search]);

  useEffect(() => {
    setTermsTemplate(
      transactionMessages.map((templ) => [
        templ.typeOp,
        templ.text ? t("CONFIGURED") : t("NOT_CONFIGURED"),
        templ.status,
        <button
          type="button"
          onClick={(e) => editTemplate(templ.id, true)}
          className="btn text-primary btn-sm"
        >
          <RiPencilLine />
        </button>,
      ]),
    );
    setShowLoading(false);
  }, [transactionMessages]);

  const getPageData = () => {
    setShowLoading(true);
    return listTransactionMessage()
      .then((v) => {
        const data = v.data.data;
        let filteredData = data;

        // Filtrer par statut
        if (status) {
          filteredData = filteredData.filter((item) => item.status === status);
        }

        // Filtrer par recherche
        if (search) {
          filteredData = filteredData.filter((item) =>
            item[filter].toLowerCase().includes(search.toLowerCase()),
          );
        }

        // Trier par ordre
        filteredData.sort((a, b) => {
          const valueA = a[filter].toLowerCase();
          const valueB = b[filter].toLowerCase();

          if (order === "asc") {
            return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
          } else {
            return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
          }
        });

        setTransactionMessages(filteredData);
        return filteredData;
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => setShowLoading(false));
  };

  const editTemplate = (id, type) => {
    const event = transactionMessages.find((v) => v.id === id);
    if (event) {
      setSelectedEvent(event);
      setTextOperation(event.text || "");
      setTemplateType(type);
    }
  };

  const handleTemplateChange = (value) => {
    setSelectedEvent((prevState) => ({
      ...prevState,
      text: value,
    }));
    setTextOperation(value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleOrderChange = (e) => {
    setOrder(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateTransactionMessage(selectedEvent.id, selectedEvent.text)
      .then((v) => {
        toast.success(t("configured_message"));
        getPageData();
        setTemplateType(false);
        setSelectedEvent({});
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      });
  };

  const getStatus = (status) => {
    if (status === "SUCCESS") {
      return " bg-success";
    } else if (status === "CANCELLED") {
      return " bg-warning";
    } else if (status === "FAILED") {
      return " bg-danger";
    } else {
      return " bg-secondary";
    }
  };

  return (
    <>
      <MainPageTitle
        mainTitle={t("Configurations")}
        pageTitle={t("status_message_menu")}
        parentTitle={t("status_message_menu")}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card p-3 pb-0 bg-white border-0 shadow">
              <div className="option-filter-table">
                <SelectOptionFilter
                  label={t("status")}
                  id="status"
                  name={status}
                  value={status}
                  onChange={handleStatusChange}
                  options={statusType}
                />

                <SelectOptionFilter
                  label={t("sort_by")}
                  id="sort"
                  name={order}
                  value={order}
                  onChange={handleOrderChange}
                  options={sortOption}
                />

                <SelectOptionFilter
                  label={t("filter_by")}
                  id="filter"
                  name={filter}
                  value={filter}
                  onChange={handleFilterChange}
                  options={filterOptions}
                />

                <SearchInputFilter
                  filter={filter}
                  placeholder={t("type_here")}
                  ariaLabel="Search"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="card-body p-0">
                <div className="custom-tab-1 bg-white">
                  <DataTable
                    tableData={termsTemplate}
                    headersTitle={tableHeaders}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" size="md" show={templateType} centered>
        <Modal.Header className="bg-primary rounded-0">
          <Modal.Title className="text-white">
            {t("details_typeOp")} : {selectedEvent.typeOp}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="row p-4">
            <div className="p-0">
              <span className={"border p-2 " + getStatus(selectedEvent.status)}>
                <strong>{t("status")}:</strong> {selectedEvent.status}
              </span>
            </div>
            <label htmlFor="text" className="p-0 mt-3 fw-bold">
              {t("text_content")} :
            </label>
            <textarea
              className="border p-2"
              value={textOperation}
              name=""
              id="text"
              onChange={(e) => handleTemplateChange(e.target.value)}
            ></textarea>
          </Modal.Body>
          <Modal.Footer className="rounded-0">
            <button
              className="btn btn-primary btn-sm me-3"
              disabled={selectedEvent.text === "" || showLoading}
            >
              {t("save_button")}
            </button>
            <button
              type="button"
              onClick={(e) => setTemplateType(false)}
              className="btn btn-outline-primary btn-sm"
            >
              {t("cancel_button")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default TransactionMessages;
