import React from "react";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { RiUser3Line } from "react-icons/ri";
import { t } from "i18next";

const SecurityDashBoard = () => {
  return (
    <>
      <MainPageTitle
        mainTitle="Dashboard"
        pageTitle={t("Dashboard")}
        parentTitle="Home"
      />
      <div className="container-fluid">
        <div className="row gap-4 p-3 pt-0">
          <div className="col-xl-9 flex-grow-1">
            <div className="row gap-4">
              <div className="col-xl-5 d-flex justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="details d-flex flex-column gap-3">
                  <span>{t("total_user")}</span>
                  <span>0</span>
                </div>
                <RiUser3Line size={50} />
              </div>
            </div>
          </div>
          {/* <div className='col-xl-5 d-flex flex-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow'>

					</div> */}
        </div>
      </div>
    </>
  );
};

export default SecurityDashBoard;
