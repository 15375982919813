import {
  CLIENT_LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  OTP_VERIFICATION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
} from "../../common/store/actionTypes";

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function clientLoginConfirmedAction(data) {
  return {
    type: CLIENT_LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function clientFistLoginConfirmedAction(data) {
  return {
    type: OTP_VERIFICATION,
    payload: data,
  };
}

export function clientDoubleAuthenticationAction(data) {
  return {
    type: OTP_VERIFICATION,
    payload: data,
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}
