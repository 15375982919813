/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CheckboxTree from "react-checkbox-tree";
import { organizeCheckedNodes } from "../../constants";
import { getAppPaths, getAppRoles } from "../../services/authService";
import {
  formPost,
  submitFailed,
  submitSuccess,
} from "../../services/postForms";
import {
  DELETE_ROLE,
  executeDelete,
  executeGet,
  ROLES_PATHS,
} from "../../services/service";
import { ThemeContext } from "../../../context/ThemeContext";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { DataTable } from "../../../common/jsx/components/DataTable";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import * as RemixIcon from "react-icons/ri";
import ErrorManager from "../../../appClient/errors";
import { t } from "i18next";

const tableHeaders = [
  { label: "name" },
  { label: "status" },
  { label: "action" },
];

const UserRoles = () => {
  const [roleListData, setRoleListData] = useState([]);
  const [addRole, setAddRole] = useState(false);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [inputs, setInputs] = useState({});
  const [formAction, setFormAction] = useState("/authz/save-roles");
  const [roleName, setRoleName] = useState("");
  const [modules, setModules] = useState([]);

  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const setEdithRole = (data) => {
    setChecked(data.paths.map((p) => String(p.id)));
    setExpanded(data.paths.map((m) => String(m.id)));
    setRoleName(data.name);
    setInputs({
      title: (
        <>
          Edith Role{" "}
          <span className="badge badge-success light border-0">
            {" "}
            {data.name}
          </span>
        </>
      ),
    });
    setFormAction(`/authz/update-role/${data.id}`);
    setAddRole(true);
  };

  const toggleRoleForm = (show) => {
    setChecked([]);
    setExpanded([]);
    setInputs({});
    setRoleName("");
    setFormAction("/authz/save-roles");
    setAddRole(show);
  };

  const getPageData = () => {
    setShowLoading(true);
    getAppPaths()
      .then((v) => {
        const data = v.data.data;
        const modu = data
          .filter((v) => v.type === "MODULE")
          .sort((a, b) => b.menuLevel - a.menuLevel)
          .map((p) => ({
            value: p.id,
            label: p.name,
            children: data
              .filter(
                (m) =>
                  (m.type === "MENU" &&
                    m.parent === null &&
                    (m.module ?? {}).id === p.id) ||
                  (m.type === "MENU" &&
                    m.parent === null &&
                    (m.module ?? {}).id === p.id),
              )
              .sort((a, b) => a.menuLevel - b.menuLevel)
              .map((elmt) => ({
                value: elmt.id,
                label: elmt.name,
                children:
                  elmt.menuLevel === "DOUBLE"
                    ? data
                        .filter((c) => (c.parent ?? {}).id === elmt.id)
                        .sort((a, b) => a.menuLevel - b.menuLevel)
                        .map((ch) => ({
                          value: ch.id,
                          label: ch.name,
                          children:
                            data.filter(
                              (cch) => (cch.parent ?? {}).id === ch.id,
                            ).length > 0
                              ? data
                                  .filter(
                                    (cch) => (cch.parent ?? {}).id === ch.id,
                                  )
                                  .sort((a, b) => a.menuLevel - b.menuLevel)
                                  .map((lc) => ({
                                    value: lc.id,
                                    label: lc.name,
                                  }))
                              : undefined,
                        }))
                    : undefined,
              })),
          }));
        setModules(modu);
        dispatch(submitSuccess(data, ""));
      })
      .catch((err) => {
        dispatch(submitFailed({}, ""));
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });

    getAppRoles()
      .then((v) => {
        const data = v.data.data;
        setRoleListData(
          data.map((r) => [
            r.name,
            "Active",
            <div className="action-button">
              <button
                type="button"
                onClick={() =>
                  executeGet(
                    `/authz/getRolePaths/${r.id}`,
                    dispatch,
                    ROLES_PATHS,
                    setEdithRole,
                  )
                }
                className="btn text-primary btn-icon-xxs"
              >
                <RemixIcon.RiPencilLine />
              </button>{" "}
              <button
                type="button"
                onClick={() =>
                  executeDelete(
                    `/authz/deleteRole/${r.id}`,
                    dispatch,
                    DELETE_ROLE,
                    getPageData,
                  )
                }
                className="btn text-danger btn-icon-xxs"
              >
                <RemixIcon.RiDeleteBin2Line />
              </button>
            </div>,
          ]),
        );
      })
      .catch((err) => {
        dispatch(submitFailed({}, ""));
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
    setAddRole(false);
  };

  useEffect(() => {
    getPageData();
    setShowLoading(true);
  }, []);

  const icons = {
    check: <RemixIcon.RiCheckboxLine />,
    uncheck: <RemixIcon.RiCheckboxBlankLine />,
    halfCheck: <RemixIcon.RiCheckboxBlankLine />,
    expandClose: <RemixIcon.RiArrowDropRightLine size="20" />,
    expandOpen: <RemixIcon.RiArrowDropDownLine size={90} />,
    expandAll: <span className="rct-icon rct-icon-expand-all" />,
    collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
    parentClose: <RemixIcon.RiFolder2Line size="20" />,
    parentOpen: <RemixIcon.RiFolderOpenLine size="20" />,
    leaf: <RemixIcon.RiFile3Line size="20" />,
  };

  const onCheck = (newchecked) => {
    const addParents = (parent) => {
      if (parent.children) {
        if (
          parent.children.some((child) =>
            newchecked.includes(String(child.value)),
          )
        ) {
          newchecked = [String(parent.value), ...newchecked];
          // parent.children.forEach((p) => addParents(p))
        } else {
          newchecked = newchecked.filter((v) => v !== String(parent.value));
          parent.children.forEach((p) => addParents(p));
        }
      }
    };

    modules.forEach((node) => addParents(node));
    setChecked(newchecked);
  };

  const onNameChange = (event) => {
    setRoleName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);
    const organized = organizeCheckedNodes(modules, checked);
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    const formAction = event.target.getAttribute("action");
    data.modules = organized;
    formPost(formAction, data, "ROLE_CREAYED")
      .then((res) => {
        getPageData();
      })
      .catch((err) => {
        // Toast({ text: err.message ?? 'failed' })
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    setShowLoading(false);
  }, []);

  return (
    <>
      <MainPageTitle
        mainTitle="Dashboard"
        pageTitle={"User Roles"}
        parentTitle={"Users Manager"}
      />
      <div className="container-fluid">
        <div className="row p-3 pt-0">
          <div className="col-xl-8 col-lg-8 bg-white shadow pt-3">
            <div className="row">
              <div className="col-xl-12 h-100">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="heading mb-3">{t("role")}</h4>
                  <Link
                    to={"#"}
                    className="btn btn-primary btn-sm mb-3"
                    onClick={() => toggleRoleForm(true)}
                  >
                    + {t("addarole")}
                  </Link>
                </div>
                <DataTable
                  tableData={roleListData}
                  headersTitle={tableHeaders}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas
        className="offcanvas offcanvas-end bg-white"
        show={addRole}
        onHide={() => toggleRoleForm(false)}
        placement="end"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="modal-title" id="#gridSystemModal">
            {inputs.title ?? t("addarole")}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => toggleRoleForm(false)}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <form action={formAction} onSubmit={(event) => handleSubmit(event)}>
              <div className="col-xl-12">
                {" "}
                <FormInput
                  value={roleName}
                  onchange={onNameChange}
                  label={t("rolename")}
                  name="name"
                />{" "}
              </div>

              <h4 className="heading">{t("UserAccesslevels")}</h4>
              <div className="mb-3">
                <CheckboxTree
                  nodes={modules}
                  checked={checked}
                  expanded={expanded}
                  onCheck={(newChecked) => onCheck(newChecked)}
                  onExpand={(newExpanded) => setExpanded(newExpanded)}
                  icons={icons}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm me-1"
                  disabled={!roleName || showLoading ? true : false}
                >
                  {t("saverole")}
                </button>
                <Link
                  to={"#"}
                  onClick={() => toggleRoleForm(false)}
                  className="btn btn-outline-danger btn-sm ms-1"
                >
                  {t("cancel_button")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default UserRoles;
