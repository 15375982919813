import { format, parseISO } from "date-fns";
import { BACKEND_ADDRESS } from "../../config";

export const internetBankServerAddress = BACKEND_ADDRESS;
// https://dev-api.iglobalfinance.com
// http://localhost:8081
export const mobileBankServerAddress = "173.248.133.175:8081";

export const baseUrlClient = "/";
export const baseUrlAdmin = "/adminportal";

export const kefy =
  "bvnbwhrbh+pöüöäöpnnvvf4a9c3e8b2d7f6a9e1b4c5d7e8f9a0b15e6f7gvlliezzuugjdbcjdbjfugfhnieö3";

export const clientOtpTokenKey =
  "f4a9c3e8b2d7f6a9e1b4c5d7e8f9a0b1c2d3e4f5a6b7c8d9e0f1a2b3c4d5e6f7";
export const userDetailsKey =
  "e3d1c4a7b8f9e0a2d3c4b5f6a7e8d1b2c3e4f5a6b7c8d9e0f1a2b3c4d5e6f7a8";
export const accesPortalKey =
  "4d2c3e5f6a7b8c9d0e1f2a3b4c5d6e7f8a9b0c1d2e3f4a5b6c7d8e9f0a1b2c3";
export const moduleIdKey =
  "9f8e7d6c5b4a392817f6e5d4c3b2a1f0e9d8c7b6a5f4e3d2c1b0a9e8d7c6b5a4";

export const currentMonthFirstDate = () => {
  const date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const stringDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "dd MMM yyyy");
};

export const accountNumberLength = 11;
