/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getCustomerAccounts,
  listCustomerChecksByAccount,
  stopCheckPayment,
} from "../../services/acountServices";
import { Modal } from "react-bootstrap";
import { FormInput } from "../../../common/jsx/components/FormInput";
import Benefit from "../../../common/images/svg/benefit.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
import { useTranslation } from "react-i18next";
import * as Spinners from "react-loader-spinner";

const initialInputs = {
  accountId: "",
  checkNumber: "0",
  reason: "",
  blockPeriod: "-1",
  checkbookNumber: "",
};

const StopCheck = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();

  // const [accountList, setAccountsList] = useState([])
  const [optionAccounts, setOptionAccounts] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const [hasCurrentAccount, setHasCurrentAccount] = useState(true);
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);
  const [canSubmit, setCanSubmit] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [isCustomPeriod, setIsCustomPeriod] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const reasons = [
    {
      label: t("stolen_missing"),
      value: "stolen/ missing",
    },
    {
      label: t("exhausted"),
      value: "exhausted",
    },
    {
      label: t("new_checkbook"),
      value: "new checkbook",
    },
  ];

  const periods = [
    {
      label: t("not_set"),
      value: "-1",
    },
    {
      label: t("1_week"),
      value: "7",
    },
    {
      label: t("10_days"),
      value: "10",
    },
    {
      label: t("30_days"),
      value: "30",
    },
    {
      label: t("custom"),
      value: "0",
    },
  ];

  const getPageData = () => {
    setShowLoading(true);
    return getCustomerAccounts()
      .then((v) => {
        const data = v.data.data;
        // setAccountsList(data)
        const currentAccounts = data.filter(
          (v) => v.productType.trim() === "CURRENT",
        );
        if (currentAccounts.length === 0) {
          setHasCurrentAccount(false);
        } else {
          setOptionAccounts(
            currentAccounts.map((act) => ({
              value: act.accountID,
              label: `${act.accountID} -${act.accountName}`,
            })),
          );
          setHasCurrentAccount(true);
        }

        return data;
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setInitialLoad(false);
      });
  };

  // const closeCheckBookPage = () => {
  //     Navigate('/home')
  // }

  useEffect(() => {
    getPageData();
  }, []);

  useEffect(() => {
    let val = true;
    Object.keys(inputs).forEach((i) => {
      if (inputs[i] === "") {
        val = false;
      }
    });
    setCanSubmit(val);
  }, [inputs]);

  useEffect(() => {
    if (inputs.accountId) {
      setShowLoading(true);
      listCustomerChecksByAccount(inputs.accountId)
        .then((v) => {
          const data = v.data.data;
          setCheckList(
            data.map((c) => ({
              label: `${c.startSeries}-${c.endSeries}`,
              value: `${c.startSeries}-${c.endSeries}`,
            })),
          );
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }, [inputs.accountId]);

  const handleSubMit = (e) => {
    e.preventDefault();
    // Transform the String in Array
    const checkNumberArray = inputs.checkNumber.split(",").map((v) => v.trim());
    // Create new Objet
    const body = {
      accountId: inputs.accountId,
      blockPeriod: inputs.blockPeriod,
      chequeSeries:
        checkNumberArray.length > 1 ? checkNumberArray : [inputs.checkNumber],
      startSeries: inputs.checkbookNumber.split("-")[0],
      endSeries: inputs.checkbookNumber.split("-")[1],
      description: inputs.reason,
    };

    const handleStopCheckPayment = (securityQuestions) => {
      return stopCheckPayment(body, securityQuestions).then((res) => {
        Navigate(`/account/unblock-check?ac=${inputs.accountId}`);
        toast.success("Success", { position: "top-right" });
      });
    };

    setVerifySecureQuestions(true);
    // Appel de setVerifyCallback avec la nouvelle donnée
    setVerifyCallback(handleStopCheckPayment);
  };

  const handleInputsChange = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (name === "blockPeriod") {
      if (value === "0") {
        setIsCustomPeriod(true);
      } else {
        setIsCustomPeriod(false);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h4 className="fw-bold">{t("stop_check_payment")}</h4>
        <div className="card bg-white border-0 shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4">
                <form
                  onSubmit={handleSubMit}
                  className="border-custom p-3 h-100 "
                >
                  <div>
                    <div className="col-xl-12">
                      <FormInput
                        name="accountId"
                        label={t("select_account")}
                        data={optionAccounts}
                        value={inputs.accountId}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("accountId", e.value)
                        }
                      />
                    </div>

                    <div hidden={!inputs.accountId} className="col-xl-12">
                      <FormInput
                        name="checkbookNumber"
                        label={t("select_reference_of_missing_check")}
                        data={checkList}
                        value={inputs.checkbookNumber}
                        type="select"
                        required={false}
                        onchange={(e) =>
                          handleInputsChange("checkbookNumber", e.value)
                        }
                      />
                    </div>

                    <div className="col-xl-12">
                      <FormInput
                        name="checkNumber"
                        label={t("enter_the_check_number")}
                        value={inputs.checkNumber}
                        type="text"
                        onchange={(e) =>
                          handleInputsChange("checkNumber", e.target.value)
                        }
                      />
                    </div>

                    <div className="col-xl-12">
                      <FormInput
                        name="reason"
                        label={t("why_are_you_blocking_this_check")}
                        data={reasons}
                        value={inputs.reason}
                        type="select"
                        onchange={(e) => handleInputsChange("reason", e.value)}
                      />
                    </div>

                    <div className="col-xl-12">
                      <FormInput
                        name="blockPeriod"
                        label={t("suspension_period")}
                        data={periods}
                        value={inputs.blockPeriod}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("blockPeriod", e.value)
                        }
                      />
                    </div>

                    <div hidden={!isCustomPeriod} className="col-xl-12">
                      <FormInput
                        name="blockPeriod"
                        label={t("enter_suspention")}
                        value={inputs.blockPeriod}
                        type="number"
                        onchange={(e) =>
                          setInputs((prevState) => {
                            return {
                              ...prevState,
                              blockPeriod: e.target.value,
                            };
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      disabled={!canSubmit || showLoading}
                      className="btn btn-primary btn-sm "
                    >
                      {t("submit_button")}
                    </button>
                  </div>
                </form>
              </div>

              <div className="col-xl-2"></div>

              <div className="col-xl-5">
                <div className="card pb-0 border-0 ">
                  <div className="card-body">
                    <div className="media me-2 pb-5 d-flex justify-content-center">
                      <img
                        width="100"
                        height="100"
                        src={Benefit}
                        alt="benefit"
                      />
                    </div>
                    <div
                      style={{ height: "300px" }}
                      id="DZ_W_Todo3"
                      className="widget-media dz-scroll height270  ps--active-y"
                    >
                      <ul className="timeline">
                        <li>
                          {/* <h5 className="mb-2 fs-5 d-flex justify-content-center">
                                                        {t('change_pass_educate')}
                                                    </h5>
                                                    <h2 className="mb-2 fs-5 text-warning">
                                                        {t('change_pass_force')}
                                                    </h2> */}
                          <p className="pl-5 fs-5 d-flex justify-content-center text-center">
                            {t("check_stop_check_body")}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        size="sm"
        show={initialLoad || (!showLoading && !hasCurrentAccount)}
        centered
      >
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
          {!showLoading && !hasCurrentAccount && (
            <div className="text-center">
              <h5 className="mb-2 fs-5 text-center">
                {t("Sorry_you_do_not_have_a_current_accounts")}
              </h5>
              <button
                className="btn btn-warning btn-sm me-3"
                onClick={() => Navigate("/home")}
              >
                {t("ok_button")}
              </button>
            </div>
          )}

          {showLoading && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Spinners.TailSpin
                height="40"
                width="40"
                ariaLabel="tail-spin-loading"
                radius="1"
                color="#000"
              />
              <p>Recherche des comptes cheques</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StopCheck;
