import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { t } from "i18next";

const TemplateForm = () => {
  const [content, setContent] = useState("");

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Save the template content (content) to the backend
    // Call your backend API to save the template
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  return (
    <>
      <MainPageTitle
        mainTitle={t("Dashboard")}
        pageTitle={t("general_config")}
        parentTitle={t("Configuration")}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="card card-bx m-b30">
            <h2>{t("create_email_template")}</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="templateContent">{t("template_content")}</label>
                <ReactQuill
                  id="templateContent"
                  value={content}
                  onChange={handleChange}
                  formats={formats}
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link", "image", "video"],
                      ["clean"],
                      [{ color: [] }, { background: [] }], // Add color options
                    ],
                  }}
                  placeholder={t("compose_your_email_template")}
                />
              </div>
              <button className="btn btn-primary" type="submit">
                {t("save_template")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateForm;
