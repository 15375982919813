import React, { useContext, useEffect, useState } from "react";
import Highlight from "react-highlight";
import { Row, Col, Card, Tab, Nav, Table } from "react-bootstrap";
import Benefit from "../../../common/images/svg/benefit.svg";
import { connect } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getAllCustomerAccountDetails,
  getDetailsUser,
  updatePrimaryAccount,
  updateProfile,
} from "../../services/acountServices";
import ErrorManager from "../../errors";
import { ThemeContext } from "../../../context/ThemeContext";
import { RiCloseLine, RiEditBoxLine } from "react-icons/ri";
import { toast } from "react-toastify";
import "../../../styles/profile.css";

const ProfileTab = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setShowLoading,
    showLoading,
    setVerifyCallback,
    setVerifySecureQuestions,
  } = useContext(ThemeContext);

  const [editAddress, setEditAddress] = useState(false);
  const [editNumber, setEditNumber] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdatePrimary, setIsUpdatePrimary] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasChangesPrimaryAccount, setHasChangesPrimaryAccount] =
    useState(false);

  const [data, setData] = useState({});
  const [inputValues, setInputValues] = useState({
    address: "",
    phoneNumber: "",
    email: "",
    preferredOtpChannel: "",
    doubleAuthentication: false,
  });
  const [inputValuesDouble, setInputValuesDouble] = useState({
    address: "",
    phoneNumber: "",
    email: "",
    preferredOtpChannel: "",
    doubleAuthentication: false,
  });
  const [radioValue, setRadioValue] = useState(data.primaryAccount);
  const [radioValueDouble, setRadioValueDouble] = useState(data.primaryAccount);

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    setShowLoading(true);
    getDetailsUser(props.userDao.clientMatricule)
      .then((res) => {
        const userData = res.data.data;
        setData(userData);
        setInputValues({
          address: userData.address || "",
          phoneNumber: userData.phoneNumber || "",
          email: userData.email || "",
          preferredOtpChannel: userData.preferredOtpChannel || "",
          doubleAuthentication: userData.doubleAuthentication || false,
        });
        setInputValuesDouble({
          address: userData.address || "",
          phoneNumber: userData.phoneNumber || "",
          email: userData.email || "",
          preferredOtpChannel: userData.preferredOtpChannel || "",
          doubleAuthentication: userData.doubleAuthentication || false,
        });
        setRadioValue(userData.primaryAccount);
        setRadioValueDouble(userData.primaryAccount);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userDao.clientMatricule, navigate, isUpdate]);

  useEffect(() => {
    const isModified =
      JSON.stringify(inputValues) !== JSON.stringify(inputValuesDouble);
    setHasChanges(isModified);
  }, [inputValues, inputValuesDouble]);

  useEffect(() => {
    const isModified = radioValue !== radioValueDouble;
    setHasChangesPrimaryAccount(isModified);
  }, [radioValue, radioValueDouble]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: name === "doubleAuthentication" ? JSON.parse(value) : value,
    });
  };

  const handlePrimarySelect = (e) => {
    setRadioValue(e.target.value);
    setHasChanges(true);
  };

  const executeUpdateUser = (securityQuestion) => {
    if (!hasChanges) {
      return;
    }

    setShowLoading(true);
    setIsUpdate(true);
    const mergedValues = {
      ...data,
      ...inputValues,
    };

    return updateProfile(mergedValues, securityQuestion)
      .then((res) => {
        toast.success(t("changes_saved_successfully"), {
          position: "top-right",
        });
        setEditAddress(false);
        setEditNumber(false);
        setEditEmail(false);
        setInputValuesDouble({ ...inputValues });
        setData({ ...data });
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setShowLoading(false);
        setIsUpdate(false);
      });
  };

  const executeUpdatePrimaryAccount = (securityQuestion) => {
    if (!hasChanges) {
      return;
    }

    setShowLoading(true);
    setIsUpdatePrimary(true);
    const body = {
      primaryAccount: radioValue,
    };

    return updatePrimaryAccount(body, securityQuestion)
      .then(() => {
        toast.success(t("changes_saved_successfully"), {
          position: "top-right",
        });
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setShowLoading(false);
        setIsUpdatePrimary(false);
      });
  };

  const handleSubmitDetailsProfile = (e) => {
    e.preventDefault();
    setVerifyCallback(executeUpdateUser);
    setVerifySecureQuestions(true);
  };

  const handleSubmitPrimaryAccount = (e) => {
    e.preventDefault();
    setVerifyCallback(executeUpdatePrimaryAccount);
    setVerifySecureQuestions(true);
  };

  const handleEditAddress = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      address: data.address || "",
    }));
    setEditAddress(true);
  };

  const handleEditNumber = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      phoneNumber: data.phoneNumber || "",
    }));
    setEditNumber(true);
  };

  const handleEditEmail = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      email: data.email || "",
    }));
    setEditEmail(true);
  };

  const getAccountDetails = () => {
    setShowLoading(true);
    return getAllCustomerAccountDetails()
      .then((v) => {
        const data = v.data.data;
        setAccounts(data);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getAccountDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdatePrimary]);

  const tabData = [
    {
      name: t("user_profile_tab"),
      key: "user_profile_tab",
      content: (
        <div className="row">
          <div className="col-xl-12 col-sm-12 overflow-scroll">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th className="fw-bold">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fw-bold">{t("user_address")} : </td>
                  <td>
                    {editAddress ? (
                      <input
                        type="text"
                        name="address"
                        value={inputValues.address}
                        autoComplete="off"
                        onChange={handleInputChange}
                        className="form-control-profil"
                      />
                    ) : data.address ? (
                      data.address.trim()
                    ) : (
                      t("no_address")
                    )}
                  </td>
                  <td>
                    {editAddress ? (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setEditAddress(false)}
                      >
                        <RiCloseLine size={15} /> {t("cancel_button")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleEditAddress}
                      >
                        <RiEditBoxLine size={15} /> {t("edit_button")}
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("user_phone")} : </td>
                  <td>
                    {editNumber ? (
                      <input
                        type="text"
                        name="phoneNumber"
                        value={inputValues.phoneNumber}
                        autoComplete="off"
                        onChange={handleInputChange}
                        className="form-control-profil"
                      />
                    ) : (
                      (data.phoneNumber ?? t("no_number"))
                    )}
                  </td>
                  <td>
                    {editNumber ? (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setEditNumber(false)}
                      >
                        <RiCloseLine size={15} /> {t("cancel_button")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleEditNumber}
                      >
                        <RiEditBoxLine size={15} /> {t("edit_button")}
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("user_email")} : </td>
                  <td>
                    {editEmail ? (
                      <input
                        type="text"
                        name="email"
                        value={inputValues.email}
                        autoComplete="off"
                        onChange={handleInputChange}
                        className="form-control-profil"
                      />
                    ) : (
                      (data.email ?? t("no_email"))
                    )}
                  </td>
                  <td>
                    {editEmail ? (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setEditEmail(false)}
                      >
                        <RiCloseLine size={15} /> {t("cancel_button")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleEditEmail}
                      >
                        <RiEditBoxLine size={15} /> {t("edit_button")}
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("otp_title")} : </td>
                  <td className="d-flex">
                    <div className="me-4 d-flex align-items-center">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="otp_sms"
                        name="preferredOtpChannel"
                        value="SMS"
                        checked={inputValues.preferredOtpChannel === "SMS"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="otp_sms" className="mt-3 ms-2">
                        {t("user_otp_sms")}
                      </label>
                    </div>
                    <div className="me-4 d-flex align-items-center">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="otp_mail"
                        name="preferredOtpChannel"
                        value="MAIL"
                        checked={inputValues.preferredOtpChannel === "MAIL"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="otp_mail" className="mt-3 ms-2">
                        {t("user_otp_email")}
                      </label>
                    </div>
                    <div className="me-4 d-flex align-items-center">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="otp_both"
                        name="preferredOtpChannel"
                        value="BOTH"
                        checked={inputValues.preferredOtpChannel === "BOTH"}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="otp_both" className="mt-3 ms-2">
                        {t("user_otp_both")}
                      </label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("double_authentication")} : </td>
                  <td className="d-flex">
                    <div className="me-4 d-flex align-items-center">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="doubleAuthentication_true"
                        name="doubleAuthentication"
                        value={true}
                        checked={inputValues.doubleAuthentication === true}
                        onChange={() =>
                          handleInputChange({
                            target: {
                              name: "doubleAuthentication",
                              value: true,
                            },
                          })
                        }
                      />
                      <label
                        htmlFor="doubleAuthentication_true"
                        className="mt-3 ms-2"
                      >
                        {t("activated")}
                      </label>
                    </div>
                    <div className="me-4 d-flex align-items-center">
                      <input
                        type="radio"
                        className="form-check-input"
                        id="doubleAuthentication_false"
                        name="doubleAuthentication"
                        value={false}
                        checked={inputValues.doubleAuthentication === false}
                        onChange={() =>
                          handleInputChange({
                            target: {
                              name: "doubleAuthentication",
                              value: false,
                            },
                          })
                        }
                      />
                      <label
                        htmlFor="doubleAuthentication_false"
                        className="mt-3 ms-2"
                      >
                        {t("desactivated")}
                      </label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <button
                      type="submit"
                      onClick={handleSubmitDetailsProfile}
                      className="btn btn-primary btn-sm fs-5 mt-2"
                      disabled={!hasChanges || showLoading}
                    >
                      {t("save_button")}
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      ),
    },
    {
      name: t("user_acc_no_tab"),
      key: "user_acc_no_tab",
      content: (
        <div className="row">
          <div className="col-xl-12 col-sm-12 overflow-scroll">
            <span className="ps-2">{t("primary_acc_select")}</span>
            <Table>
              <thead>
                <tr>
                  <th className="fw-bold">{t("user_acc_select_title")}</th>
                  <th className="fw-bold">{t("accounts_display")}</th>
                  <th className="fw-bold">{t("acc_type_title")}</th>
                  <th className="fw-bold">{t("status")}</th>
                </tr>
              </thead>
              <tbody>
                {accounts.map((account, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="primaryAccount"
                          value={account.accountID}
                          id={`account${index}`}
                          checked={radioValue === account.accountID}
                          onChange={handlePrimarySelect}
                        />
                        <label htmlFor="primary" className="mt-3 ms-2"></label>
                      </div>
                    </td>
                    <td className="w-space-no">
                      {account.accountID}-{account.accountName}
                    </td>
                    <td className="w-space-no">{account.productType}</td>
                    <td>
                      <span
                        className={`badge ${account.acstatus === "Actif" ? "bg-success" : "bg-danger"}`}
                      >
                        {account.acstatus}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
              <div className="">
                <button
                  type="submit"
                  onClick={handleSubmitPrimaryAccount}
                  className="btn btn-primary btn-sm fs-5 mt-2"
                  disabled={!hasChangesPrimaryAccount || showLoading}
                >
                  {t("save_button")}
                </button>
              </div>
            </Table>
          </div>
        </div>
      ),
    },
    {
      name: t("user_security_quest_tab"),
      key: "user_security_quest_tab",
      content: (
        <div className="row">
          <div className="col-xl-8">
            <div className="card  border-0 pb-0">
              <div className="card-header border-0 pb-0">
                <h3 className="card-title text-warning">
                  {" "}
                  {t("user_security_quest_title")}
                </h3>
              </div>
              <div className="card-body">
                <div
                  style={{ height: "230px" }}
                  id="DZ_W_Todo3"
                  className="widget-media dz-scroll height270  ps--active-y"
                >
                  <ul className="timeline phil-puce">
                    <p className="fs-5">{t("user_security_question")}</p>
                    <li>{t("user_sec_quest_example_1")}</li>
                    <li>{t("user_sec_quest_example_2")}</li>
                    <li>{t("user_sec_quest_example_3")}</li>
                  </ul>
                </div>
                <div>
                  <NavLink
                    to={"/security-questions"}
                    className="btn btn-sm btn-primary "
                  >
                    {t("edit_button")}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card pb-0 border-custom shadow rounded-0">
              <div className="card-body">
                <div className="media me-2 pb-5 d-flex justify-content-center">
                  <img width="100" height="100" src={Benefit} alt="benefit" />
                </div>
                <div
                  style={{ height: "300px" }}
                  id="DZ_W_Todo3"
                  className="widget-media dz-scroll height270  ps--active-y"
                >
                  <ul className="timeline">
                    <li>
                      <h5 className="mb-2 fs-5 d-flex justify-content-center">
                        {t("modal_set_quest_info")}
                      </h5>
                      <h2 className="mb-2 fs-5 text-warning">
                        {t("change_pass_force")}
                      </h2>
                      <p className="pl-5 fs-5 d-flex justify-content-center">
                        {" "}
                        {t("sec_quest_learn")}{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <Row>
        <Col xl={12}>
          <Tab.Container defaultActiveKey="Preview">
            <Card name="custom-tab" className="dz-card border-0 shadow">
              <Tab.Content>
                <Tab.Pane eventKey="Preview">
                  <Card.Body>
                    {/* <!-- Nav tabs --> */}
                    <div className="custom-tab-1">
                      <Tab.Container
                        defaultActiveKey={tabData[0].key.toLowerCase()}
                      >
                        <Nav as="ul" className="nav-tabs">
                          {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                              <Nav.Link eventKey={data.key.toLowerCase()}>
                                <i className={`la la-${data.icon} me-2`} />
                                {data.name}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                        <Tab.Content className="pt-4">
                          {tabData.map((data, i) => (
                            <Tab.Pane eventKey={data.key.toLowerCase()} key={i}>
                              {data.content}
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="Code">
                  <div className="card-body pt-0 p-0 code-area">
                    <pre className="mb-0">
                      <code className="language-html">
                        <Highlight></Highlight>
                      </code>
                    </pre>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userDao: state.auth.userDao,
  };
};
export default connect(mapStateToProps)(ProfileTab);
