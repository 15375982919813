import { adminAxiosInstance } from "../../common/axiosInstance";

export function listAllSubscriptions(
  order,
  filter,
  search,
  type,
  status,
  page,
  size,
) {
  return adminAxiosInstance.get(
    `/subscription/listAll?order=${order}&filter=${filter}&search=${search}&page=${page}&size=${size}&type=${type}&status=${status}`,
  );
}

export function listMbConfigurations() {
  return adminAxiosInstance.get(`/instConfig/listMobileConfig`);
}

export function clientDetails(clientId) {
  return adminAxiosInstance.get(`/instConfig/client-details/${clientId}`);
}

export function updateClientStatus(clientId, status) {
  return adminAxiosInstance.put(
    `/instConfig/update-client-status/${clientId}`,
    { status: status },
  );
}

export function updateClientEmail(clientId, body) {
  return adminAxiosInstance.put(
    `/instConfig/update-client-status/${clientId}`,
    body,
  );
}

export function resetSecurityQuestion(clientId) {
  return adminAxiosInstance.put(
    `/subscription/reset-security-questions/${clientId}`,
  );
}

export function addSubscriptions(data) {
  return adminAxiosInstance.post(`/subscription/add`, data);
}

export const listClientRequest = (
  order,
  filter,
  search,
  type,
  status,
  page,
  size,
) => {
  return adminAxiosInstance.get(
    `subscription/list-client-request?order=${order}&filter=${filter}&search=${search}&page=${page}&size=${size}&type=${type}&status=${status}`,
  );
};

export const getOneClientRequest = (uuid) => {
  return adminAxiosInstance.get(`subscription/client-request/${uuid}`);
};

export const validateClientAccountRequest = (uuid, body) => {
  return adminAxiosInstance.put(
    `subscription/client-request-validation/${uuid}`,
    body,
  );
};

export const dashboardStatus = () => {
  return adminAxiosInstance.get(`/dashboard-status`);
};
