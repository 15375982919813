import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { authForgotPassword } from "../../services/clientAuthService";
import { CUSTOMERPORTAL } from "../../../common/store/selectors/authSelectors";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import "../../../styles/login.css";
import ImageLogin from "../../../common/images/login/1.svg";
import AppTranslation from "../../../common/jsx/components/AppTranslation";
import { useTranslation } from "react-i18next";
import { encryptData } from "../../../utils/utilsCrypto";
import logo from "../../../common/images/logo/logo.png";
import { COMPANY_NAME_FULL } from "../../../config";

function ForgotPassword(props) {
  const { tb } = AppTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [errors, setErrors] = useState({ userName: "" });
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setErrors((prev) => ({
      ...prev,
      userName: "",
    }));
  };

  function handleCheckUsername(e) {
    e.preventDefault();

    if (userName === "") {
      setErrors({ userName: t("login_username_error") });
    } else {
      setShowLoading(true);
      authForgotPassword(userName)
        .then((response) => {
          const resData = response.data.data;
          resData.portal = CUSTOMERPORTAL;
          const dataCrypted = encryptData(resData);
          navigate(`/auth/otp-verification?m=${dataCrypted}`);
        })
        .catch((err) => {
          setErrors({ userName: "" });
          if (err.response) {
            if (err.response.status === 401) {
              toast.warning(err.response.data.message, {
                position: "top-right",
              });
            }
          } else {
            ErrorManager(err);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });

  return (
    <div className="conteneur">
      <div className="content">
        <form onSubmit={handleCheckUsername} className="left">
          <div className="sm-welcome">
            <h1>{t("login_title")}</h1>
            <p>{tb("login_page_intro")}</p>
          </div>
          <img src={logo} width="35%" alt="logo" />

          <h2 className="head-title">{t("forgot_pass_title")}</h2>
          <div className="input-box">
            <div className="icon">
              <RemixIcon.RiUserLine size={20} />
            </div>
            <input
              type="text"
              value={userName}
              onChange={handleUsernameChange}
              placeholder={t("forgot_pass_placeholder")}
              autoComplete="off"
              autoFocus
            />
          </div>
          <small className="input-error">{errors.userName}</small>

          <button className="btn-custom" type="submit" disabled={showLoading}>
            {showLoading ? (
              <Spinners.TailSpin
                height="20"
                width="20"
                ariaLabel="tail-spin-loading"
                radius="4"
                color="#fff"
              />
            ) : (
              <RemixIcon.RiSearch2Line />
            )}
            {t("verify_account")}
          </button>
          <a
            className="btn-link"
            type="button"
            onClick={() => navigate("/auth/login")}
          >
            <RemixIcon.RiHomeLine />
            {t("back_to_login")}
          </a>
        </form>
        <div className="right right-forgot-password">
          <img className="image-login" src={ImageLogin} alt={ImageLogin} />
          <h1 className="fw-bold">{COMPANY_NAME_FULL}</h1>
          <p>{tb("login_page_intro")}</p>
          <button onClick={() => navigate("/auth/page-register")}>
            {t("pass_create_acc_ques")}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(ForgotPassword);
