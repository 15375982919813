import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkAutoLogin } from "../../../services";
import { baseUrlAdmin, baseUrlClient } from "../../../constant";
import {
  ADMINPORTAL,
  CUSTOMERPORTAL,
} from "../../../store/selectors/authSelectors";
import { store } from "../../../store";

const Error403 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storeState = store.getState();
  const accessPortal = storeState.auth.accessPortal;

  useEffect(() => {
    if (accessPortal === CUSTOMERPORTAL) {
      checkAutoLogin(dispatch, navigate, baseUrlClient);
    } else if (accessPortal === ADMINPORTAL) {
      checkAutoLogin(dispatch, navigate, baseUrlAdmin);
    }
  }, [dispatch, navigate, accessPortal]);

  return (
    <div className="authincation h-100">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="error-page">
              <div className="error-inner text-center">
                <div className="dz-error" data-text="403">
                  403
                </div>
                <h4 className="error-head">
                  <i className="fa fa-times-circle text-danger"></i> Forbidden
                  Error!
                </h4>
                <p className="error-head">
                  You do not have permission to view this resource.
                </p>
                <div>
                  <button
                    type="button"
                    onClick={(e) => navigate(-1)}
                    className="btn btn-secondary"
                  >
                    BACK{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error403;
