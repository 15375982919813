/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import Highlight from "react-highlight";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { FormInput } from "../../../common/jsx/components/FormInput";
import {
  getCustomerAccounts,
  searchPayable,
} from "../../services/acountServices";
import { useQuery } from "../../../common/services";
import { ThemeContext } from "../../../context/ThemeContext";
import ErrorManager from "../../errors";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { stringDate } from "../../../common/constant";
import { useTranslation } from "react-i18next";
import Benefit from "../../../common/images/svg/benefit.svg";

const billOptions = [
  { value: "WATERBI", label: "CAMWATER" },
  { value: "ENEOBI", label: "ENEO POSTPAID" },
  { value: "CANALB", label: "CANAL PLUS" },
];

// const airTimeAndData = [
//     { value: "MTN", label: "MTN" },
//     { value: "ORANGE", label: "ORANGE" },
//     { value: "NEXTEL", label: "NEXTEL" },
// ];

const BillsTab = (props) => {
  const scrollRef = useRef(null);
  const { t } = useTranslation();
  const [typeOp, setTypeOp] = useState("");
  const [reference, setReference] = useState("");
  const [payment, setPayment] = useState([]);

  const Navigate = useNavigate();
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const tableHeaders = [
    { label: t("name") },
    { label: t("amount") },
    { label: t("payable_from") },
    { label: t("payable_until") },
    { label: t("actions") },
  ];

  useEffect(() => {
    setShowLoading(false);
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      const bottomPosition =
        scrollRef.current.getBoundingClientRect().bottom +
        window.scrollY -
        window.innerHeight +
        scrollRef.current.offsetHeight;
      window.scrollTo({ top: bottomPosition, behavior: "smooth" });
    }
  }, [payment]);

  const listPayables = (e) => {
    e.preventDefault();

    setShowLoading(true);
    const body = {
      id: reference,
      typeOp: typeOp,
    };

    searchPayable(body)
      .then((res) => {
        setPayment(
          res.data.data.map((b) => [
            b.name,
            b.amount,
            stringDate(b.payable_from),
            stringDate(b.payable_until),
            <div className="action-button">
              <button
                type="button"
                onClick={() =>
                  Navigate(
                    `/client-services/before-process?Pc_PrincipAccount=aqqwedtcesegvcvbgbnjnuzvrqqervvgvjuqscvjuzfewqsdftzh&Pc_TypeOp=${typeOp}&SvMontant=${b.amount}&reference=${reference}&i=.`,
                  )
                }
                className="btn btn-outline-primary btn-sm"
              >
                option
              </button>
            </div>,
          ]),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (
    <div ref={scrollRef} className="row ">
      <div className="col-xl-4 border-custom p-3">
        <div className="">
          <h5 className="card-title fw-bold pb-3">{t("bills_operations")}</h5>
        </div>
        <form onSubmit={listPayables} method="POST">
          <div className="row ">
            <div className="col-xl-12 ">
              <FormInput
                data={billOptions}
                value={typeOp}
                onchange={(e) => setTypeOp(e.value)}
                label={t("select_operator")}
                required={true}
                name="typeOp"
                type="select"
              />
            </div>
            <div className="col-xl-12 ">
              <FormInput
                onchange={(e) => setReference(e.target.value)}
                label={t("bill_ref")}
                required={true}
                name="id"
                type="text"
              />
            </div>
          </div>
          <div>
            <button
              className="btn btn-primary btn-sm me-3"
              disabled={!typeOp || !reference || showLoading}
              type="submit"
            >
              {t("validate_button")}
            </button>
          </div>
        </form>
      </div>
      <div className="col-xl-2"></div>
      <div className="col-xl-4 d-flex flex-column justify-content-center align-items-center">
        <div className="media me-2 pb-5 d-flex justify-content-center">
          <img width="100" height="100" src={Benefit} alt="benefit" />
        </div>
        <div className="text-center">
          <span>{t("Paymt_details")} </span>
        </div>
      </div>

      <div className="col-xl-12 p-0 mt-3">
        {payment ? (
          <DataTable headersTitle={tableHeaders} tableData={payment} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

// const AirTimePurchase = (props) => {
//     const { t } = useTranslation();

//     return (
//         <div className="row ">
//             <div className="col-xl-4 border-custom p-3">
//                 <div className="">
//                     <h5 className="card-title fw-bold pb-3">{t('airtime_purchase')}</h5>
//                 </div>
//                 <form method="POST">
//                     <div className="row ">
//                         <div className="col-xl-12 ">
//                             <FormInput
//                                 data={airTimeAndData}
//                                 label={t('pay_from')} required={true} name="typeOp"
//                                 type="select" />
//                         </div>
//                         <div className="col-xl-12 ">
//                             <FormInput
//                                 data={airTimeAndData} value={airTimeAndData}
//                                 label={t('select_operator')} required={true} name="typeOp"
//                                 type="select" />
//                         </div>
//                         <div className="col-xl-12 ">
//                             <FormInput
//                                 label={t('beneficiary_number')} required={true} name="phoneNumber"
//                                 type="number" />
//                         </div>
//                         <div className="col-xl-12 ">
//                             <FormInput
//                                 label={t('amount')} required={true} name="amount"
//                                 type="number" />
//                         </div>
//                     </div>
//                     <div>
//                         <button className="btn btn-primary btn-sm me-3" disabled={false} type="button">
//                             {t('validate_button')}
//                         </button>
//                         <button className="btn btn-outline-primary btn-sm" disabled={false} type="submit">
//                             {t('cancel_button')}
//                         </button>
//                     </div>
//                 </form>
//             </div>

//             <div className="col-xl-7  text-center">
//                 <div className="card border-0 pb-0">
//                     <div className="card-body border-0 ">
//                         <div className="media me-2 pb-5 d-flex justify-content-center">
//                             <img width="100" height="100" src={Benefit} alt="benefit" />
//                         </div>
//                         <div
//                             style={{ height: "300px" }}
//                             id="DZ_W_Todo3"
//                             className="widget-media dz-scroll height270  ps--active-y">
//                             <ul className="timeline">
//                                 <li>
//                                     <p className="pl-5 fs-5 text-center">{t('Paymt_details')} </p>
//                                 </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

const ClientServices = () => {
  const { t } = useTranslation();
  // const [optionAccounts, setOptionAccounts] = useState([])
  let query = useQuery();
  const { setShowLoading } = useContext(ThemeContext);
  const defaultKey = query.get("view") || "bills_payment";
  const [displayTab, setDisplayTab] = useState(defaultKey);

  const getPageData = async () => {
    try {
      const v = await getCustomerAccounts();
      const data = v.data.data;
      return data;
    } catch (err) {
      return undefined;
    }
  };

  const tabData = [
    {
      name: t("bills_payment"),
      key: "bills_payment",
      content: <BillsTab />,
    },
    // {
    //     name: t('airtime_purchase'),
    //     content: <AirTimePurchase />
    // },
  ];

  useEffect(() => {
    setDisplayTab(defaultKey);
  }, [defaultKey]);

  useEffect(() => {
    setShowLoading(true);
    getPageData().then((res) => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <Row>
          <Col xl={12}>
            <Tab.Container defaultActiveKey="Preview">
              <Card name="custom-tab" className="dz-card rounded-0 shadow">
                <Tab.Content>
                  <Tab.Pane eventKey="Preview">
                    <Card.Body>
                      {/* <!-- Nav tabs --> */}
                      <div className="custom-tab-1">
                        <Tab.Container
                          activeKey={displayTab}
                          defaultActiveKey={displayTab}
                        >
                          <Nav as="ul" className="nav-tabs">
                            {tabData.map((data, i) => (
                              <Nav.Item
                                onClick={(e) =>
                                  setDisplayTab(data.name.toLowerCase())
                                }
                                as="li"
                                key={i}
                              >
                                <Nav.Link eventKey={data.key.toLowerCase()}>
                                  <i className={`la la-${data.icon} me-2`} />
                                  {data.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                          <Tab.Content className="p-3">
                            {tabData.map((data, i) => (
                              <Tab.Pane
                                eventKey={data.key.toLowerCase()}
                                key={i}
                              >
                                {data.content}
                              </Tab.Pane>
                            ))}
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </Card.Body>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Code">
                    <div className="card-body pt-0 p-0 code-area">
                      <pre className="mb-0">
                        <code className="language-html">
                          <Highlight></Highlight>
                        </code>
                      </pre>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card>
            </Tab.Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ClientServices;
