/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { getCustomerAccounts } from "../../services/acountServices";
import { FormInput } from "../../../common/jsx/components/FormInput";
import Benefit from "../../../common/images/svg/benefit.svg";
import ErrorManager from "../../errors";
import { useNavigate } from "react-router-dom";
import { baseUrlClient } from "../../../common/constant";
import {
  InitiateTransaction,
  getTransactionBilling,
} from "../../services/mobileBankService";
import { Logout, useQuery } from "../../../common/services";
import { toast } from "react-toastify";
import { formatAmount } from "../../../utils/utilsFormattedAmount";
import { useTranslation } from "react-i18next";

const BeforeProcess = (props) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  let query = useQuery();
  const SvMontant = query.get("SvMontant");
  const Pc_TypeOp = query.get("Pc_TypeOp");
  const reference = query.get("reference");
  const i = query.get("i");

  const initialInputs = {
    accountID: "",
    SvMontant: SvMontant,
    Pc_TypeOp: Pc_TypeOp,
    reference: reference,
    fee_amount: 0,
    tax: 0,
  };

  // const [accountList, setAccountsList] = useState([])
  const [optionAccounts, setOptionAccounts] = useState([]);
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
    setOtpVerificationCallBack,
    setVerifyOtp,
  } = useContext(ThemeContext);

  const [canSubmit, setCanSubmit] = useState(false);
  const [inputs, setInputs] = useState(initialInputs);
  const [errorMsg, setErrorMsg] = useState({});
  const [step, setStep] = useState(1);
  const [currency, setCurrency] = useState(1);

  const checkUrlParams = () => {
    let message = "";

    if (SvMontant !== null) {
      const montant = parseFloat(SvMontant);
      const isInteger = Number.isInteger(montant);
      if (isNaN(montant) || !isInteger) {
        message = "SvMontant doit être un nombre (flottant ou entier).";
      }
    } else {
      message = "SvMontant est requis.";
    }

    if (reference !== null) {
      if (typeof Pc_TypeOp !== "string") {
        message = "reference doit être une chaîne de caractères.";
      }
    } else {
      message = "reference est requis.";
    }

    if (Pc_TypeOp !== null) {
      if (typeof Pc_TypeOp !== "string") {
        message = "Pc_TypeOp doit être une chaîne de caractères.";
      } else if (Pc_TypeOp.length >= 10) {
        message = "Pc_TypeOp doit contenir moins de 10 caractères.";
      }
    } else {
      message = "Pc_TypeOp est requis.";
    }

    if (message) {
      toast.error("incorrect data format, you will be redirected", {
        position: "top-right",
      });

      setTimeout(() => {
        Navigate("/home");
      }, 3000);
    }
  };

  const getPageData = async () => {
    setShowLoading(true);
    try {
      try {
        const v = await getCustomerAccounts();
        const data = v.data.data;
        // setAccountsList(data)
        setOptionAccounts(
          data.map((act) => ({
            value: act.accountID,
            label: `${act.accountID} -${act.accountName}`,
          })),
        );
        return data;
      } catch (err) {
        if (err.response.status === 422) {
          setErrorMsg({
            accountID: err.response.data.message,
          });
        } else {
          ErrorManager(err, Navigate);
        }
      }
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (i !== ".") {
      Logout(Navigate, baseUrlClient);
    }

    checkUrlParams();
    getPageData().then((v) => setShowLoading(false));
  }, [Navigate, i]);

  useEffect(() => {
    let val = true;
    Object.keys(inputs).forEach((i) => {
      if (inputs[i] === "" || inputs[i] === undefined || inputs[i] === null) {
        val = false;
      }
    });
    setCanSubmit(val);
  }, [inputs]);

  useEffect(() => {
    setInputs((prevState) => {
      return {
        ...prevState,
        accountType: (
          optionAccounts.find((v) => v.value === inputs.accountID) ?? {}
        ).label,
      };
    });
  }, [inputs.accountID]);

  const executeAccountWithdrawal = async (securityQuestions) => {
    setShowLoading(true);
    const body = {
      accountId: inputs.accountID,
      amount: inputs.SvMontant,
      typeOp: inputs.Pc_TypeOp,
    };
    try {
      const v = await InitiateTransaction(
        body,
        securityQuestions,
        "withdrawal",
      );
      const data_1 = v.data.data;
      // data.handleError = handleError
      setVerifyOtp(true);
      setOtpVerificationCallBack(data_1);
    } finally {
      setShowLoading(false);
    }
  };

  // const handleError = (err) => {
  //     if (err.response.status === 422) {
  //         setErrorMsg({
  //             err: err.response.data.message
  //         })
  //     }
  // }

  const handleValidate = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    await getTransactionBilling({
      amount: inputs.SvMontant,
      account: inputs.accountID,
      typeOp: Pc_TypeOp,
    })
      .then((v) => {
        setCurrency(v.data.data.currency);
        setInputs((prevState) => {
          return {
            ...prevState,
            fee_amount: v.data.data.retAmount,
            tax: v.data.data.mntTva,
          };
        });
        setStep(2);
      })
      .catch((err) => {
        // if (err.response.status === 422) {
        //     setErrorMsg({
        //         SvMontant: err.response.data.message
        //     })
        // }
        // else {
        // }
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleConfirmOperation = (e) => {
    e.preventDefault();
    setVerifyCallback(executeAccountWithdrawal);
    setVerifySecureQuestions(true);
  };

  const handleInputsChange = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, []);

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{t("bills_payment_process")}</h4>
      <div className="card bg-white border-0 shadow">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-4">
              {step === 1 ? (
                <form
                  onSubmit={handleValidate}
                  className="border-custom p-3 h-100 "
                >
                  <div>
                    <div className="col-xl-12">
                      <FormInput
                        value={inputs.Pc_TypeOp}
                        onchange={(e) =>
                          handleInputsChange("Pc_TypeOp", e.target.value)
                        }
                        label={t("type_operation")}
                        name="Pc_TypeOp"
                        type="text"
                        disable={true}
                        required={false}
                        errorMsg={errorMsg.Pc_TypeOp}
                      />
                    </div>
                    <div className="col-xl-12">
                      <FormInput
                        value={inputs.reference}
                        onchange={(e) =>
                          handleInputsChange("reference", e.target.value)
                        }
                        label={t("reference_no")}
                        name="reference"
                        type="text"
                        disable={true}
                        required={false}
                        errorMsg={errorMsg.reference}
                      />
                    </div>
                    <div className="col-xl-12">
                      <FormInput
                        value={inputs.SvMontant}
                        onchange={(e) =>
                          handleInputsChange("SvMontant", e.target.value)
                        }
                        label={t("amount")}
                        name="SvMontant"
                        type="number"
                        disable={true}
                        required={false}
                        // errorMsg={errorMsg.SvMontant}
                      />
                    </div>
                    <div className="col-xl-12">
                      <FormInput
                        name="accountID"
                        label={t("select_account")}
                        data={optionAccounts}
                        value={inputs.accountID}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("accountID", e.value)
                        }
                        errorMsg={errorMsg.accountID}
                      />
                    </div>
                  </div>

                  <div className=" mt-3 ">
                    <button
                      type="submit"
                      disabled={showLoading || !canSubmit}
                      className="btn btn-primary btn-sm "
                    >
                      {t("proceed_button")}
                    </button>
                  </div>
                </form>
              ) : step === 2 ? (
                <div className="border-custom p-3 h-100">
                  <span>{t("you_are_abt_to")} </span>{" "}
                  <div className="mt-5 d-flex justify-content-between">
                    <h5>{t("account_number")} </h5>
                    <h5>{inputs.accountID}</h5>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("type_operation")}</h5>
                    <h5> {inputs.Pc_TypeOp} </h5>
                  </div>
                  <hr />
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("amount")} </h5>
                    <h5>{formatAmount(inputs.SvMontant, currency)}</h5>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <h5>{t("value_added_task")}</h5>
                    <h5>{formatAmount(inputs.tax, currency)}</h5>
                  </div>
                  <div className="pt-3">
                    <button
                      className="btn btn-outline-primary btn-sm me-3"
                      onClick={(e) => setStep(1)}
                      type="button"
                    >
                      {t("cancel_button")}
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleConfirmOperation}
                      type="button"
                      disabled={showLoading}
                    >
                      {t("proceed_button")}
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-xl-1 mb-5"></div>

            <div className="col-xl-7">
              <div className="card border-0 pb-0">
                <div className="card-body border-0 ">
                  <div className="media me-2 pb-5 d-flex justify-content-center">
                    <img width="100" height="100" src={Benefit} alt="benefit" />
                  </div>
                  <div
                    style={{ height: "300px" }}
                    id="DZ_W_Todo3"
                    className="widget-media dz-scroll height270  ps--active-y"
                  >
                    <ul className="timeline">
                      <li>
                        <p className="pl-5 fs-5 text-center">
                          {t("bill_payment_body")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeProcess;
