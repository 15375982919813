import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo/logo.png";
// import logoLarge from '../../../images/logo/logo-large.png'
import { store } from "../../../store";
import { CUSTOMERPORTAL } from "../../../store/selectors/authSelectors";
import { COMPANY_NAME } from "../../../../config";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const storeState = store.getState();
  const accessPortal = storeState.auth.accessPortal;
  const [toggle, setToggle] = useState(false);

  return (
    <div className="nav-header">
      <Link
        to={accessPortal === CUSTOMERPORTAL ? "/home" : "/adminportal/home"}
        className="brand-logo"
      >
        <img className="logo-abbr" src={logo} width={30} alt="jbi" />
        {/* <img className="brand-title" src={logoLarge} width={50} alt="banking" /> */}
        <span className="ms-2 brand-title">{COMPANY_NAME}</span>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          //openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger `}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
