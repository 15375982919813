/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import registration from "../../../common/images/login/7.jpg";
import "../../../styles/register.css";
import UploadPicture from "../../../common/images/placeholder/uploadPicture.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import ErrorManager from "../../errors";
import {
  accountIdentification,
  uploadMedia,
  verifyAccountResquest,
} from "../../services/clientAuthService";
import Webcam from "react-webcam";
import { baseUrlClient } from "../../../common/constant";
import { Logout } from "../../../common/services";
import { useTranslation } from "react-i18next";
import Compressor from "compressorjs";
import { toast } from "react-toastify";

const Register2 = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { uuid } = useParams();
  const Navigate = useNavigate();

  const webcamRef = useRef(null);
  const [mirrored] = useState(true);
  const [imgSrc, setImgSrc] = useState(null);
  const [errorNetwork, setErrorNetwork] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const [showPage, setShowPage] = useState(false);

  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const step = parseInt(params.get("step")) || 1;

  const [secondEndPoint, setSecondEndPoint] = useState(() => {
    return (
      getFromSessionStorage("secondEndPoint") || {
        identificationType: "",
        identificationNumber: "",
        idIssueDate: "",
        idExpDate: "",
        photoUuid: "",
        frontIdUuid: "",
        backIdUuid: "",
        ptcName: "",
        ptcAddress: "",
        ptcPhone: "",
      }
    );
  });

  const [errors, setErrors] = useState({
    identificationType: "",
    identificationNumber: "",
    idIssueDate: "",
    idExpDate: "",
    photoUuid: "",
    frontIdUuid: "",
    backIdUuid: "",
    ptcName: "",
    ptcAddress: "",
    ptcPhone: "",
  });

  useEffect(() => {
    if (uuid) {
      verifyAccountResquest(uuid)
        .then(() => {
          Navigate(`?step=1`);
          setShowPage(true);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              toast.error(err.response.data.message, { position: "top-right" });
              sessionStorage.clear();
              Navigate("/auth/login");
              return;
            } else {
              ErrorManager(err, Navigate);
              sessionStorage.clear();
              return;
            }
          }
          ErrorManager(err, Navigate);
          sessionStorage.clear();
          Navigate("/auth/login");
          return;
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      Navigate("/page-error-404");
    }
  }, [Navigate, uuid]);

  useEffect(() => {
    const storedValue = sessionStorage.getItem("zwz%098123jh.fdr");
    const storedStep = storedValue ? parseInt(storedValue.slice(-1)) || 1 : 1;
    if (step !== storedStep) {
      Navigate(`?step=${storedStep}`);
    }
  }, [Navigate, step]);

  const videoConstraints = {};

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };

  const mineType = (dataURI) => {
    return dataURI.split(",")[0].split(":")[1].split(";")[0].split("/")[1];
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);

    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }

  useEffect(() => {
    if (imgSrc) {
      const role = "CUSTOMER_PHOTO";
      const property = "photoUuid";
      uploadImageToBackend(imgSrc, role, property);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgSrc]);

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });

  let stepComponent;

  switch (step) {
    case 1:
      stepComponent = stepForm1();
      break;
    case 2:
      stepComponent = stepForm2();
      break;
    case 3:
      stepComponent = stepForm3();
      break;
    case 4:
      stepComponent = stepForm4();
      break;
    default:
      stepComponent = stepForm1();
      break;
  }

  useEffect(() => {
    saveToSessionStorage("secondEndPoint", secondEndPoint);
  }, [secondEndPoint]);

  function saveToSessionStorage(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  function getFromSessionStorage(key) {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  function handleSubmitSecondEndPoint() {
    setShowLoading(true);

    delete secondEndPoint.frontIdCard;
    delete secondEndPoint.backIdCard;
    delete secondEndPoint.frontIdCard;
    // secondEndPoint.photoUuid
    accountIdentification(uuid, secondEndPoint)
      .then(() => {
        Navigate("/auth/page-register/second-end-point-success");
        sessionStorage.removeItem("secondEndPoint");
        sessionStorage.removeItem("zwz%098123jh.fdr");
        sessionStorage.setItem(
          ".,mndbfsvdaa!@#$%^&*()_szdx",
          new Date().toISOString(),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function handleContinue(e) {
    e.preventDefault();
    const currentStep = step;
    let isValid = true;

    switch (currentStep) {
      case 1:
        if (!secondEndPoint.identificationType) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            identificationType: t("id_type_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            identityType: "",
          }));
        }
        break;
      case 2:
        const issueDate = new Date(secondEndPoint.idIssueDate);
        const expDate = new Date(secondEndPoint.idExpDate);

        if (!secondEndPoint.identificationNumber) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            identificationNumber: t("id_no_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            identificationNumber: "",
          }));
        }
        if (!secondEndPoint.idIssueDate) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            idIssueDate: t("id_dob_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            idIssueDate: "",
          }));
        }
        if (secondEndPoint.identificationType === "NIC") {
          if (!secondEndPoint.idExpDate) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              idExpDate: t("id_expiry_error"),
            }));
            isValid = false;
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              idExpDate: "",
            }));
          }
        }
        if (secondEndPoint.identificationType === "NIC") {
          if (secondEndPoint.idIssueDate && secondEndPoint.idExpDate) {
            if (expDate <= issueDate) {
              setErrors((prevErrors) => ({
                ...prevErrors,
                idExpDate: t("id_issue_instruc"),
              }));
              isValid = false;
            } else {
              setErrors((prevErrors) => ({
                ...prevErrors,
                idExpDate: "",
              }));
            }
          }
        }
        if (!secondEndPoint.frontIdUuid) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            frontIdUuid: t("id_img_front_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            frontIdUuid: "",
          }));
        }
        if (
          secondEndPoint.identificationType === "NIC" &&
          !secondEndPoint.backIdUuid
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            backIdUuid: t("id_img_back_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            backIdUuid: "",
          }));
        }
        break;
      case 3:
        if (!imgSrc) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            backIdUuid: t("user_photo_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            backIdUuid: "",
          }));
        }
        break;
      default:
        stepComponent = stepForm1();
        break;
    }

    if (isValid) {
      if (currentStep <= 3) {
        const newStep = step + 1;
        const newStoredValue = `huyrtt44wdejhrugktrmllyjui5hijbjygyvhbhvcgcdmlf,l,lmknjhvgc12${newStep}`;
        sessionStorage.setItem("zwz%098123jh.fdr", newStoredValue);
        Navigate(`?step=${newStep}`);
      } else if (currentStep === 4) {
        e.preventDefault();
        handleSubmitSecondEndPoint();
      }
    }
  }

  function handleBack() {
    if (step > 1) {
      Navigate(`?step=${step - 1}`);
    } else {
      Logout(Navigate, baseUrlClient);
    }
  }

  function uploadImageToBackend(file, role, property) {
    const formData = new FormData();
    formData.append("role", role);
    if (property === "photoUuid") {
      formData.append("file", dataURItoBlob(file), `photo.${mineType(file)}`);
    } else {
      formData.append("file", file);
    }

    setShowLoading(true);
    uploadMedia(uuid, formData)
      .then((res) => {
        setSecondEndPoint((prevState) => ({
          ...prevState,
          [property]: res.data.data,
        }));
      })
      .catch((err) => {
        if (!err.response) {
          setErrorNetwork(true);
          // Clear the input value if network response is empty
          document.getElementById("frontIdCardInput").value = "";
        }
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function handleFileSelect(e, fieldName, role, property) {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        toast.error(t("image_is_too_large"), { position: "top-right" });
        setTimeout(() => {
          toast.error(t("image_is_too_large_solution") + "3 MB", {
            position: "top-right",
          });
        }, 2000);
        return;
      }

      // Compresser l'image
      new Compressor(file, {
        quality: 0.6, // Réduire la qualité à 60%
        maxWidth: 800, // Taille maximale en pixels
        success(result) {
          uploadImageToBackend(result, role, property);
        },
        error(err) {
          toast.error(t("image_not_upload"), { position: "top-right" });
        },
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setSecondEndPoint((prevState) => ({
          ...prevState,
          [fieldName]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setSecondEndPoint((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function stepForm1() {
    return (
      <div className="form-step">
        <h1>{t("id_page_title")}</h1>
        <div className="content-3">
          <p>{t("id_type_quest")}</p>
          <div className="input-registration d-flex justify-content-center gap-3 ">
            <div className="input-conten d-flex align-items-center gap-1">
              <input
                type="radio"
                name="identificationType"
                value="NIC"
                checked={secondEndPoint.identificationType === "NIC"}
                onChange={handleChange}
              />
              <span>{t("id_card")}</span>
            </div>
            <div className="input-conten d-flex align-items-center gap-1">
              <input
                type="radio"
                name="identificationType"
                value="Passport"
                checked={secondEndPoint.identificationType === "Passport"}
                onChange={handleChange}
              />
              <span>{t("passport")}</span>
            </div>
          </div>

          <small className="error">{errors.identificationType}</small>
        </div>
      </div>
    );
  }

  function stepForm2() {
    return (
      <div className="form-step pad">
        <h1>{t("id_title")}</h1>
        {secondEndPoint.identificationType === "NIC" ? (
          <div className="content">
            <div className="input-registration">
              <div className="input-content">
                <label>{t("id_card_no")}</label>
                <input
                  type="text"
                  name="identificationNumber"
                  value={secondEndPoint.identificationNumber}
                  onChange={handleChange}
                />
              </div>
              <small className="error">{errors.identificationNumber}</small>
            </div>
            <div className="input-registration">
              <div className="input-content">
                <label>{t("id_issue_date")} </label>
                <input
                  type="date"
                  name="idIssueDate"
                  value={secondEndPoint.idIssueDate}
                  onChange={handleChange}
                />
              </div>
              <small className="error">{errors.idIssueDate}</small>
            </div>
            <div className="input-registration last-child">
              <div className="input-content">
                <label>{t("id_exp_date")}</label>
                <input
                  type="date"
                  name="idExpDate"
                  value={secondEndPoint.idExpDate}
                  onChange={handleChange}
                />
              </div>
              <small className="error">{errors.idExpDate}</small>
            </div>
            <div className="input-registration">
              <div className="input-content">
                <label>{t("id_front")}</label>
                <div
                  className="upload-card"
                  onClick={() =>
                    document.getElementById("frontIdCardInput").click()
                  }
                >
                  {showLoading && secondEndPoint.frontIdCard ? (
                    <Spinners.TailSpin
                      height="30"
                      width="30"
                      ariaLabel="tail-spin-loading"
                      radius="4"
                      color="#fff"
                    />
                  ) : showLoading === false &&
                    errorNetwork === false &&
                    secondEndPoint.frontIdCard ? (
                    <img
                      src={secondEndPoint.frontIdCard}
                      width="100%"
                      alt="Click here"
                    />
                  ) : (
                    <>
                      <img
                        src={UploadPicture}
                        className="upload-card-img"
                        alt="Click here"
                      />
                      <span>{t("click_here")}</span>
                    </>
                  )}
                </div>
                <input
                  id="frontIdCardInput"
                  type="file"
                  name="frontIdCard"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) =>
                    handleFileSelect(
                      e,
                      "frontIdCard",
                      "FRONT_ID_PHOTO",
                      "frontIdUuid",
                    )
                  }
                  hidden
                />
              </div>
              <small className="error">{errors.frontIdUuid}</small>
            </div>
            <div className="input-registration">
              <div className="input-content">
                <label>{t("id_back")}</label>
                <div
                  className="upload-card"
                  onClick={() =>
                    document.getElementById("backIdCardInput").click()
                  }
                >
                  {showLoading && secondEndPoint.backIdCard ? (
                    <Spinners.TailSpin
                      height="30"
                      width="30"
                      ariaLabel="tail-spin-loading"
                      radius="4"
                      color="#fff"
                    />
                  ) : showLoading === false &&
                    errorNetwork === false &&
                    secondEndPoint.backIdCard ? (
                    <img
                      src={secondEndPoint.backIdCard}
                      width="100%"
                      alt="Click here"
                    />
                  ) : (
                    <>
                      <img
                        src={UploadPicture}
                        className="upload-card-img"
                        alt="Click here"
                      />
                      <span>{t("click_here")}</span>
                    </>
                  )}
                </div>
                <input
                  id="backIdCardInput"
                  type="file"
                  name="backIdCard"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) =>
                    handleFileSelect(
                      e,
                      "backIdCard",
                      "BACK_ID_PHOTO",
                      "backIdUuid",
                    )
                  }
                  hidden
                />
              </div>
              <small className="error">{errors.backIdUuid}</small>
            </div>
          </div>
        ) : (
          <div className="content">
            <div className="input-registration">
              <div className="input-content">
                <label>{t("passport_no")}</label>
                <input
                  type="text"
                  name="identificationNumber"
                  value={secondEndPoint.identificationNumber}
                  onChange={handleChange}
                />
              </div>
              <small className="error">{errors.identificationNumber}</small>
            </div>
            <div className="input-registration">
              <div className="input-content">
                <label>{t("id_issue_date")}</label>
                <input
                  type="date"
                  name="idIssueDate"
                  value={secondEndPoint.idIssueDate}
                  onChange={handleChange}
                />
              </div>
              <small className="error">{errors.idIssueDate}</small>
            </div>
            <div className="input-registration">
              <div className="input-content">
                <label>{t("passport_front")}</label>
                <div
                  className="upload-card"
                  onClick={() =>
                    document.getElementById("frontPassportInput").click()
                  }
                >
                  {showLoading && secondEndPoint.frontPassport ? (
                    <Spinners.TailSpin
                      height="30"
                      width="30"
                      ariaLabel="tail-spin-loading"
                      radius="4"
                      color="#fff"
                    />
                  ) : showLoading === false &&
                    errorNetwork === false &&
                    secondEndPoint.frontPassport ? (
                    <img
                      src={secondEndPoint.frontPassport}
                      width="100%"
                      alt="Click here"
                    />
                  ) : (
                    <>
                      <img
                        src={UploadPicture}
                        className="upload-card-img"
                        alt="Click here"
                      />
                      <span>{t("click_here")}</span>
                    </>
                  )}
                </div>
                <input
                  id="frontPassportInput"
                  type="file"
                  name="frontPassport"
                  accept=".jpg,.jpeg,.png"
                  onChange={(e) =>
                    handleFileSelect(
                      e,
                      "frontPassport",
                      "FRONT_ID_PHOTO",
                      "frontIdUuid",
                    )
                  }
                  hidden
                />
              </div>
              <small className="error">{errors.frontIdUuid}</small>
            </div>
          </div>
        )}
      </div>
    );
  }

  function stepForm3() {
    return (
      <div className="form-step">
        <h1>{t("id_page_title")}</h1>
        <div className="content-4">
          <div className="input-registration">
            <div className="input-content">
              <label>{t("my_face")}</label>
              {imgSrc ? (
                <div className="screen">
                  {showLoading ? (
                    <Spinners.TailSpin
                      height="30"
                      width="30"
                      ariaLabel="tail-spin-loading"
                      radius="4"
                      color="#fff"
                    />
                  ) : (
                    <img src={imgSrc} width="100%" height="100%" alt="webcam" />
                  )}
                </div>
              ) : (
                <div className="screen" hidden={canShow ? false : true}>
                  <Webcam
                    ref={webcamRef}
                    width="100%"
                    height="100%"
                    mirrored={mirrored}
                    screenshotFormat="image/jpeg"
                    screenshotQuality={1}
                    imageSmoothing={true}
                    videoConstraints={videoConstraints}
                    onCanPlay={() => {
                      setCanShow(true);
                    }}
                  />
                </div>
              )}
              <div
                className={
                  canShow
                    ? "d-none"
                    : "screen d-flex justify-content-center align-items-center gap-1"
                }
                hidden={canShow}
                style={{}}
              >
                <Spinners.TailSpin
                  height="30"
                  width="30"
                  ariaLabel="tail-spin-loading"
                  radius="4"
                  color="#fff"
                />
                {t("camara_loading")}
              </div>
              <div className="btn-take-picture">
                {imgSrc ? (
                  <button onClick={retake}>
                    <RemixIcon.RiLoopRightLine size="18" /> {t("retake_img")}
                  </button>
                ) : (
                  <button onClick={capture}>
                    <RemixIcon.RiCamera3Line size="18" />
                    {t("capture_img")}
                  </button>
                )}
              </div>
            </div>
            {/*<small className="error">{errors.frontPassport}</small> */}
          </div>
        </div>
      </div>
    );
  }

  function stepForm4() {
    return (
      <div className="form-step">
        <h1>{t("person_to_contact")}</h1>
        <div className="content">
          <div className="input-registration">
            <div className="input-content">
              <label>{t("person_first_name")}</label>
              <input
                type="text"
                name="ptcName"
                value={secondEndPoint.ptcName}
                onChange={handleChange}
              />
            </div>
            <small className="error"></small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("person_address")}</label>
              <input
                type="text"
                name="ptcAddress"
                value={secondEndPoint.ptcAddress}
                onChange={handleChange}
              />
            </div>
            <small className="error"></small>
          </div>
          <div className="input-registration last-child">
            <div className="input-content">
              <label>{t("person_no")}</label>
              <input
                type="number"
                name="ptcPhone"
                value={secondEndPoint.ptcPhone}
                onChange={handleChange}
              />
            </div>
            <small className="error"></small>
          </div>
        </div>
      </div>
    );
  }

  return (
    showPage && (
      <div className="subscribe">
        <div className="left">
          <img className="login-image" src={registration} alt={registration} />
        </div>
        <div className="right">
          <div className="all-step">
            {stepComponent}
            <div className="step-button">
              <button type="button" onClick={handleBack}>
                <RemixIcon.RiArrowLeftLine />
                {t("back_button")}
              </button>
              <button
                type="button"
                onClick={handleContinue}
                disabled={showLoading}
              >
                {step === 4 ? "submit" : "Continue"}
                {step === 4 && showLoading ? (
                  <Spinners.TailSpin
                    height="20"
                    width="20"
                    ariaLabel="tail-spin-loading"
                    radius="4"
                    color="#fff"
                  />
                ) : (
                  <RemixIcon.RiArrowRightLine />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Register2;
