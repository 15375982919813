/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import Benefit from "../../../common/images/svg/benefit.svg";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { updateClientPassword } from "../../services/clientAuthService";
import ErrorManager from "../../errors";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const initialInputState = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);

  const [canSubmit, setCanSubmit] = useState(false);
  const [inputs, setInputs] = useState(initialInputState);
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const Navigate = useNavigate();

  const handleInputsChange = (e) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
    setErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  useEffect(() => {
    setCanSubmit(
      inputs.newPassword !== "" &&
        inputs.newPassword === inputs.confirmPassword,
    );
  }, [inputs]);

  useEffect(() => {
    setShowLoading(false);
  }, []);

  const updateUserPassword = (securityQuestion) => {
    return updateClientPassword(inputs, securityQuestion)
      .then((res) => {
        toast.success(res.data.data, {
          position: "top-right",
        });
        setInputs(initialInputState);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleSubMit = (e) => {
    e.preventDefault();
    setShowLoading(true);
    setVerifyCallback(updateUserPassword);
    setVerifySecureQuestions(true);
  };

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{t("change_pass_title")}</h4>
      <div className="card shadow bg-white border-0 ">
        <div className="card-body">
          <div className="row ">
            <div className="col-xl-4">
              <form
                onSubmit={handleSubMit}
                className="border-custom p-3 h-100 "
              >
                <div>
                  <div className="col-xl-12">
                    <FormInput
                      name="oldPassword"
                      label={t("enter_current_password")}
                      value={inputs.oldPassword}
                      type="password"
                      onchange={handleInputsChange}
                      error={errors.oldPassword} // Ajoutez cette ligne
                    />
                  </div>
                  <div className="col-xl-12">
                    <FormInput
                      name="newPassword"
                      label={t("enter_new_password")}
                      value={inputs.newPassword}
                      type="password"
                      onchange={handleInputsChange}
                      error={errors.newPassword} // Ajoutez cette ligne
                    />
                  </div>
                  <div className="col-xl-12">
                    <FormInput
                      name="confirmPassword"
                      label={t("confirm_password")}
                      value={inputs.confirmPassword}
                      type="password"
                      onchange={handleInputsChange}
                      error={errors.confirmPassword} // Ajoutez cette ligne
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={!canSubmit || showLoading}
                    className="btn btn-primary btn-sm "
                  >
                    {t("change_pass_submit")}
                  </button>
                </div>
              </form>
            </div>

            <div className="col-xl-1"></div>

            <div className="col-xl-5">
              <div className="card pb-0 border-0 ">
                <div className="card-body p-3">
                  <div className="media me-2 pb-5 d-flex justify-content-center">
                    <img width="100" height="100" src={Benefit} alt="benefit" />
                  </div>
                  <div
                    style={{ height: "300px" }}
                    id="DZ_W_Todo3"
                    className="widget-media dz-scroll height270  ps--active-y"
                  >
                    <ul className="timeline">
                      <li>
                        <p className="pl-5 fs-5 d-flex justify-content-center text-center">
                          {t("update_password_info")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
