import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { resetPassword } from "../../services/clientAuthService";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import ImageLogin from "../../../common/images/login/1.svg";
import "../../../styles/login.css";
import { Logout } from "../../../common/services";
import { baseUrlClient } from "../../../common/constant";
import { useTranslation } from "react-i18next";
import AppTranslation from "../../../common/jsx/components/AppTranslation";
import logo from "../../../common/images/logo/logo.png";
import PasswordInput from "../../../common/jsx/components/PasswordInput";
import { COMPANY_NAME_FULL } from "../../../config";

function SetNewPassword() {
  const { tb } = AppTranslation();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { guid } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  function handleCheckPassword(e) {
    e.preventDefault();

    // Expression régulière pour vérifier le mot de passe
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;

    if (password === "") {
      setErrors({
        password: t("user_pass_error"),
      });
    } else if (confirmPassword === "") {
      setErrors({
        ...errors,
        confirmPassword: t("confirm_pass_error"),
      });
    } else if (password !== confirmPassword) {
      setErrors({
        confirmPassword: t("confirm_diff_pass_error"),
      });
    } else {
      if (!passwordRegex.test(password)) {
        setErrors({
          password: t("pass_requirement"),
        });
      } else {
        setShowLoading(true);
        const body = {
          newPassword: password,
          confirmPassword: confirmPassword,
        };
        resetPassword(guid, body)
          .then(() => {
            Logout(navigate, baseUrlClient);
            toast.success(t("pass_modified"));
            sessionStorage.removeItem("ClientOtpToken");
          })
          .catch((err) => {
            setErrors({
              password: "",
              confirmPassword: "",
            });

            if (err.response) {
              if (err.response.status === 401) {
                toast.warning(err.response.data.message, {
                  position: "top-right",
                });
              }
            } else {
              ErrorManager(err);
            }
          })
          .finally(() => {
            setShowLoading(false);
          });
      }
    }
  }

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });
  return (
    <div className="conteneur">
      <div className="content">
        <form onSubmit={handleCheckPassword} className="left">
          <div className="sm-welcome">
            <h1> {t("login_title")} </h1>
            <p>{t("login_page_intro")}</p>
          </div>
          <img src={logo} width="35%" alt="logo" />
          <h2 className="head-title">{t("new_pass")}</h2>
          <div className="input-box">
            <div className="icon">
              <RemixIcon.RiKey2Line size={20} />
            </div>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("enter_new_pass")}
              showPasswordToggle={true}
              autoFocus={true}
            />
          </div>
          <small className="input-error">{errors.password}</small>
          <div className="input-box">
            <div className="icon">
              <RemixIcon.RiKey2Line size={20} />
            </div>
            <PasswordInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t("confirm_new_pass")}
              showPasswordToggle={true}
            />
          </div>
          <small className="input-error">{errors.confirmPassword}</small>

          <button className="btn-custom" type="submit" disabled={showLoading}>
            {showLoading ? (
              <Spinners.TailSpin
                height="20"
                width="20"
                ariaLabel="tail-spin-loading"
                radius="4"
                color="#fff"
              />
            ) : (
              <RemixIcon.RiSaveLine />
            )}
            {t("save_pass")}
          </button>
        </form>
        <div className="right right-forgot-password">
          <img className="image-login" src={ImageLogin} alt={ImageLogin} />
          <h1 className="fw-bold">{COMPANY_NAME_FULL}</h1>
          <p>{tb("login_page_intro")}</p>
          <button onClick={() => Logout(navigate, baseUrlClient)}>
            {t("login")}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(SetNewPassword);
