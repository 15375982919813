import React, { useEffect, useState, useContext, useRef } from "react";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import {
  getOneClientRequest,
  validateClientAccountRequest,
} from "../../services/subscriptionService";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import "../../styles/detailsAccountRequest.css";
import { Modal } from "react-bootstrap";
import ErrorManager from "../../../appClient/errors";
import { toast } from "react-toastify";
import { viewMedia } from "../../services/media";
import * as Spinners from "react-loader-spinner";
import { baseUrlAdmin } from "../../../common/constant";
import { t } from "i18next";
import PlaceHolderClient from "../../../common/images/placeholder/placeholder-icon.jpg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";

const DetailsAccountRequest = () => {
  const { uuid } = useParams();
  const Navigate = useNavigate();
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const pdfContentRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [data, setData] = useState({});
  const [pictures, setPictures] = useState({
    profil: "",
    frontIdCard: "",
    backIdCard: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState("");

  const binaryToDataURL = (binaryData) => {
    return `data:image/jpeg;base64,${binaryData}`;
  };

  useEffect(() => {
    setShowLoading(true);
    const getDetails = async () => {
      try {
        const resultDetails = await getOneClientRequest(uuid);
        setData(resultDetails.data.data);

        const profil = await viewMedia(resultDetails.data.data.photoUuid);
        setPictures((prevState) => ({
          ...prevState,
          profil: profil.data.data,
        }));

        const frontIdCard = await viewMedia(
          resultDetails.data.data.frontIdUuid,
        );
        setPictures((prevState) => ({
          ...prevState,
          frontIdCard: frontIdCard.data.data,
        }));

        const backIdCard = await viewMedia(resultDetails.data.data.backIdUuid);
        setPictures((prevState) => ({
          ...prevState,
          backIdCard: backIdCard.data.data,
        }));
      } catch (err) {
        ErrorManager(err, Navigate);
      } finally {
        setShowLoading(false);
      }
    };

    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const handleRejectedModal = () => {
    setShowModal(true);
  };

  const handleRejected = (e) => {
    e.preventDefault();
    setShowLoading(true);
    const body = {
      status: "REJECTED",
      comment: comment,
    };
    validateClientAccountRequest(uuid, body)
      .then((res) => {
        toast.success("account rejected !", {
          position: "top-right",
        });
        setComment("");
        Navigate(`${baseUrlAdmin}/account-register`);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const generatePDF = async () => {
    setIsButtonDisabled(true);

    try {
      if (!pdfContentRef.current) return;

      const input = pdfContentRef.current;
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });
      // Convertir les dimensions du canvas en millimètres pour ajuster l'image dans le PDF
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Details account request ${data.name + " " + data.surname}.pdf`);

      // UPDATE STATUS to DOWNLOADED
      const body = {
        status: "DOWNLOADED",
        comment: "Downloaded",
      };

      validateClientAccountRequest(uuid, body)
        .then((res) => {
          toast.success(t("account_pdf_downloaded"), { position: "top-right" });
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });

      Swal.fire({
        icon: "success",
        title: "PDF prêt à être téléchargé!",
        text: "Votre PDF a été généré avec succès.",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Erreur",
        text: "Une erreur est survenue lors de la génération du PDF.",
      });
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <MainPageTitle
        mainTitle="Details account request"
        pageTitle="account request"
        parentTitle="Subscriptions"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="m-2">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => Navigate("/adminportal/account-register")}
              >
                {t("back_button")}
              </button>
            </div>
            {data && (
              <div className="card rounded-0 card-bx m-b30 p-3">
                <div ref={pdfContentRef} className="">
                  <div className="row d-flex pe-3 ps-3">
                    <div className="d-flex align-items-center col-xl-2 col-lg-2 col-md-2 ps-0 content-img details-avatar">
                      {
                        pictures.profil ? (
                          <img
                            src={binaryToDataURL(pictures.profil)}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            alt="profil"
                          />
                        ) : (
                          // <div className='d-flex align-items-center justify-content-center border-custom w-100 h-100'>
                          <img
                            src={PlaceHolderClient}
                            alt="PlaceHolderClient"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )
                        // </div>}
                      }
                      {pictures.profil && (
                        <a
                          href={binaryToDataURL(pictures.profil)}
                          className="download-img"
                          download={`profile__${data.name}`}
                        >
                          <button className="btn btn-danger btn-sm">
                            {t("download_button")}
                          </button>
                        </a>
                      )}
                    </div>
                    <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 mt-4 pt-md-4 pt-sm-4 pb-md-3 pb-sm-3 ps-3 gap-sm-3 d-flex flex-lg-row flex-xl-row flex-column align-items-xl-center align-items-lg-center align-items-sm-start align-items-xs-start position-relative border">
                      <div
                        className="mb-3 position-absolute"
                        style={{ top: "-10px" }}
                      >
                        <span className="p-1 bg-dark text-white">
                          {t("basic_info")}
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 d-flex gap-5 align-items-center">
                        <div className="d-flex flex-column gap-4">
                          <span className="fw-bold">{t("f_name")} </span>
                          <span className="fw-bold">{t("gender")} </span>
                          <span className="fw-bold">{t("dob")} </span>
                        </div>
                        <div className="d-flex flex-column gap-4">
                          <span>
                            {data.name ? data.name + " " + data.surname : "---"}
                          </span>
                          <span>{data.gender ? data.gender : "---"}</span>
                          <span>{data.birthDate ? data.birthDate : "---"}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 d-flex mt-xs-5 gap-4 align-items-center">
                        <div className="d-flex flex-column gap-4">
                          <span className="fw-bold">{t("country")} </span>
                          <span className="fw-bold">{t("profession")} </span>
                          <span className="fw-bold">{t("nationality")} </span>
                        </div>
                        <div className="d-flex flex-column gap-4">
                          <span>
                            {data.countryOfResidence
                              ? data.countryOfResidence
                              : "---"}
                          </span>
                          <span>
                            {data.profession ? data.profession : "---"}
                          </span>
                          <span>
                            {data.nationality ? data.nationality : "---"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between position-relative border m-1 mt-5 p-3 ps-2 pt-4 gap-3">
                    <div
                      className="mb-3 position-absolute"
                      style={{ top: "-10px" }}
                    >
                      <span className="p-1 bg-dark text-white">
                        {t("contact_info_title")}
                      </span>
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex gap-5 align-items-center">
                      <div className="d-flex flex-column gap-4">
                        <span className="fw-bold">{t("address")}</span>
                        <span className="fw-bold">{t("phone_number")} </span>
                        <span className="fw-bold">{t("Phone_number2")} </span>
                      </div>
                      <div className="d-flex flex-column gap-4">
                        <span>{data.email ? data.email : "---"}</span>
                        <span>
                          {data.phoneNumber1 ? data.phoneNumber1 : "---"}
                        </span>
                        <span>
                          {data.phoneNumber2 ? data.phoneNumber2 : "---"}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex gap-5 align-items-center">
                      <div className="d-flex flex-column gap-4">
                        <span className="fw-bold">
                          {t("subscribe_current_address")}
                        </span>
                        <span className="fw-bold">{t("address2")}</span>
                        <span className="fw-bold">{t("po_box")} </span>
                      </div>
                      <div className="d-flex flex-column gap-4">
                        <span>{data.address1 ? data.address1 : "---"}</span>
                        <span>{data.address2 ? data.address2 : "---"}</span>
                        <span>{data.poBox ? data.poBox : "---"}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between position-relative border m-1 mt-5 p-3 ps-2 pt-4">
                    <div
                      className="mb-3 position-absolute"
                      style={{ top: "-10px" }}
                    >
                      <span className="p-1 bg-dark text-white">
                        {t("person_to_contact")}
                      </span>
                    </div>
                    <div className="col-lg-12 d-flex gap-5 align-items-center">
                      <div className="d-flex flex-column gap-4">
                        <span className="fw-bold">{t("f_name")} </span>
                        <span className="fw-bold">{t("phone_number")} </span>
                        <span className="fw-bold">{t("address")} </span>
                      </div>
                      <div className="d-flex flex-column gap-4">
                        <span>{data.ptcName ? data.ptcName : "---"}</span>
                        <span>{data.ptcPhone ? data.ptcPhone : "---"}</span>
                        <span>{data.ptcAddress ? data.ptcAddress : "---"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between position-relative border m-1 mt-5 p-3 ps-2 pt-4 flex-lg-row flex-md-row flex-sm-column gap-3">
                    <div
                      className="mb-3 position-absolute"
                      style={{ top: "-10px" }}
                    >
                      <span className="p-1 bg-dark text-white">
                        {t("Identification_informations")}
                      </span>
                    </div>
                    <div className="col-lg-4 col-md-5 col-sm-12 d-flex gap-5 align-items-center">
                      <div className="d-flex flex-column gap-4">
                        <span className="fw-bold">
                          {t("Identification_type ")}
                        </span>
                        <span className="fw-bold">
                          {t("Identification_number")}
                        </span>
                        <span className="fw-bold">{t("Issue_date")} </span>
                        {data.identificationType === "NIC" && (
                          <span className="fw-bold">
                            {t("expiration_date")}{" "}
                          </span>
                        )}
                      </div>
                      <div className="d-flex flex-column gap-4">
                        <span>
                          {data.identificationType
                            ? data.identificationType
                            : "---"}
                        </span>
                        <span>
                          {data.identificationNumber
                            ? data.identificationNumber
                            : "---"}
                        </span>
                        <span>
                          {data.idIssueDate ? data.idIssueDate : "---"}
                        </span>
                        {data.identificationType === "NIC" && (
                          <span>{data.idExpDate ? data.idExpDate : "---"}</span>
                        )}
                      </div>
                    </div>
                    <div className="custom-sm-card col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center justify-content-sm-start align-items-center flex-lg-row flex-md-row gap-5">
                      <div className="col-lg-5 col-md-5 col-sm-5">
                        <span className="fw-bold">
                          {" "}
                          {data.identificationType === "NIC"
                            ? t("id_front_NIC")
                            : t("id_front_passport")}
                        </span>
                        {pictures.frontIdCard ? (
                          <div
                            className="content-img"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "150px",
                              maxWidth: "200px",
                              width: "100%",
                              height: "auto",
                            }}
                          >
                            <img
                              src={binaryToDataURL(pictures.frontIdCard)}
                              width="100%"
                              alt="front card"
                            />
                            {pictures.frontIdCard && (
                              <a
                                href={binaryToDataURL(pictures.frontIdCard)}
                                className="download-img"
                                download={`front-id-card__${data.name}`}
                              >
                                <button className="btn btn-danger btn-sm">
                                  {t("download_button")}
                                </button>
                              </a>
                            )}
                          </div>
                        ) : (
                          <div
                            className="border-custom"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "150px",
                              maxWidth: "200px",
                              width: "100%",
                              height: "150px",
                            }}
                          >
                            {showLoading ? (
                              <Spinners.TailSpin
                                height="30"
                                width="30"
                                ariaLabel="tail-spin-loading"
                                radius="4"
                                color="#000"
                              />
                            ) : (
                              t("image_not_set")
                            )}
                          </div>
                        )}
                      </div>
                      {data.identificationType === "NIC" && (
                        <div className="col-lg-5 col-md-5 col-sm-5">
                          <span className="fw-bold">{t("id_back")}</span>
                          {pictures.backIdCard ? (
                            <div
                              className="content-img"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "150px",
                                maxWidth: "200px",
                                width: "100%",
                                height: "auto",
                              }}
                            >
                              <img
                                src={binaryToDataURL(pictures.backIdCard)}
                                width="100%"
                                alt="back card"
                              />
                              {pictures.backIdCard && (
                                <a
                                  href={binaryToDataURL(pictures.backIdCard)}
                                  className="download-img"
                                  download={`back-id-card__${data.name}`}
                                >
                                  <button className="btn btn-danger btn-sm">
                                    {t("download_button")}
                                  </button>
                                </a>
                              )}
                            </div>
                          ) : (
                            <div
                              className="border-custom"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minWidth: "150px",
                                maxWidth: "200px",
                                width: "100%",
                                height: "150px",
                              }}
                            >
                              {showLoading ? (
                                <Spinners.TailSpin
                                  height="30"
                                  width="30"
                                  ariaLabel="tail-spin-loading"
                                  radius="4"
                                  color="#000"
                                />
                              ) : (
                                t("image_not_set")
                              )}
                            </div>
                          )}
                        </div>
                      )}

                      {data.identificationType === null && (
                        <div className="col-lg-5 col-md-5 col-sm-5">
                          <span className="fw-bold">{t("id_back")}</span>
                          <div
                            className="border-custom"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "150px",
                              maxWidth: "200px",
                              width: "100%",
                              height: "150px",
                            }}
                          >
                            {showLoading ? (
                              <Spinners.TailSpin
                                height="30"
                                width="30"
                                ariaLabel="tail-spin-loading"
                                radius="4"
                                color="#000"
                              />
                            ) : (
                              t("image_not_set")
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {data.status !== "PENDING" ? (
                  <p>{data.message}</p>
                ) : (
                  <div className="d-flex gap-2 mt-4">
                    {/* <button className='btn btn-primary btn-sm'>{t('accept_button')}</button> */}
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={generatePDF}
                      disabled={showLoading || isButtonDisabled}
                    >
                      {isButtonDisabled
                        ? "Génération en cours..."
                        : "Générer le PDF"}
                    </button>
                    <button
                      className="btn btn-outline-danger btn-sm"
                      onClick={handleRejectedModal}
                    >
                      {t("reject_button")}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal className="fade" size="md" show={showModal} centered>
        {/* <Modal.Header style={{ height: "3rem" }} className="bg-danger">
               <Modal.Title className="text-white">{t('rejected_account')}</Modal.Title>
               <button onClick={() => setShowModal(false)} className="btn-close btn btn-danger"></button>
            </Modal.Header> */}
        <form onSubmit={handleRejected}>
          <Modal.Body className="d-flex flex-column">
            <label htmlFor="comment">{t("reason")} </label>
            <textarea
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              name="comment"
              className="border-custom p-2"
              autoFocus
              style={{ width: "100%", height: "100px", resize: "none" }}
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger btn-sm me-3"
              disabled={showLoading || comment !== "" ? false : true}
            >
              {t("rejected_account")}
            </button>
            <button
              onClick={() => setShowModal(false)}
              type="button"
              className="btn btn-outline-danger btn-sm"
              disabled={showLoading}
            >
              {t("cancel_button")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default DetailsAccountRequest;
