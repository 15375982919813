import React, { useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { CUSTOMERPORTAL } from "../../store/selectors/authSelectors";
import {
  resendOtp,
  verifyCustomerOtp,
} from "../../../appClient/services/clientAuthService";
import { saveTokenInSessionStorage, useQuery } from "../../services";
import { clientLoginConfirmedAction } from "../../../appClient/actions/clientAuthActions";
import ErrorManager from "../../../appClient/errors";
import { decryptData, encryptData } from "../../../utils/utilsCrypto";
import { toast } from "react-toastify";
import "./autOtpPage.css";
import { t } from "i18next";
import { accesPortalKey } from "../../constant";

function AutOtpPage() {
  const [otp, setOTP] = useState(["", "", "", "", ""]);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [trialsLeft, setTrialsLeft] = useState(0);
  const [maxTrials, setMaxTrials] = useState(0);
  const [trialsClass, setTrialsClass] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTimeExpired, setIsTimeExpired] = useState(false);
  let query = useQuery();
  const dataUrl = query.get("m");
  const [propsUrl, setPropsUrl] = useState(null);

  useEffect(() => {
    if (dataUrl) {
      try {
        const decryptedData = decryptData(dataUrl);
        setPropsUrl(decryptedData);
      } catch (error) {
        sessionStorage.clear();
        navigate("/auth/login");
        toast.error(t("corrupted_data"));
      }
    } else {
      sessionStorage.clear();
      navigate("/auth/login");
      toast.error(t("corrupted_data"));
    }
  }, [dataUrl, navigate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (propsUrl) {
      inputRefs.current[0].focus();
      setTimeout(() => {
        setShowLoading(false);
      }, 500);

      setMaxTrials(propsUrl.authInfo.maxTrials);
      setTrialsLeft(propsUrl.authInfo.trials);

      const expiresAt = new Date(
        propsUrl.authInfo.verificationObject.expiresAt,
      );
      const now = new Date();
      const initialTimeLeft = Math.floor((expiresAt - now) / 1000);
      setTimeLeft(initialTimeLeft);

      const interval = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft <= 1) {
            clearInterval(interval);
            setIsTimeExpired(true);
            return 0;
          }
          return prevTimeLeft - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsUrl]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (trialsLeft === 1) {
      setTrialsClass("text-danger");
    } else if (trialsLeft < maxTrials / 2) {
      setTrialsClass("text-warning");
    } else {
      setTrialsClass("");
    }
  }, [trialsLeft, maxTrials]);

  const handleChange = (index, e) => {
    const { value } = e.target;
    if (isNaN(value)) return;

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const portal = sessionStorage.getItem(accesPortalKey);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoading(true);
    const enteredOTP = otp.join("");
    const body = {
      role: propsUrl.authInfo.verificationObject.role,
      otp: Number(enteredOTP),
    };

    const roleActions = {
      FIRST_LOGIN: {
        navigateTo: "/auth/set-security-questions",
        toastMessage: t("define_your_security_question"),
      },
      SUBSCRIBER: {
        navigateTo: "/auth/login",
        toastMessage: t("account_registed"),
      },
      RESET_PASSWORD: {
        navigateTo: `/auth/new-password/${propsUrl.authInfo.verificationObject.guid}`,
        toastMessage: null,
      },
      DOUBLE_AUTHENTICATION: {
        navigateTo: "/home",
        toastMessage: t("Welcome_login"),
      },
      DEFAULT: {
        navigateTo: "/home",
        toastMessage: t("Welcome_login"),
      },
    };

    const { navigateTo, toastMessage } =
      roleActions[propsUrl?.authInfo?.verificationObject?.role] ||
      roleActions["DEFAULT"];
    if (portal === CUSTOMERPORTAL) {
      verifyCustomerOtp(propsUrl.authInfo.verificationObject.guid, body)
        .then((v) => {
          const resData = v.data.data;
          // const dataCrypted = encryptData(resData);
          if (
            propsUrl?.authInfo?.verificationObject?.role ===
            "DOUBLE_AUTHENTICATION"
          ) {
            saveTokenInSessionStorage(resData);
            dispatch(clientLoginConfirmedAction(resData));
          }

          if (propsUrl?.authInfo?.verificationObject?.role === "FIRST_LOGIN") {
            navigate(navigateTo);
            saveTokenInSessionStorage(resData);
            dispatch(clientLoginConfirmedAction(resData));
          } else {
            navigate(navigateTo);
          }

          if (toastMessage) {
            toast.success(toastMessage, { position: "top-right" });
          }
        })
        .catch((err) => {
          // if (err.response.status === 401) {
          //     sessionStorage.clear();
          // }
          if (err.response.status === 403) {
            const object = err.response.data.object;
            setMaxTrials(object.maxTrials);
            setTrialsLeft(object.trials);
            toast.error(err.response.data.message, { position: "top-right" });
          } else {
            ErrorManager(err, navigate);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const handleSubmitResendOtp = (e) => {
    e.preventDefault();
    setIsTimeExpired(false);
    setShowLoading(true);
    resendOtp(
      propsUrl.authInfo.verificationObject.uuid,
      propsUrl.authInfo.verificationObject.guid,
    )
      .then((res) => {
        toast.success(t("check_your_email"));
        setTimeout(() => {
          toast.success(t("check_your_email_details"), {
            position: "top-right",
          });
        }, 1000);
        const data = {
          ...res.data.data,
          portal: "CUSTOMERPORTAL",
        };
        setOTP(["", "", "", "", ""]);
        const dataCrypted = encryptData(data);
        navigate(`/auth/otp-verification?m=${dataCrypted}`);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          sessionStorage.clear();
        }
        if (err.response.status === 403) {
          const object = err.response.data.object;
          setMaxTrials(object.maxTrials);
          setTrialsLeft(object.trials);
          toast.error(err.response.data.message, { position: "top-right" });
        } else {
          ErrorManager(err, navigate);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const getTitleByRole = (role) => {
    switch (role) {
      case "DOUBLE_AUTHENTICATION":
        return t("double_authentication_title");
      case "SUBSCRIPTION_REQUEST":
        return t("first_subscribe");
      case "RESET_PASSWORD":
        return t("change_pass_title");
      default:
        return t("first_login_verification");
    }
  };

  const isOTPComplete = otp.every((digit) => digit !== "");
  const isButtonDisabled = !isOTPComplete || showLoading || isTimeExpired;

  return (
    <div className="container height-100 d-flex justify-content-center align-items-center">
      <div className="position-relative">
        <div className="card card-custom shadow rounded-0 p-3 text-center">
          <h2 className="mb-4 fw-bold text-uppercase text-primary">
            {getTitleByRole(propsUrl?.authInfo?.verificationObject?.role)}
          </h2>
          <div className="d-flex flex-column">
            <span>{t("input_otp_send")}</span>
            <span>
              <strong className="text-primary">{t("otp_description1")}</strong>{" "}
              {t("otp_description2")}
            </span>
          </div>
          <form onSubmit={handleSubmit}>
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-2"
            >
              {otp.map((digit, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className="m-2 text-center form-control rounded"
                  type="text"
                  value={digit}
                  maxLength="1"
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
            <div className="mt-4 mb-4">
              <button
                type="submit"
                className="btn btn-primary px-4 validate"
                disabled={isButtonDisabled}
              >
                Validate
              </button>
            </div>
          </form>
          <div className="text-center">
            {timeLeft > 0 ? (
              <span>
                transaction request will expire in:{" "}
                <strong>{formatTime(timeLeft)}</strong>
              </span>
            ) : (
              <span>
                Did not receive an OTP?
                <NavLink
                  className="text-primary"
                  to="#"
                  onClick={handleSubmitResendOtp}
                >
                  {" "}
                  Click here to resend{" "}
                </NavLink>
              </span>
            )}
            {propsUrl?.authInfo?.verificationObject?.role !== "SUBSCRIBER" && (
              <h4 className={trialsClass}>Trials left: {trialsLeft}</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    verificationInfo: state.otp.otpInfo,
    userDao: state.otp.userDao,
    portal: state.otp.portal,
  };
};

export default connect(mapStateToProps)(AutOtpPage);
