import { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../appAdmin/pages/auth/Login";
import { AuthLayout } from "../common/jsx/pages";
import { useDispatch } from "react-redux";
import { createAdminInterceptor } from "../common/axiosInstance";

const AuthAdminRouter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    createAdminInterceptor(dispatch, navigate);
  }, [dispatch, navigate]);

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Login />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AuthAdminRouter;
