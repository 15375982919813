/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getCustomerAccounts,
  listCustomerChecksByAccount,
  saveCheckBookRequest,
} from "../../services/acountServices";
import { Modal } from "react-bootstrap";
import { FormInput } from "../../../common/jsx/components/FormInput";
import Benefit from "../../../common/images/svg/benefit.svg";
import swal from "sweetalert";
import * as Spinners from "react-loader-spinner";
import ErrorManager from "../../errors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CheckBookRequest = () => {
  const { t } = useTranslation();
  // const [accountList, setAccountsList] = useState([])
  const [checkList, setCheckList] = useState([]);
  const [optionAccounts, setOptionAccounts] = useState([]);
  const [hasCurrentAccount, setHasCurrentAccount] = useState(true);
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);
  const [canSubmit, setCanSubmit] = useState(false);
  const initialInputs = {
    accountId: "",
    numberPages: "",
    reason: "",
    checkbookNumber: "",
    blockMissingCheck: t("no"),
  };
  const [inputs, setInputs] = useState(initialInputs);
  const [bookNumberRequired, setBookNumberRequired] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const numberPages = [
    {
      label: "100",
      value: "100",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "25",
      value: "25",
    },
  ];

  const reasons = [
    {
      label: t("stolen_missing"),
      value: "stolen/ missing",
    },
    {
      label: t("exhausted"),
      value: "exhausted",
    },
    {
      label: t("new_checkbook"),
      value: "new checkbook",
    },
  ];

  const Navigate = useNavigate();

  const getPageData = () => {
    setShowLoading(true);
    return getCustomerAccounts()
      .then((v) => {
        const data = v.data.data;
        // setAccountsList(data)
        const currentAccounts = data.filter(
          (v) => v.productType.trim() === "CURRENT",
        );
        if (currentAccounts.length === 0) {
          setHasCurrentAccount(false);
        } else {
          setOptionAccounts(
            currentAccounts.map((act) => ({
              value: act.accountID,
              label: `${act.accountID} -${act.accountName}`,
            })),
          );
          setHasCurrentAccount(true);
        }
        return data;
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setInitialLoad(false);
      });
  };

  useEffect(() => {
    getPageData().then((v) => setShowLoading(false));
  }, []);

  useEffect(() => {
    let val = true;
    Object.keys(inputs).forEach((i) => {
      if (inputs[i] === "") {
        val = false;
      }
    });
    setCanSubmit(val);
  }, [inputs]);

  useEffect(() => {
    if (bookNumberRequired) {
      listCustomerChecksByAccount(inputs.accountId)
        .then((v) => {
          const data = v.data.data;
          setCheckList(
            data.map((c) => ({
              label: `${c.startSeries}-${c.endSeries}`,
              value: c.endSeries,
            })),
          );
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        });
      setInputs((prevState) => {
        return {
          ...prevState,
          checkbookNumber: "",
        };
      });
    } else {
      setInputs((prevState) => {
        return {
          ...prevState,
          checkbookNumber: "0",
        };
      });
    }
  }, [bookNumberRequired]);

  const checkBookRequest = (securityQuestions) => {
    setShowLoading(true);
    return saveCheckBookRequest(inputs, securityQuestions)
      .then((v) => {
        toast.success(t("request_made"), { position: "top-right" });
        Navigate("/account/check-book-request-status");
        return v;
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleSubMit = async (e) => {
    e.preventDefault();
    if (inputs.reason === "stolen/ missing") {
      await swal({
        title: t("block_missing_check_book"),
        text: t("suspend_checkbook!"),
        icon: t("info"),
        buttons: {
          cancel: {
            text: t("no"),
            visible: true,
          },
          confirm: {
            text: t("yes"),
            visible: true,
          },
        },
      })
        .then((willBlock) => {
          if (willBlock) {
            setInputs((prevState) => {
              return {
                ...prevState,
                blockMissingCheck: t("yes"),
              };
            });
          } else {
            setInputs((prevState) => {
              return {
                ...prevState,
                blockMissingCheck: t("no"),
              };
            });
          }
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        });
    }
    setVerifySecureQuestions(true);
    setVerifyCallback(checkBookRequest);
  };

  const handleInputsChange = (name, value) => {
    setInputs((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (name === "reason" && value === "stolen/ missing") {
      setBookNumberRequired(true);
    } else if (name === "reason" && value !== "stolen/ missing") {
      setBookNumberRequired(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <div className="container-fluid">
        <h4 className="fw-bold">{t("check_book_request")}</h4>
        <div className="card bg-white border-0 shadow">
          <div className="card-body">
            <div className="row">
              <div className="col-xl-4">
                <form
                  onSubmit={handleSubMit}
                  className="border-custom p-3 h-100 "
                >
                  <div>
                    <div className="col-xl-12">
                      <FormInput
                        name="accountId"
                        label={t("select_account")}
                        data={optionAccounts}
                        value={inputs.accountId}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("accountId", e.value)
                        }
                      />
                    </div>
                    <div className="col-xl-12">
                      <FormInput
                        name="numberPages"
                        label={t("select_number_of_pages")}
                        data={numberPages}
                        value={inputs.numberPages}
                        type="select"
                        onchange={(e) =>
                          handleInputsChange("numberPages", e.value)
                        }
                      />
                    </div>
                    <div className="col-xl-12">
                      <FormInput
                        name="reason"
                        label={t("what_happened")}
                        data={reasons}
                        value={inputs.reason}
                        type="select"
                        onchange={(e) => handleInputsChange("reason", e.value)}
                      />
                    </div>
                    {bookNumberRequired ? (
                      <div>
                        <div className="col-xl-12">
                          <FormInput
                            name="checkbookNumber"
                            label={t("select_reference_of_missing_check")}
                            data={checkList}
                            value={inputs.checkbookNumber}
                            type="select"
                            required={false}
                            onchange={(e) =>
                              handleInputsChange("checkbookNumber", e.value)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className=" mt-3 ">
                    <button
                      type="submit"
                      disabled={!canSubmit || showLoading}
                      className="btn btn-primary btn-sm "
                    >
                      {t("submit_button")}
                    </button>
                  </div>
                </form>
              </div>

              <div className="col-xl-1 mb-5"></div>

              <div className="col-xl-7">
                <div className="card border-0 pb-0">
                  <div className="card-body border-0 ">
                    <div className="media me-2 pb-5 d-flex justify-content-center">
                      <img
                        width="100"
                        height="100"
                        src={Benefit}
                        alt="benefit"
                      />
                    </div>
                    <div
                      style={{ height: "300px" }}
                      id="DZ_W_Todo3"
                      className="widget-media dz-scroll height270  ps--active-y"
                    >
                      <ul className="timeline">
                        <li>
                          {/* <h5 className="mb-2 fs-5 d-flex justify-content-center">
                                                    {t('change_pass_educate')}
                                                    </h5>
                                                    <h2 className="mb-2 fs-5 text-warning">
                                                    {t('change_pass_force')}
                                                    </h2> */}
                          <p className="pl-5 fs-5 text-center">
                            {t("check_book_request_body")}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        size="sm"
        show={initialLoad || (!showLoading && !hasCurrentAccount)}
        centered
      >
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
          {!showLoading && !hasCurrentAccount && (
            <div className="text-center">
              <h5 className="mb-2 fs-5 text-center">
                {t("Sorry_you_do_not_have_a_current_accounts")}
              </h5>
              <button
                className="btn btn-warning btn-sm me-3"
                onClick={() => Navigate("/home")}
              >
                {t("ok_button")}
              </button>
            </div>
          )}

          {showLoading && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Spinners.TailSpin
                height="40"
                width="40"
                ariaLabel="tail-spin-loading"
                radius="1"
                color="#000"
              />
              <p>Recherche des comptes cheques</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckBookRequest;
