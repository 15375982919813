import React, { useContext, useEffect, useState } from "react";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  listInstitutionConfig,
  updateInstitutionConfig,
} from "../../services/configurationService";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import ErrorManager from "../../../appClient/errors";
import { useNavigate } from "react-router-dom";
import { baseUrlAdmin } from "../../../common/constant";
import { t } from "i18next";
import { toast } from "react-toastify";

const InstitutionConfig = () => {
  const navigate = useNavigate();
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const inputBlog = [
    { label: t("institution_name"), name: "institutionName", value: "" },
    {
      label: t("institution_short_name"),
      name: "institutionShortName",
      value: "",
    },
    { label: t("max_security_questions"), name: "maxSecurityQuest", value: 0 },
    { label: t("min_security_questions"), name: "minSecurityQuest", value: 0 },
    { label: t("otp_min_before_expire"), name: "otpMinBeforeExpire", value: 0 },
    { label: t("institution_email"), name: "institutionEmail", value: "" },
    { label: t("email_password"), name: "emailPassword", value: "" },
    { label: t("return_URL"), name: "returnUrl", value: "" },
    { label: t("email_no_reply"), name: "emailNoReply", value: "" },
    { label: t("host"), name: "host", value: "" },
    { label: t("port"), name: "port", value: 0 },
    { label: t("verify_quest_number"), name: "verifyQuestNumber", value: 0 },
    { label: t("max_verify_attempt"), name: "maxVerifyAttempt", value: 0 },
    { label: t("default_package"), name: "defaultPackage", value: "" },
    {
      label: t("subscription_method"),
      name: "subMethod",
      value: "AUTOMATIC",
      type: "select",
      options: [
        { label: t("manual"), value: "MANUAL" },
        { label: t("automatic"), value: "AUTOMATIC" },
      ],
    },
    {
      label: t("verification_reset_timer"),
      name: "verificationResetTimer",
      value: 0,
    },
    {
      label: t("verification_prompt"),
      name: "questConfig",
      value: "MANUAL",
      type: "select",
      options: [
        { label: t("manual"), value: "MANUAL" },
        { label: t("automatic"), value: "AUTO" },
      ],
    },
    // { label: 'Logo', name: 'logo', value: '' },
    // { label: 'Image', name: 'img', value: '' },
    { label: t("town"), name: "town", value: "" },
    { label: t("phone"), name: "phone", value: "" },
    { label: t("pobox"), name: "pobox", value: "" },
  ];

  const [otherData, setOtherData] = useState({});
  const [initialFormData, setInitialFormData] = useState(() => {
    const initialState = {};
    inputBlog.forEach((field) => (initialState[field.name] = field.value));
    return initialState;
  });
  const [formData, setFormData] = useState(() => {
    const initialState = {};
    inputBlog.forEach((field) => (initialState[field.name] = field.value));
    return initialState;
  });

  const getPageData = async () => {
    setShowLoading(true);
    try {
      try {
        const v = await listInstitutionConfig();
        const data = v.data.data;
        setOtherData(data);
        const newFormData = {};
        inputBlog.forEach((field) => {
          newFormData[field.name] = data[field.name] || field.value;
        });
        setFormData(newFormData);
        setInitialFormData(newFormData);
        return data;
      } catch (err) {
        ErrorManager(err, navigate);
      }
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (event, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: event.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);
    const updatedData = { ...otherData, ...formData };
    // delete updatedData.id // Remove the id before sending data
    updateInstitutionConfig(updatedData)
      .then((res) => {
        toast.success(t("changes_saved_successfully"), {
          position: "top-right",
        });
        getPageData();
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const hasChanges = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormData);
  };

  return (
    <>
      <MainPageTitle
        mainTitle="Dashboard"
        pageTitle={t("general_config")}
        parentTitle={"Configuration"}
      />
      <div className="container-fluid">
        <div className="row p-3 pt-0">
          <div className="card rounded-0 shadow card-bx m-b30 m-">
            <div className="card-header">
              <h6 className="title">{t("general_configurations")}</h6>
            </div>
            <form
              type="PUT"
              action={`${baseUrlAdmin}/instConfig/update`}
              onSubmit={handleSubmit}
              className="profile-form"
            >
              <div className="card-body">
                <div className="row">
                  {inputBlog.map((item, ind) => (
                    <div className="col-xl-3 col-sm-6" key={ind}>
                      <FormInput
                        classes="custom-form-input"
                        onchange={
                          item.type === "select"
                            ? (e) => handleSelectChange(e, item.name)
                            : handleChange
                        }
                        type={item.type ?? "text"}
                        data={item.options}
                        name={item.name}
                        value={formData[item.name]}
                        label={item.label}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-primary btn-sm"
                  disabled={!hasChanges() || showLoading}
                >
                  {t("update_button")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstitutionConfig;
