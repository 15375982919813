import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";

export class AjaxSelect2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedOption: null,
    };
  }

  componentDidMount() {
    axios
      .get(`https://${this.props.url}`)
      .then((response) => {
        const options = response.data.map((item) => {
          return { value: item.id, label: item.name };
        });
        this.setState({ options });
      })
      .catch((error) => {
        const options = [
          {
            value: 1,
            label: "error",
          },
        ];
        this.setState({ options });
      });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  render() {
    const { selectedOption, options } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={options}
        isClearable={true}
        isSearchable={true}
      />
    );
  }
}

export function DataSelect(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event);
  };

  useEffect(() => {
    setData(props);
    setSelectedOption(
      props.options.find((o) => o.value === props.value)
        ? props.options.find((o) => o.value === props.value)
        : selectedOption
          ? selectedOption
          : null,
    );
  }, [props, selectedOption]);

  return (
    <Select
      required={data.required}
      value={selectedOption}
      onChange={(event) => {
        props.onChange(event);
        handleChange(event);
      }}
      name={data.name}
      id={data.id}
      onSelect={(event) => {
        props.onChange(event);
        handleChange(event);
      }}
      isLoading={!data.options}
      options={data.options}
      isClearable={false}
      isSearchable={true}
    />
  );
}
