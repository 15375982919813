import React, { useState, useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLoginConfirmedAction } from "../../actions/authActions";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import { ThemeContext } from "../../../context/ThemeContext";
import { login } from "../../services/authService";
import {
  savePortal,
  saveTokenInSessionStorage,
} from "../../../common/services";
import { toast } from "react-toastify";
import ErrorManager from "../../../appClient/errors";
import { ADMINPORTAL } from "../../../common/store/selectors/authSelectors";
import "./login.css";
import { baseUrlAdmin } from "../../../common/constant";
import { t } from "i18next";
import PasswordInput from "../../../common/jsx/components/PasswordInput";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
  });

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });

  savePortal(ADMINPORTAL);

  function onLogin(e) {
    e.preventDefault();

    if (userName === "") {
      setErrors({
        userName: "userName is Required",
      });
    } else if (password === "") {
      setErrors({
        password: "Password is Required",
      });
    } else {
      setShowLoading(true);
      login(userName, password)
        .then((res) => {
          const resData = res.data.data;
          saveTokenInSessionStorage(resData);
          dispatch(adminLoginConfirmedAction(resData));
          navigate(`${baseUrlAdmin}/home`);
        })
        .catch((err) => {
          setErrors({
            userName: "",
            password: "",
          });
          if (err.response) {
            if (err.response.status === 401) {
              toast.warning(err.response.data.message, {
                position: "top-right",
              });
            }
          } else {
            ErrorManager(err);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }

  return (
    <div className="conteneur-admin">
      <form onSubmit={onLogin} className="form">
        <h2 className="head-title">{t("login")}</h2>
        <div className="input-box">
          <div className="icon">
            <RemixIcon.RiUserLine size={20} />
          </div>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder={t("enter_your_username")}
            autoComplete="off"
            autoFocus
          />
        </div>
        <small className="input-error">{errors.userName}</small>
        <div className="input-box">
          <div className="icon">
            <RemixIcon.RiKeyLine size={20} />
          </div>
          <PasswordInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("enter_your_password")}
            name="password"
            showPasswordToggle={true} // Affiche le toggle pour le mot de passe
            className="form-control-profil"
          />
        </div>
        <small className="input-error">{errors.password}</small>

        <button className="btn-custom" type="submit" disabled={showLoading}>
          {showLoading ? (
            <Spinners.TailSpin
              height="20"
              width="20"
              ariaLabel="tail-spin-loading"
              radius="4"
              color="#fff"
            />
          ) : (
            <RemixIcon.RiLoginBoxLine />
          )}
          {t("log_in")}
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
