import CryptoJS from "crypto-js";
import { kefy } from "../common/constant";

export const encryptData = (data) => {
  // Convert data to JSON string if it's an object
  const dataString = JSON.stringify(data);

  // Encrypt the data string
  const ciphertext = CryptoJS.AES.encrypt(dataString, kefy).toString();

  // Return URL-safe version of the encrypted data
  return encodeURIComponent(ciphertext);
};

export const decryptData = (data) => {
  let decryptedData = "";
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, kefy);
    decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  }
  return JSON.parse(decryptedData);
};
