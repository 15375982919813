/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react"
import Highlight from "react-highlight"
import { Row, Col, Card, Tab, Nav, Spinner } from "react-bootstrap"
import { FormInput } from "../../../common/jsx/components/FormInput"
import Benefit from "../../../common/images/svg/benefit.svg"
import { accountNumberLength } from "../../../common/constant"
import { findAccountByAccountId, saveBeneficiary, saveMobilMoneyeBeneficiary } from "../../services/acountServices"
import { ThemeContext } from "../../../context/ThemeContext"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { getNameByNumber } from "../../services/mobileBankService"
import { substringText } from "../../../utils/utilsSubstring"
import ErrorManager from "../../errors"

const initialAccount = {
  accountId: "",
  customerName: "",
  mobile: false,
}

const AddBeneficiary = () => {
  const { t } = useTranslation()
  const [accountInfo, setAccountInfo] = useState(initialAccount)
  const { setShowLoading, showLoading, setVerifySecureQuestions, setVerifyCallback } = useContext(ThemeContext)
  const [canSubmit, setCanSubmit] = useState(false)
  const [nameNumberLoader, setNameNumberLoader] = useState(false)
  const [operator, setOperator] = useState("")
  const [nameNumber, setNameNumber] = useState("")
  const [mobileMoneyNumber, setMobileMoneyNumber] = useState("")
  const [errorMsg, setErrorMsg] = useState({})
  const navigate = useNavigate()

  const beneficiarySave = (securityQuestions) => {
    setShowLoading(true)
    return saveBeneficiary(accountInfo.accountId, accountInfo.mobile, securityQuestions).then((v) => {
      navigate("/account/beneficiary")
      toast.success(t("save_message"), { position: "top-right" })
      setShowLoading(false)
    })
  }

  const mobileMoneySave = (securityQuestions) => {
    const initialMobileMoney = {
      benefactorAccountNumber: "mobile",
      telephone: mobileMoneyNumber,
      nom: nameNumber,
      mobile: true,
    }
    setShowLoading(true)
    return saveMobilMoneyeBeneficiary(initialMobileMoney, securityQuestions).then((v) => {
      navigate("/account/beneficiary")
      toast.success(t("save_message"), { position: "top-right" })
      setShowLoading(false)
    })
  }

  const handleAccountIdChange = (e) => {
    const value = e.target.value
    if (value.length === accountNumberLength) {
      findAccountByAccountId(value)
        .then((v) => {
          const data = v.data.data
          setAccountInfo((info) => {
            return {
              ...info,
              accountId: value,
              customerName: data.clientName,
            }
          })
          setCanSubmit(true)
        })
        .catch((err) => {
          setAccountInfo((info) => {
            return {
              ...info,
              accountId: value,
              customerName: "",
            }
          })
          setErrorMsg({
            accountId: err.response.data.message,
          })
          setCanSubmit(false)
        })
    } else {
      setAccountInfo((info) => {
        return {
          ...info,
          accountId: value,
          customerName: "",
        }
      })
      setCanSubmit(false)
    }
  }

  const submitBeneficiaryForm = (e) => {
    e.preventDefault()
    setShowLoading(true)
    setVerifyCallback(beneficiarySave)
    setVerifySecureQuestions(true)
  }

  const handleSubmitMobileMoney = (e) => {
    e.preventDefault()
    setShowLoading(true)
    setVerifyCallback(mobileMoneySave)
    setVerifySecureQuestions(true)
  }

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false)
    }, 500)
  }, [])

  const verifyOperator = (phoneNumber) => {
    const mtnPrefixes = ["650", "651", "652", "653", "654", "67", "680", "681", "682", "683"]
    const orangePrefixes = ["655", "656", "657", "658", "6590", "6591", "6592", "6593", "6594", "659", "69"]

    const digitsOnly = phoneNumber.replace(/\D/g, "")
    let cleanedNumber = digitsOnly
    if (digitsOnly.startsWith("237")) {
      cleanedNumber = digitsOnly.slice(3)
    }

    if (cleanedNumber.length === 9) {
      if (mtnPrefixes.some((prefix) => cleanedNumber.startsWith(prefix))) {
        setErrorMsg({ phoneNumber: "" })
        return "MTNRET"
      } else if (orangePrefixes.some((prefix) => cleanedNumber.startsWith(prefix))) {
        setErrorMsg({ phoneNumber: "" })
        return "OMRETR"
      } else {
        setErrorMsg({ phoneNumber: t("uncateg_optor") })
        setNameNumber("")
      }
    } else if (cleanedNumber.length > 9) {
      setErrorMsg({ phoneNumber: t("number_incorrect") })
      setNameNumber("")
    } else {
      setErrorMsg({ phoneNumber: "" })
      setNameNumber("")
    }
  }

  useEffect(() => {
    const operator = verifyOperator(mobileMoneyNumber)

    setOperator(operator)
    if ((operator === "OMRETR" || operator === "MTNRET") && mobileMoneyNumber) {
      setNameNumberLoader(true)
      getNameByNumber(mobileMoneyNumber)
        .then((res) => {
          const data = res.data?.data?.data
          if (data.status !== "FAILED") {
            setNameNumber(data.verifiedName)
          } else {
            setErrorMsg({
              ...errorMsg,
              phoneNumber: t("number_not_found"),
            })
          }
        })
        .catch((err) => {          
          if (err.response.status === 422) {
            setErrorMsg({
              phoneNumber: err.response.data.message,
            })
          } else {
            ErrorManager(err, navigate)
          }
        })
        .finally(() => setNameNumberLoader(false))
    }
  }, [mobileMoneyNumber])

  const tabData = [
    {
      name: "Bank Account",
      content: (
        <div className="row">
          <div className="col-xl-3 p-3 ms-3 mb-3 border-custom">
            <div>
              <h4 className="fw-bold mb-4"> {t("add_beneficiary_title")}</h4>
            </div>
            <form onSubmit={submitBeneficiaryForm} className="">
              <div className="row">
                <div className="col-xl-12">
                  <FormInput
                    value={accountInfo.accountId}
                    onchange={handleAccountIdChange}
                    name="accountId"
                    label={t("beficial_acc_no")}
                    type="number"
                    errorMsg={errorMsg.accountId}
                    maxLength={accountNumberLength}
                  />
                </div>
                <div className="col-xl-12">
                  <FormInput
                    onchange={(e) =>
                      setAccountInfo((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }
                      })
                    }
                    value={accountInfo.customerName}
                    label={t("beficial_cust_name")}
                    disable={true}
                    name="beneficiary_name"
                    type="text"
                  />
                </div>
              </div>
              <div className="pb-3">
                <button className="btn btn-primary btn-sm me-3" disabled={!canSubmit || showLoading} type="submit">
                  {t("submit_button")}
                </button>
                <button className="btn btn-outline-primary btn-sm" onClick={() => navigate(-1)} type="button">
                  {t("cancel_button")}
                </button>
              </div>
            </form>
          </div>

          <div className="col-xl-2  mb-5"></div>

          <div className="col-xl-4  col-lg-6">
            <div className="card  pb-0 border-0">
              <div className="">
                <div className="card-body">
                  <div className="media me-2 pb-5 d-flex justify-content-center">
                    <img width="100" height="100" src={Benefit} alt="benefit" />
                  </div>
                  <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
                    <ul className="timeline">
                      <li>
                        <h3 className="mb-3 fw-bold text-center"> {t("add_beneficial_info_title")}</h3>
                        <p className="pl-5 fs-5 text-center">{t("add_beneficial_info")}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Mobile Money",
      content: (
        <div className="row">
          <div className="col-xl-3 p-3 ms-3 mb-3 col-lg-6 border-custom pt-3">
            <div>
              <h4 className="fw-bold mb-4">{t("add_beneficiary_title")}</h4>
            </div>
            <form onSubmit={handleSubmitMobileMoney}>
              <div className="row">
                <div className="col-xl-12 position-relative">
                  <FormInput
                    value={mobileMoneyNumber}
                    onchange={(e) => setMobileMoneyNumber(e.target.value)}
                    name="telephone"
                    label={t("beficial_cust_no")}
                    type="number"
                    errorMsg={errorMsg.phoneNumber}
                  />
                  {nameNumberLoader && (
                    <Spinner
                      className="position-absolute text-blue"
                      style={{
                        top: "5px",
                        right: "10px",
                        fontSize: "8px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                </div>
                <div className="col-xl-12">
                  <FormInput value={nameNumber} label={t("beficial_cust_name")} disable={true} name="nom" type="text" />
                </div>
              </div>
              <div className="pb-3">
                <button className="btn btn-primary btn-sm me-3" type="submit" disabled={!nameNumber || !mobileMoneyNumber || showLoading}>
                  {t("submit_button")}
                </button>
                <button className="btn btn-outline-primary btn-sm" onClick={() => navigate(-1)} type="button">
                  {t("cancel_button")}
                </button>
              </div>
            </form>
          </div>

          <div className="col-xl-2  mb-5"></div>

          <div className="col-xl-4  col-lg-6">
            <div className="card pb-0 border-0">
              <div className="">
                <div className="card-body border-0">
                  <div className="media me-2 pb-5 d-flex justify-content-center">
                    <img width="100" height="100" src={Benefit} alt="benefit" />
                  </div>
                  <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
                    <ul className="timeline">
                      <li>
                        <h3 className="mb-3 fw-bold text-center">{t("add_beneficiary_title_momo")}</h3>

                        <p className="pl-5 fs-5 text-center">{t("add_beneficiary_info_momo")}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]
  return (
    <Fragment>
      <div className="container-fluid">
        <Row>
          <Col xl={12}>
            <Tab.Container defaultActiveKey="Preview">
              <Card name="custom-tab" className="dz-card border-custom rounded-0 shadow">
                <Tab.Content>
                  <Tab.Pane eventKey="Preview">
                    <Card.Body>
                      {/* <!-- Nav tabs --> */}
                      <div className="custom-tab-1">
                        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                          <Nav as="ul" className="nav-tabs">
                            {tabData.map((data, i) => (
                              <Nav.Item as="li" key={i}>
                                <Nav.Link eventKey={data.name.toLowerCase()}>
                                  <i className={`la la-${data.icon} me-2`} />
                                  {data.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                          <Tab.Content className="pt-4">
                            {tabData.map((data, i) => (
                              <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                                {data.content}
                              </Tab.Pane>
                            ))}
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </Card.Body>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Code">
                    <div className="card-body shadow pt-0 p-0 code-area">
                      <pre className="mb-0">
                        <code className="language-html">
                          <Highlight>
                            {`
                          <div className="custom-tab-1">
                            <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                              <Nav as="ul" className="nav-tabs">
                                {tabData.map((data, i) => (
                                  <Nav.Item as="li" key={i}>
                                    <Nav.Link eventKey={data.name.toLowerCase()}>
                                      <i className={\`la la-$\{data.icon} me-2\`} />
                                      {data.name}
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                            </Tab.Container>
                          </div>
                          `}
                          </Highlight>
                        </code>
                      </pre>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card>
            </Tab.Container>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default AddBeneficiary
