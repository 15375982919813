import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import * as RemixIcon from "react-icons/ri";
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../../context/ThemeContext";
import { store } from "../../../store";
import {
  ADMINPORTAL,
  CUSTOMERPORTAL,
} from "../../../store/selectors/authSelectors";
import { getAuthorizedMenus } from "../../../../appAdmin/services/authService";
import { SVGICON } from "../../../constant/theme";
import { baseUrlAdmin } from "../../../constant";
import { useTranslation } from "react-i18next";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const storeState = store.getState();
  const moduleId = sessionStorage.getItem("moduleId");
  const accessPortal = storeState.auth.accessPortal;
  const [state, setState] = useReducer(reducer, initialState);
  const [displayMenu, setDisplayMenu] = useState([]);
  const [hideOnScroll, setHideOnScroll] = useState(true);

  const { t } = useTranslation();

  const toggleSidebar = () => {
    if (window.innerWidth <= 1023) {
      let mainwrapper = document.querySelector("#main-wrapper");
      mainwrapper.classList.remove("menu-toggle");
    }
  };

  const handleMenuActive = (status, e) => {
    e.preventDefault();
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = (status, e) => {
    e.preventDefault();
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  // Path
  let path = window.location.pathname;
  path = path.split("/").pop();

  useEffect(() => {
    displayMenu.forEach((data) => {
      data.content?.forEach((item) => {
        if (path === item.to) {
          setState({ active: data.title });
        }
        item.content?.forEach((ele) => {
          if (path === ele.to) {
            setState({ activeSubmenu: item.title, active: data.title });
          }
        });
      });
    });
  }, [path, displayMenu]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (accessPortal === CUSTOMERPORTAL) {
      setDisplayMenu(MenuList);
    } else if (accessPortal === ADMINPORTAL) {
      getAuthorizedMenus(moduleId)
        .then((v) => {
          const menus = v.data.data
            .filter((m) => m.type === "MENU" && m.parent === null)
            .sort((a, b) => a.menuLevel - b.menuLevel)
            .map((elmt) => ({
              title: elmt.name,
              to: baseUrlAdmin + elmt.path,
              classsChange:
                elmt.menuStyle === "DOUBLE"
                  ? "mm-collapse"
                  : elmt.menuStyle === "TAG"
                    ? "menu-title"
                    : undefined,
              iconStyle: SVGICON[elmt.icon],
              content:
                elmt.menuStyle === "DOUBLE"
                  ? v.data.data
                      .filter((c) => (c.parent ?? {}).id === elmt.id)
                      .sort((a, b) => b.menuLevel - a.menuLevel)
                      .map((ch) => ({
                        title: ch.name,
                        to: ch.path,
                        hasMenu:
                          v.data.data.filter(
                            (cch) => (cch.parent ?? {}).id === ch.id,
                          ).length > 0
                            ? true
                            : undefined,
                        content:
                          v.data.data.filter(
                            (cch) => (cch.parent ?? {}).id === ch.id,
                          ).length > 0
                            ? v.data.data
                                .filter(
                                  (cch) => (cch.parent ?? {}).id === ch.id,
                                )
                                .sort((a, b) => b.menuLevel - a.menuLevel)
                                .map((lc) => ({
                                  title: lc.name,
                                  to: lc.path,
                                }))
                            : undefined,
                      }))
                  : undefined,
            }));
          setDisplayMenu(menus);
        })
        .catch((err) => {
          setDisplayMenu([]);
        });
    }

    displayMenu.forEach((data) => {
      data.content?.forEach((item) => {
        if (path === item.to) {
          setState({ active: data.title });
        }
        item.content?.forEach((ele) => {
          if (path === ele.to) {
            setState({ activeSubmenu: item.title, active: data.title });
          }
        });
      });
    });
  }, [path, moduleId, accessPortal]);
  /* eslint-disable react-hooks/exhaustive-deps */

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.querySelector(".deznav");
      const header = document.querySelector(".nav-control");
      if (
        window.innerWidth <= 1023 &&
        sidebar &&
        !sidebar.contains(event.target) &&
        !header.contains(event.target)
      ) {
        toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`shadow deznav border-right ${iconHover} ${sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static" ? (hideOnScroll > 120 ? "fixed" : "") : ""}`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {displayMenu.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {t(data.title)}
                </li>
              );
            } else {
              return (
                <li
                  className={` ${state.active === data.title ? "mm-active" : ""}`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={(e) => {
                          handleMenuActive(data.title, e);
                        }}
                      >
                        <span className="nav-text">{t(data.title)} </span>
                        {state.active === data.title ? (
                          <RemixIcon.RiArrowDropDownLine />
                        ) : (
                          <RemixIcon.RiArrowDropRightLine />
                        )}
                      </Link>
                      <Collapse in={state.active === data.title}>
                        <ul
                          className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}
                        >
                          {data.content.map((data, index) => (
                            <li
                              key={index}
                              className={` ${state.activeSubmenu === t(data.title) ? "mm-active" : ""}`}
                            >
                              {data.content && data.content.length > 0 ? (
                                <>
                                  <Link
                                    to={"#"}
                                    className="has-arrow"
                                    onClick={(e) =>
                                      handleSubmenuActive(data.title, e)
                                    }
                                  >
                                    <span className="nav-text">
                                      {t(data.title)}{" "}
                                    </span>
                                    {state.activeSubmenu === data.title ? (
                                      <RemixIcon.RiArrowDropDownLine />
                                    ) : (
                                      <RemixIcon.RiArrowDropRightLine />
                                    )}
                                  </Link>
                                  <Collapse
                                    in={state.activeSubmenu === data.title}
                                  >
                                    <ul style={{ marginLeft: "20px" }}>
                                      {data.content.map((data, index) => (
                                        <li
                                          key={index}
                                          className={` ${state.activeSubmenu === data.title ? "mm-active" : ""}`}
                                        >
                                          <Link
                                            to={`${data.to}`}
                                            onClick={() => toggleSidebar()}
                                          >
                                            {t(data.title)}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </Collapse>
                                </>
                              ) : (
                                <Link
                                  to={`${data.to}`}
                                  onClick={() => toggleSidebar()}
                                >
                                  {t(data.title)}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link to={`${data.to}`} onClick={() => toggleSidebar()}>
                      <span className="nav-text">{t(data.title)}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
