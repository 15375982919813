import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoutPage from "./Logout";
import { SVGICON } from "../../../constant/theme";
import { store } from "../../../store";
import * as RemixIcon from "react-icons/ri";
import {
  ADMINPORTAL,
  CUSTOMERPORTAL,
} from "../../../store/selectors/authSelectors";
import { baseUrlAdmin } from "../../../constant";
import { useTranslation } from "react-i18next";
import FlagEN from "../../../images/flag/en.png";
import FlagFR from "../../../images/flag/fr.png";
import { ThemeContext } from "../../../../context/ThemeContext";
import AvatarIcon from "../../../images/me/user-avatar.png";

const Header = () => {
  const { t } = useTranslation();
  const { setShowLoading, lang, setLang } = useContext(ThemeContext);
  const storeState = store.getState();
  const user = storeState.auth.userDao;
  const accessPortal = storeState.auth.accessPortal;

  const [headerFix, setHeaderFix] = useState(false);
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);

  const changeLanguage = (lang) => {
    setLang(lang);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setShowLoading(false);
    window.addEventListener("scroll", () => {
      setHeaderFix(window.scrollY > 50);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setHeaderFix(window.scrollY > 50);
      });
    };
  }, []);
  /* eslint-disable react-hooks/exhaustive-deps */

  const getPortal = () => {
    if (accessPortal === CUSTOMERPORTAL) {
      return "";
    } else if (accessPortal === ADMINPORTAL) {
      return baseUrlAdmin;
    }
  };

  const handleLangToggle = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };

  const handleProfileToggle = (isOpen) => {
    setProfileDropdownOpen(isOpen);
  };

  const handleProfileItemClick = () => {
    setProfileDropdownOpen(false);
  };

  return (
    <div className={`header shadow ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left"></div>
            <ul className="navbar-nav header-right">
              <li className="nav-item align-items-end header-border m-0 p-0 text-white">
                <Dropdown
                  className="header-lang"
                  show={langDropdownOpen}
                  onClick={handleLangToggle}
                >
                  <Dropdown.Toggle className="nav-link i-false" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      <div className="header-media text-white">
                        {lang === "en" ? (
                          <img src={FlagEN} alt="FlagEN" />
                        ) : (
                          <img src={FlagFR} alt="FlagFR" />
                        )}
                        <RemixIcon.RiArrowDropDownLine size={15} />
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div className="card border-0 mb-0">
                      <div
                        className="item-lang card-footer py-2 text-nowrap d-flex gap-2"
                        onClick={() => changeLanguage("fr")}
                      >
                        <img src={FlagFR} alt="FlagFR" />
                        {t("french_lang")}
                      </div>
                      <div
                        className="item-lang card-footer py-2 text-nowrap d-flex gap-2"
                        onClick={() => changeLanguage("en")}
                      >
                        <img src={FlagEN} alt="FlagEN" />
                        {t("english_lang")}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="nav-item align-items-center justify-content-center header-border m-0 ms-4">
                <Link className="text-white" to={`${getPortal()}/home`}>
                  <RemixIcon.RiHome2Line size={20} />{" "}
                </Link>
              </li>
              <li className="nav-item ps-3">
                <Dropdown
                  className="header-profile2"
                  show={profileDropdownOpen}
                  onToggle={handleProfileToggle}
                >
                  <Dropdown.Toggle className="nav-link i-false" as="div">
                    <div className="header-info2 d-flex align-items-center">
                      <div className="header-media text-white">
                        <RemixIcon.RiUserLine size={20} />
                      </div>
                      <div className="header-info">
                        <h6>{user.name}</h6>
                        <p>{user.email}</p>
                      </div>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <div className="card border-0 mb-0">
                      <div className="card-header py-2">
                        <div className="products">
                          <img
                            src={AvatarIcon}
                            className="avatar avatar-md"
                            alt=""
                          />
                          <div>
                            <h6>{user.name}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="card-body px-0 py-2">
                        <Link
                          to={`${getPortal()}/view-profile`}
                          className="dropdown-item ai-icon"
                          onClick={handleProfileItemClick}
                        >
                          {SVGICON.User}{" "}
                          <span className="ms-2">{t("user_profile_btn")} </span>
                        </Link>
                      </div>
                      <div className="card-footer px-0 py-2">
                        <LogoutPage onClick={handleProfileItemClick} />
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;

export const AuthHeader = ({ onNote }) => {
  const [headerFix, setHeaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setHeaderFix(window.scrollY > 50);
    });
  }, []);

  return (
    <div
      style={{ width: "100%" }}
      className={`header ${headerFix ? "is-fixed" : ""}`}
    >
      <div className="header-content">
        <nav className="navbar navbar-expand"></nav>
      </div>
    </div>
  );
};
