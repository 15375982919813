import { ADMINPORTAL, CUSTOMERPORTAL } from "../selectors/authSelectors";
import {
  ADMIN_LOGIN_CONFIRMED_ACTION,
  CLIENT_LOGIN_CONFIRMED_ACTION,
  FORGOT_PASSWORD_REQUEST,
  LOADING_TOGGLE_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  MODULE_SELECTED,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
} from "../actionTypes";

const initialState = {
  auth: {
    token: "",
    expireAt: "",
  },
  userDao: {
    id: 0,
    uuid: "",
    firstName: "",
    lastName: "",
    clientName: "",
    email: "",
    doubleAuthentication: false,
    securityQuestionCounts: 0,
    language: "",
    passExpiration: false,
    passDuration: null,
    passPeriodicity: null,
    passwordChangedTime: null,
    phoneNumber: "",
    address: null,
    enabled: true,
    passwordExpired: false,
    username: "",
    accountNonLocked: true,
    credentialsNonExpired: true,
    passExpirationDate: null,
    accountNonExpired: true,
    userType: null,
  },
  moduleId: null,
  accessPortal: null,
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload.authInfo,
      userDao: action.payload.userDao,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === ADMIN_LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      accessPortal: ADMINPORTAL,
      auth: action.payload.authInfo,
      moduleId: action.payload.moduleId,
      userDao: action.payload.userDao,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === CLIENT_LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      accessPortal: CUSTOMERPORTAL,
      auth: action.payload.authInfo,
      moduleId: action.payload.moduleId,
      userDao: action.payload.userDao,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === FORGOT_PASSWORD_REQUEST) {
    return {
      ...state,
      auth: action.payload.authInfo,
      userDao: action.payload.userDao,
      errorMessage: "",
      successMessage: action.payload.message,
      showLoading: false,
    };
  }

  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      auth: initialState.auth,
      userDao: initialState.userDao,
    };
  }

  if (
    action.type === SIGNUP_FAILED_ACTION ||
    action.type === LOGIN_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }

  if (action.type === MODULE_SELECTED) {
    return {
      ...state,
      moduleId: action.payload,
    };
  }

  return state;
}
