/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { getAuthorizedModules } from "../../services/authService";
import { SVGICON } from "../../../common/constant/theme";
import Header from "../../../common/jsx/layouts/nav/Header";
import TopLoader from "../../../common/jsx/components/TopLoader";
import { Footer } from "rsuite";
import { ThemeContext } from "../../../context/ThemeContext";
import "./home.css";
import ErrorManager from "../../../appClient/errors";
import { moduleSelected } from "../../actions/authActions";
import { t } from "i18next";

const AuthModules = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modules, setModules] = useState([]);
  const { setShowLoading } = useContext(ThemeContext);

  const getPageData = () => {
    setShowLoading(true);
    return getAuthorizedModules()
      .then((v) => {
        const data = v.data.data;
        setModules(data);
        return v;
      })
      .catch((err) => {
        ErrorManager(err, navigate);
        setModules([]);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };
  useEffect(() => {
    getPageData().finally((e) => {
      setShowLoading(false);
    });
  }, []);

  return (
    <div className="auth-module">
      <Header />
      <TopLoader />
      <div className="content">
        <h2 className="text-center">{t("listofauthorizedmodules")}</h2>
        <div className="box-content">
          {modules.map((m) => {
            return (
              <div key={m.id} className="box">
                <div className="d-flex justify-content-between align-items-center">
                  <span className="me-3 text-success desc">
                    {SVGICON[m.icon]}
                    <h4 className="mb-1">{m.name}</h4>
                  </span>
                  <div className="">
                    <button
                      onClick={() =>
                        dispatch(moduleSelected(m.id, m.path, navigate))
                      }
                      key={m.id}
                      className="badge badge-success"
                    >
                      {t("open_button")}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AuthModules;
