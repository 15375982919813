import { Route, Routes, useNavigate } from "react-router-dom";
import AuthRouter from "./routers/AuthRouter";
import AdminRouter from "./routers/AdminRouter";
import CustomerRouter from "./routers/CustomerRouter";
import AuthAdminRouter from "./routers/AuthAdminRouter";
import AuthGuard from "./utils/utilsAuthGuard";
import { Suspense, useEffect } from "react";
import { baseUrlAdmin, baseUrlClient } from "./common/constant";
import { store } from "./common/store";
import { createClientInterceptor } from "./common/axiosInstance";
import InactivityHandler from "./utils/utilsInactivityHandler";
import "./App.css";

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    createClientInterceptor(store.dispatch, navigate);
  }, [navigate]);

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <InactivityHandler />
      <Routes>
        <Route path="/auth/*" element={<AuthRouter />} />
        <Route path="/auth-a/*" element={<AuthAdminRouter />} />
        <Route
          path="/adminportal/*"
          element={
            <AuthGuard baseUrl={baseUrlAdmin}>
              <AdminRouter />
            </AuthGuard>
          }
        />
        <Route
          path="/*"
          element={
            <AuthGuard baseUrl={baseUrlClient}>
              <CustomerRouter />
            </AuthGuard>
          }
        />
        <Route path="*" element={<navigate to="/auth/login" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
