import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { store } from "../../common/store";
import { internetBankServerAddress } from "../../common/constant";
import { ThemeContext } from "../../context/ThemeContext";
import * as Spinners from "react-loader-spinner";
import ImgMTN from "../../common/images/operator/momo.png";
import ImgORANGE from "../../common/images/operator/om.png";
import ModalAfterOperation from "./ModalAfterOperation";
import { t } from "i18next";

const LoadingTranModal = () => {
  const { walletOpLoading, walletOp, setWalletOpLoading, setWalletOp } =
    useContext(ThemeContext);
  const state = store.getState();
  const auth = state.auth;
  const [modalMessage, setModalMessage] = useState("");
  const [status, setStatus] = useState("");
  const [walletWithdrawal, setWalletWithdrawal] = useState(null);

  useEffect(() => {
    if (walletOp) {
      if (walletOp.walletToBank === false) {
        const withdrawalData = JSON.parse(sessionStorage.getItem("tr"));
        setWalletWithdrawal(withdrawalData);
      } else {
        setWalletWithdrawal(null);
      }

      const baseUrl = `${internetBankServerAddress}/api/v1/client/transactions`;
      const eventSource = new EventSource(
        `${baseUrl}/transactionStatus/${walletOp.paymentGatewaysUuid}?token=${auth.auth.token}`,
      );

      eventSource.addEventListener("status", (event) => {
        const data = JSON.parse(event.data);
        if (data.status !== "PENDING") {
          setStatus(data.status);
          setModalMessage(data.message);
          sessionStorage.removeItem("tr");
          setWalletOpLoading(false);
          setWalletOp(undefined);
          eventSource.close();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletOp]);

  const getOperatorImage = () => {
    if (walletOp?.walletToBank) {
      if (walletOp.typeOp === "OMRETR") {
        return ImgORANGE;
      } else {
        return ImgMTN;
      }
    } else if (walletWithdrawal) {
      if (walletWithdrawal.typeOp === "OMDEPO") {
        return ImgORANGE;
      } else {
        return ImgMTN;
      }
    }
    return null;
  };

  const getOperatorName = () => {
    if (walletOp?.walletToBank || walletWithdrawal) {
      return walletOp?.typeOp === "OMRETR" ||
        walletWithdrawal?.typeOp === "OMDEPO"
        ? "Orange Cameroon"
        : "MTN Cameroon Ltd";
    }
    return "";
  };

  const getCode = () => {
    if (walletOp?.walletToBank) {
      return walletOp.typeOp === "OMRETR" ? "#150*50#" : "*126#";
    }
    return "";
  };

  const getSecretCodeInfo = () => {
    if (walletOp?.walletToBank) {
      return walletOp.typeOp === "OMRETR"
        ? t("enter_your_orange_mobile_money_secret_code")
        : t("enter_your_mtn_mobile_money_secret_code");
    }
    return "";
  };

  return (
    <>
      <Modal
        className="fade rounded-0 border-0"
        size="md"
        show={walletOpLoading}
        centered
      >
        <Modal.Body className="row rounded-0 border-0">
          <div className="col-xl-12 d-flex justify-content-between mb-3 ">
            <h1>
              {walletOp?.walletToBank
                ? t("wallet_to_bank")
                : t("bank_to_wallet")}
            </h1>
            <span className="d-flex">
              <p className="cfa-sign me-3 mt-1 fs-3">XAF</p>
              <h1>
                {walletOp?.walletToBank
                  ? walletOp.amount
                  : walletWithdrawal?.amount}
              </h1>
            </span>
          </div>
          <div className="align-items-center gap-2 mb-3">
            <img src={getOperatorImage()} alt="operator" width="30%" />
            <div className="d-flex">
              <p>
                {walletOp?.walletToBank
                  ? walletOp.phoneNumber
                  : walletWithdrawal?.phoneNumber}
              </p>
              <span>|</span>
              <p>{getOperatorName()}</p>
            </div>
          </div>
          {walletOp?.walletToBank && (
            <>
              <h4>{t("to_validate_your_payment")}</h4>
              <div className="mb-2">
                <h5>
                  1. {t("dial")} {getCode()}
                </h5>
                <h5>2. {getSecretCodeInfo()}</h5>
              </div>
            </>
          )}
          <div>
            <p>
              {t("waiting_for_your_payment")}{" "}
              <Spinners.TailSpin
                height="40"
                width="40"
                ariaLabel="tail-spin-loading"
                radius="1"
                color="#000"
              />
            </p>
          </div>
        </Modal.Body>
      </Modal>
      <ModalAfterOperation
        modalMessage={modalMessage}
        status={status}
        setModalMessage={setModalMessage}
      />
    </>
  );
};

export default LoadingTranModal;
