import { t } from "i18next";
import SVGICON from "../../../../data/svg";
import * as RemixIcon from "react-icons/ri";

export const MenuList = [
  {
    iconStyle: SVGICON.Home,
    title: t("dashboard"),
    to: "/home",
  },
  {
    title: t("db_account"),
    classsChange: "mm-collapse",
    icon: RemixIcon.RiAccountBoxLine,
    content: [
      {
        title: t("db_account_details"),
        to: "/account?view=account-details",
      },
      {
        title: t("db_account_statement"),
        to: "/account?view=account-statement",
      },
      {
        title: t("db_manage_beneficiaries"),
        to: "/account/beneficiary",
      },
      {
        title: t("db_stop_unblock_check"),
        classsChange: "mm-collapse",
        iconStyle: SVGICON.Wallet,
        content: [
          {
            title: t("db_checkbook_request"),
            to: "/account/check-book-request",
          },
          {
            title: t("bd_checkbook_request_status"),
            to: "/account/check-book-request-status",
          },
          {
            title: t("db_stop_check"),
            to: "/account/stop-check",
          },
          {
            title: t("bd_unblock_check"),
            to: "/account/unblock-check",
          },
        ],
      },
    ],
  },
  {
    title: t("bd_transactions"),
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Wallet,
    content: [
      // {
      //     title: t('db_diaspora_deposit'),
      //     to: '/diaspora-deposit',
      // },
      {
        title: t("db_mobile_money"),
        hasMenu: true,
        content: [
          {
            title: t("db_transfers"),
            to: "/transfers",
          },
          {
            title: t("wallet_to_bank"),
            to: "/mobile-money?view=wallet-to-bank",
          },
          {
            title: t("db_bank_to_wallet"),
            to: "/mobile-money?view=bank-to-wallet",
          },
        ],
      },
      {
        title: t("db_bills_and_services"),
        hasMenu: true,
        content: [
          {
            title: t("db_bills_payment"),
            to: "/client-services?view=bills_payment",
          },
          // {
          //     title: t('db_airtime'),
          //     to: '/client-services?view=airtime_purchase',
          // },
        ],
      },
    ],
  },
  {
    title: t("db_other_services"),
    classsChange: "mm-collapse",
    iconStyle: SVGICON.Wallet,
    content: [
      {
        title: t("db_security_settings"),
        hasMenu: true,
        content: [
          {
            title: t("db_change_password"),
            to: "/change-password",
          },
          {
            title: t("db_security_questions"),
            to: "/security-questions",
          },
        ],
      },
      {
        title: t("db_notification"),
        to: "/notification",
      },
    ],
  },
];
