import { OTP_VERIFICATION } from "../actionTypes";

const initialState = {
  otpInfo: {
    uuid: "",
    guid: "",
    minBeforeExpire: 0,
    destination: "0",
    role: "",
    expiresAt: "",
  },
  userDao: {
    id: 0,
    uuid: "",
    subscriptionDate: "",
    email: "",
    userLogin: "",
    clientName: "",
    primaryAccount: "",
    productName: "",
    clientMatricul: "",
    preferedOtpChanel: "",
    passDuration: null,
    passPeriodicity: null,
    passwordChangedTime: null,
    phoneNumber: "",
    address: null,
  },
};

export function OtpReducer(state = initialState, action) {
  if (action.type === OTP_VERIFICATION) {
    return {
      ...state,
      otpInfo: action.payload.authInfo,
      userDao: action.payload.userDao,
      portal: action.payload.portal,
    };
  }
  return state;
}
