import React, { useReducer } from "react";
import { Row, Card, Col, Button, Modal } from "react-bootstrap";
import PageTitle from "../../../common/jsx/layouts/PageTitle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const init = false;

const reducer = (state, active) => {
  switch (active.type) {
    case "basicModal":
      return { ...state, basicModal: !state.basicModal };
    case "contentModal":
      return { ...state, contentModal: !state.contentModal };
    case "modalCentered":
      return { ...state, modalCentered: !state.modalCentered };
    case "modalWithTooltip":
      return { ...state, modalWithTooltip: !state.modalWithTooltip };
    case "gridInsideModal":
      return { ...state, gridInsideModal: !state.gridInsideModal };
    case "largeModal":
      return { ...state, largeModal: !state.largeModal };
    case "smallModal":
      return { ...state, smallModal: !state.smallModal };
    default:
      return state;
  }
};

const AllModals = () => {
  const { t } = useTranslation();

  const [state, dispatch] = useReducer(reducer, init);
  return (
    <div className="h-80">
      <PageTitle
        pageContent="Modal"
        motherMenu={"Bootstrap"}
        activeMenu={"Modal"}
      />
      <div className="container-fluid">
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>Modals</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="bootstrap-modal">
                  <Button
                    variant="primary"
                    type="button"
                    className="mb-2 me-1"
                    onClick={() => dispatch({ type: "modalCentered" })}
                  >
                    {t("modal_otp")}
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    className="mb-2 me-1"
                    onClick={() => dispatch({ type: "contentModal" })}
                  >
                    {t("modal_4_ques")}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        className="fade"
        size="sm"
        show={state.modalCentered}
        onHide={() => dispatch({ type: "modalCentered" })}
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="text-white">
            {t("modal_1time_verify")}
          </Modal.Title>
          <Button
            onClick={() => dispatch({ type: "modalCentered" })}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <p className="otp-text">{t("modal_1time_verify_info")}</p>
          <form>
            <div className="form-group ">
              <label className="form-label">
                <h6>{t("modal_verify_code")}</h6>
              </label>
              <input
                name="dzName"
                required=""
                className="text-center form-control"
                type="number"
              />
            </div>
            <div className="d-flex justify-content-end mt-2">
              <Link className="text-primary fs-5 fw-bold">
                {t("modal_resend_code")}
              </Link>
            </div>
          </form>
          <div className="d-flex">
            <p className="me-3">{t("modal_verify_trials")}</p>
            <h4 className="fw-bold">3</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary btn-sm fw-bold"
            onClick={() => dispatch({ type: "modalCentered" })}
          >
            Close
          </button>
          <Button
            onClick={() => dispatch({ type: "modalCentered" })}
            className="btn-outline-primary btn-sm fw-bold"
          >
            {t("modal_save_button")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="fade"
        size="lg"
        show={state.contentModal}
        onHide={() => dispatch({ type: "modalWithTooltip" })}
      >
        <Modal.Header className="bg-primary">
          <Modal.Title className="text-white">
            {t("modal_set_quest")}
          </Modal.Title>
          <Button
            onClick={() => dispatch({ type: "contentModal" })}
            variant=""
            className="btn-close"
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center mb-5 fs-5">{t("modal_set_quest_info")}</h5>
          <div>
            <form className="dz-form">
              <div className="form-group mt-2">
                <label className="form-label w-100">
                  {t("modal_sec_quest_one")}
                </label>
                <input
                  name="dzName"
                  required=""
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="form-group mt-2">
                <label className="form-label fs-5">
                  {t("modal_sec_quest_two")}
                </label>
                <input
                  name="dzName"
                  required=""
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="form-group mt-2">
                <label className="form-label w-100">
                  {t("modal_sec_quest_three")}
                </label>
                <input
                  name="dzName"
                  required=""
                  className="form-control"
                  type="text"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-primary btn-sm fw-bold"
            onClick={() => dispatch({ type: "contentModal" })}
          >
            Close
          </button>
          <Button
            onClick={() => dispatch({ type: "contentModal" })}
            className="btn-outline-primary btn-sm fw-bold"
          >
            {t("modal_save_button")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllModals;
