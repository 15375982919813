export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const ADMIN_LOGIN_CONFIRMED_ACTION =
  "[admin login action] confirmed login";
export const CLIENT_LOGIN_CONFIRMED_ACTION =
  "[client login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const FORGOT_PASSWORD_REQUEST = "[Forgot password] request";
export const AUTH_FORM_SUBMIT_FAILED = "[Faild auth] form";
export const MODULE_SELECTED = "MODULE_SELECTED";

export const OTP_VERIFICATION = "OTP_VERIFICATION";
