import { LOGOUT_ACTION } from "./store/actionTypes";
import { ADMINPORTAL, CUSTOMERPORTAL } from "./store/selectors/authSelectors";
import { adminLoginConfirmedAction } from "../appAdmin/actions/authActions";
import { useLocation } from "react-router-dom";
import {
  clientFistLoginConfirmedAction,
  clientLoginConfirmedAction,
} from "../appClient/actions/clientAuthActions";
import {
  accesPortalKey,
  baseUrlAdmin,
  clientOtpTokenKey,
  moduleIdKey,
  userDetailsKey,
} from "./constant";

export function Logout(navigate, baseUrl) {
  sessionStorage.clear();
  if (baseUrl) {
    if (baseUrl === baseUrlAdmin) {
      navigate("/auth-a/login");
    } else {
      navigate("/auth/login");
    }
  } else {
    navigate("/auth/login");
  }

  return {
    type: LOGOUT_ACTION,
  };
}

export function savePortal(portal) {
  sessionStorage.setItem(accesPortalKey, portal);
  sessionStorage.setItem(substring(accesPortalKey) + "1b", portal);
}

export function getPortal() {
  return sessionStorage.getItem(accesPortalKey);
}

function substring(chaine) {
  let partieAvant = chaine.substring(0, 9);
  let partieApres = chaine.substring(11);

  let nouvelleChaine = partieAvant + partieApres;

  return nouvelleChaine;
}

export function saveTokenInSessionStorage(tokenDetails) {
  // original
  sessionStorage.setItem(userDetailsKey, JSON.stringify(tokenDetails));

  // duplicate
  sessionStorage.setItem(
    substring(userDetailsKey) + "ff",
    JSON.stringify(tokenDetails),
  );

  sessionStorage.removeItem(clientOtpTokenKey);
  sessionStorage.removeItem(substring(clientOtpTokenKey) + "h2");
}

export function saveOtpToken(tokenDetails) {
  // original
  sessionStorage.setItem(clientOtpTokenKey, JSON.stringify(tokenDetails));

  // duplicate
  sessionStorage.setItem(
    substring(clientOtpTokenKey) + "h2",
    JSON.stringify(tokenDetails),
  );
}

export function checkAutoLogin(dispatch, navigate, baseUrl) {
  const tokenDetailsString = sessionStorage.getItem(userDetailsKey);
  const accesPortal = sessionStorage.getItem(accesPortalKey);
  const moduleId = sessionStorage.getItem(moduleIdKey);
  const otpVerificationInfo = sessionStorage.getItem(clientOtpTokenKey);

  if (otpVerificationInfo) {
    const object = JSON.parse(otpVerificationInfo);
    object.portal = accesPortal;
    dispatch(clientFistLoginConfirmedAction(object));
    return;
  }

  let tokenDetails = "";
  if (tokenDetailsString === null) {
    dispatch(Logout(navigate, baseUrl));
    return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = Number(tokenDetails.authInfo.expireAt) - 2;
  let currentTime = new Date().getTime();

  if (currentTime > expireDate) {
    dispatch(Logout(navigate, baseUrl));
    return;
  }

  tokenDetails.moduleId = moduleId;
  if (accesPortal === ADMINPORTAL) {
    dispatch(adminLoginConfirmedAction(tokenDetails));
  } else if (accesPortal === CUSTOMERPORTAL) {
    dispatch(clientLoginConfirmedAction(tokenDetails));
  } else {
    dispatch(Logout(navigate, baseUrl));
  }
}

export function checkSessionIntegrity(dispatch, navigate, baseUrl) {
  function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  window.addEventListener("storage", (event) => {
    const { key } = event;
    if (key !== null) {
      const originalTokenDetails = JSON.parse(
        sessionStorage.getItem(clientOtpTokenKey),
      );
      const originalPortal = sessionStorage.getItem(accesPortalKey);
      const originalTokenDetailsString = sessionStorage.getItem(userDetailsKey);

      const duplicateTokenDetails = JSON.parse(
        sessionStorage.getItem(substring(clientOtpTokenKey) + "h2"),
      );
      const duplicatePortal = sessionStorage.getItem(
        substring(accesPortalKey) + "1b",
      );
      const duplicateTokenDetailsString = sessionStorage.getItem(
        substring(userDetailsKey) + "ff",
      );

      if (
        !isEqual(originalTokenDetails, duplicateTokenDetails) ||
        originalPortal !== duplicatePortal
      ) {
        dispatch(Logout(navigate, baseUrl));
      }

      if (!isEqual(originalTokenDetailsString, duplicateTokenDetailsString)) {
        dispatch(Logout(navigate, baseUrl));
      }

      if (sessionStorage.length === 0) {
        dispatch(Logout(navigate, baseUrl));
      }
    }
  });
}

export function checkClientOtpTokenIntegrity() {
  const clientOtpToken = sessionStorage.getItem(clientOtpTokenKey);
  const duplicateClientOtpTokenKey = substring(clientOtpTokenKey) + "h2";
  const duplicateClientOtpToken = sessionStorage.getItem(
    duplicateClientOtpTokenKey,
  );

  return (
    clientOtpToken !== null &&
    duplicateClientOtpToken !== null &&
    clientOtpToken === duplicateClientOtpToken
  );
}

export function runLogoutTimer(dispatch, timer, navigate) {
  // setTimeout(() => {
  //     //dispatch(Logout(history));
  //     dispatch(Logout(navigate));
  // }, timer);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
