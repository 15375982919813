import { customerAxiosInstance } from "../../common/axiosInstance"

export const makeAccountTransfer = (data, securityQuestions) => {
  const body = {
    accountId: data.accountID,
    beneficiary_account: data.beneficiary_account,
    memo: data.memo,
    amount: data.amount,
  }
  return customerAxiosInstance.post("transactions/initiate-internal-transfer", body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  })
}

export const makeAccountWithdrawal = (data) => {
  return customerAxiosInstance.post("transactions/make/account/withdrawal", data).catch((err) => {})
}

export const InitiateTransaction = (data, securityQuestions, type) => {
  return customerAxiosInstance.post(`transactions/initiate/${type}`, data, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  })
}

export const getTransactionBilling = ({ account, typeOp, amount }) => {
  const body = {
    Pd_ServerDate: "",
    Pc_PrincipAccount: account,
    Pc_SlaveAccount: account,
    Pc_TypeOp: typeOp,
    Pc_CodeOp: "",
    SvMontant: amount,
  }
  return customerAxiosInstance.post("transactions/getBillingOption", body)
}

export const makeDiaporaDeposit = (data, securityQuestions) => {
  const body = {
    accountId: data.accountID,
    currency: data.currency,
    amount: data.amount,
    description: data.memo,
  }
  return customerAxiosInstance.post("transactions/diaspora-deposit", body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  })
}

export const getNameByNumber = (phonenumber) => {
  return customerAxiosInstance.get(`transactions/verify-name/${phonenumber}`)
}
