import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { FormInput } from "../../common/jsx/components/FormInput";
import {
  addSubscriptions,
  listMbConfigurations,
} from "../services/subscriptionService";
import { ThemeContext } from "../../context/ThemeContext";
import { toast } from "react-toastify";
import { t } from "i18next";
import ErrorManager from "../../appClient/errors";

const chargeOptions = [
  { label: "YES", value: 1 },
  { label: "NO", value: null },
];

const SubscriptionCanvas = forwardRef((props, ref) => {
  const { showLoading } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [addSubscription, setAddSubscription] = useState(false);
  const [mbConfigurations, setMbConfigurations] = useState({});

  useImperativeHandle(ref, () => ({
    showSubscriptionModal() {
      setAddSubscription(true);
    },
  }));

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    addSubscriptions(data)
      .then((res) => {
        setAddSubscription(false);
        props.getPageData();
        toast.success(t("success"));
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate);
        } else {
          ErrorManager(err, navigate);
        }
      });
  };

  useEffect(() => {
    listMbConfigurations().then((v) => {
      const data = v.data.data;
      setMbConfigurations(
        data.map((e) => ({
          label: e.description,
          value: e.code,
        }))
      );
    });
  }, []);

  return (
    <>
      <Offcanvas
        show={addSubscription}
        onHide={setAddSubscription}
        className="offcanvas-end bg-white"
        placement="end"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="modal-title" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddSubscription(false)}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <form action="/subscription/add" onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <FormInput name="accountId" label={t("account_number")} />
                </div>
                <div className="col-xl-12 mb-3">
                  <FormInput name="userLogin" label={t("user_name")} />
                </div>
                <div className="col-xl-12 mb-3">
                  <FormInput
                    name="packageCode"
                    label={t("select_authority")}
                    data={mbConfigurations}
                    type="select"
                  />
                </div>
                <div className="col-xl-12 mb-3">
                  <FormInput
                    name="applyFee"
                    value={1}
                    label={t("aplly_subscription_charge")}
                    data={chargeOptions}
                    type="select"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm me-1"
                  disabled={showLoading}
                >
                  {t("submit_button")}
                </button>
                <Link
                  to={"#"}
                  onClick={() => setAddSubscription(false)}
                  className="btn btn-outline-danger btn-sm ms-1"
                >
                  {t("cancel_button")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default SubscriptionCanvas;
