/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  clientDetails,
  listAllSubscriptions,
  resetSecurityQuestion,
  updateClientEmail,
  updateClientStatus,
} from "../../services/subscriptionService";
import SubscriptionCanvas from "../../components/SubscriptionCanvas";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { ThemeContext } from "../../../context/ThemeContext";
import { DataTablePaginate } from "../../../common/jsx/components/DataTablePaginate";
import SelectOptionFilter from "../../../common/jsx/components/SelectOptionFilter";
import SearchInputFilter from "../../../common/jsx/components/SearchInputFilter";
import { filterType, sortOption } from "../../../data/optionFilter";
import ErrorManager from "../../../appClient/errors";
import { t } from "i18next";
import * as RemixIcon from "react-icons/ri";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { enUS } from "date-fns/locale"; // Import locale for English formatting
import swal from "sweetalert";
import { substringText } from "../../../utils/utilsSubstring";

const CustomerSubscriptions = () => {
  const { setShowLoading, setTableLoading } = useContext(ThemeContext);
  const subscription = useRef();
  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [size] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("ACTIVE");
  const [order, setOrder] = useState("asc");
  const [filter, setFilter] = useState("clientName");
  const [search, setSearch] = useState("");
  const [chooseStatus, setChooseStatus] = useState("");
  const [reason, setReason] = useState("");

  const [editSecurityInfo, setEditSecurityInfo] = useState(false);
  const [dataDetailsUser, setDataDetailsUser] = useState({});
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [editedEmail, setEditedEmail] = useState("");

  // GET TYPE VALUE
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  // GET STATUS VALUE
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  // GET ORDER VALUE
  const handleOrderChange = (e) => {
    setOrder(e.target.value);
  };

  // GET FILTER VALUE
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // GET SEARCH VALUE
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCancelButton = () => {
    setEditSecurityInfo(false);
    setReason("");
  };

  const handleEditEmail = () => {
    setIsEditingEmail(true);
    setEditedEmail(dataDetailsUser.email);
  };

  const handleCancelEdit = () => {
    setIsEditingEmail(false);
    setEditedEmail(dataDetailsUser.email);
  };

  const tableHeader = [
    { label: t("client_matricule") },
    { label: t("login") },
    { label: t("primary_account") },
    { label: t("client_name") },
    { label: t("phone_number") },
    { label: t("email") },
    { label: t("subscription_package") },
    { label: t("subscription_date") },
    { value: "status", label: t("status") },
    { label: t("actions") },
  ];

  const updateSecurityInfo = (matricule) => {
    setEditSecurityInfo(true);
    setShowLoading(true);
    clientDetails(matricule)
      .then((res) => {
        setDataDetailsUser(res.data.data);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  // FILTER SELECT TAG DATA
  const filterOptions = [
    { value: "clientMatricule", label: t("client_matricule") },
    { value: "userLogin", label: t("login") },
    { value: "primaryAccount", label: t("primary_account") },
    { value: "clientName", label: t("client_name") },
    { value: "phoneNumber", label: t("phone_number") },
    { value: "email", label: t("email") },
  ];

  const statusType = [
    { value: "ACTIVE", label: t("active") },
    { value: "", label: t("all") },
    { value: "SUSPENDED", label: t("suspended") },
    { value: "BLOCKED", label: t("blocked") },
  ];

  const handleStatusChoose = (e) => {
    setChooseStatus(e.target.value);
  };

  const handleStatusUpdate = () => {
    setShowLoading(true);
    updateClientStatus(dataDetailsUser.clientMatricule, chooseStatus, reason)
      .then((res) => {
        toast.success(t("status_updated_successfully"), {
          position: "top-right",
        });
        setEditSecurityInfo(false);
        getPageData();
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate);
        } else {
          ErrorManager(err, navigate);
        }
      })
      .finally(() => {
        setShowLoading(false);
        setReason("");
      });
  };

  const getPageData = () => {
    setShowLoading(true);
    setTableLoading(true);
    listAllSubscriptions(order, filter, search, type, status, page, size)
      .then((res) => {
        setTotalElements(res.data?.data?.totalElements);
        setTotalPages(res.data?.data?.totalPages);
        setTableData(
          res.data?.data?.data.map((d) => [
            d.clientMatricule,
            d.userLogin,
            d.primaryAccount,
            <div className="text-nowrap">{d.name}</div>,
            d.phoneNumber,
            d.email,
            "",
            <div className="text-nowrap">
              {format(new Date(d.subscriptionDate), "EEEE dd MMMM yyyy", {
                locale: enUS,
              })}
            </div>,
            <button
              className={`badge p-1 ${d.status === "ACTIVE" ? "bg-success" : d.status === "PENDING" ? "bg-warning" : "bg-danger"}`}
            >
              {d.status}
            </button>,
            <button
              onClick={() => updateSecurityInfo(d.clientMatricule)}
              type="button"
              className="d-flex gap-1 align-items-center btn btn-sm btn-outline-primary"
            >
              <RemixIcon.RiEyeLine />
              {t("details")}
            </button>,
          ]),
        );
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate);
        } else {
          ErrorManager(err, navigate);
        }
      })
      .finally(() => {
        setShowLoading(false);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    setPage(0);
    getPageData();
    setShowLoading(false);
  }, [order, filter, search, type, status, size]);

  useEffect(() => {
    getPageData();
    setShowLoading(false);
  }, [page]);

  const statusOptions = ["ACTIVE", "INACTIVE", "SUSPENDED", "BLOCKED"];
  const filteredStatusOptions = statusOptions.filter(
    (status) => status !== dataDetailsUser.status,
  );

  const handleResetSecurity = () => {
    swal({
      title: t("reset_security"),
      text: `${t("are_you_sure_you_want_to_reset_security")}?`,
      icon: "warning",
      buttons: [t("button_cancel"), t("button_reset")],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        resetSecurityQuestion(dataDetailsUser.clientMatricule)
          .then((res) =>
            swal(`${"reset"}!`, t("security_has_been_reset"), t("success")),
          )
          .catch((err) => {
            ErrorManager(err, navigate);
          });
      }
    });
  };

  const handleSaveEmail = () => {
    if (editedEmail !== dataDetailsUser.email) {
      setShowLoading(true);
      const body = {
        status: dataDetailsUser.status,
        email: editedEmail,
      };

      updateClientEmail(dataDetailsUser.clientMatricule, body)
        .then((res) => {
          toast.success(t("email_updated_successfully"), {
            position: "top-right",
          });
          setEditSecurityInfo(false);
          getPageData();
        })
        .catch((err) => {
          if (err.response) {
            ErrorManager(err, navigate);
          } else {
            ErrorManager(err, navigate);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  return (
    <>
      <MainPageTitle
        mainTitle="Subscriptions"
        pageTitle={"Subscribers"}
        parentTitle={"Subscriptions"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card rounded-0 p-3 shadow">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center p-0 mb-3">
                    <h4 className="heading mb-0">{t("subscriptions")}</h4>
                    <div>
                      <Link
                        to={"#"}
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="offcanvas"
                        onClick={() =>
                          subscription.current.showSubscriptionModal()
                        }
                      >
                        + {t("add_subscription")}
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="option-filter-table">
                    <SelectOptionFilter
                      label={t("status")}
                      id="status"
                      name={status}
                      value={status}
                      onChange={handleStatusChange}
                      options={statusType}
                    />

                    <SelectOptionFilter
                      label={t("customer_type")}
                      id="type"
                      name={type}
                      value={type}
                      onChange={handleTypeChange}
                      options={filterType}
                    />

                    <SelectOptionFilter
                      label={t("sort_by")}
                      id="sort"
                      name={order}
                      value={order}
                      onChange={handleOrderChange}
                      options={sortOption}
                    />

                    <SelectOptionFilter
                      label={t("filter_by")}
                      id="filter"
                      name={filter}
                      value={filter}
                      onChange={handleFilterChange}
                      options={filterOptions}
                    />

                    <SearchInputFilter
                      filter={filter}
                      placeholder={t("type_here")}
                      ariaLabel="Search"
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div>
                    <DataTablePaginate
                      headersTitle={tableHeader}
                      tableData={tableData}
                      totalPages={totalPages}
                      currentPage={page}
                      size={size}
                      totalElements={totalElements}
                      setPage={setPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" size="md" show={editSecurityInfo} centered>
        <Modal.Header
          style={{ height: "3rem" }}
          className="bg-primary border-0"
        >
          <Modal.Title className="text-white">{t("details_user")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <div className="row d-flex flex-column gap-2">
            <div className="col-12 border shadow p-3">
              <span className="fw-bold"> {t("client_name")}</span>:{" "}
              {dataDetailsUser.name}
            </div>
            <div className="col-12 border shadow p-3">
              <span className="fw-bold">{t("phone_number")} : </span>
              {dataDetailsUser.phoneNumber
                ? dataDetailsUser.phoneNumber
                : "---"}
            </div>
            <div className="col-12 border shadow p-3 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-1">
                <span className="fw-bold d-flex">{t("email")}:</span>
                {isEditingEmail ? (
                  // Mode d'édition : afficher un champ input
                  <input
                    type="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                    className="form-control p-1 p h-75"
                  />
                ) : dataDetailsUser.email ? (
                  substringText(dataDetailsUser.email, 30)
                ) : (
                  "---"
                )}
              </div>
              <div className="d-flex gap-2">
                {isEditingEmail ? (
                  <>
                    {/* Bouton Enregistrer */}
                    {editedEmail !== dataDetailsUser.email && (
                      <button
                        className="btn btn-success btn-sm"
                        onClick={handleSaveEmail}
                      >
                        <RemixIcon.RiCheckLine size={15} />
                        {t("save_button")}
                      </button>
                    )}

                    {/* Bouton Annuler */}
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={handleCancelEdit}
                    >
                      <RemixIcon.RiCloseLine size={15} />
                      {editedEmail === dataDetailsUser.email &&
                        t("cancel_button")}
                    </button>
                  </>
                ) : (
                  // Bouton Edit si non en édition
                  <button
                    className="btn btn-outline-primary btn-sm d-flex gap-1 align-items-center"
                    onClick={handleEditEmail}
                  >
                    <RemixIcon.RiEditBoxLine size={15} />
                    {t("edit_button")}
                  </button>
                )}
              </div>
            </div>
            <div className="col-12 d-flex justify-content-between align-items-center border shadow p-3 fw-bold">
              {t("reset_security")}
              <button
                className="btn btn-warning btn-sm d-flex gap-1"
                onClick={handleResetSecurity}
              >
                <RemixIcon.RiRestartLine size={15} />
                {t("reset_button")}
              </button>
            </div>
            <div className="col-12 border shadow p-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="fw-bold">{t("status")}: </span>{" "}
                  {dataDetailsUser.status}
                </div>
                <select
                  name="status"
                  id="status"
                  className="p-1 border bg-l"
                  onChange={handleStatusChoose}
                  value={chooseStatus}
                >
                  <option value="">---</option>
                  {filteredStatusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex justify-content-between mt-1 hidden">
                <div hidden={chooseStatus === ""}>
                  <label htmlFor="reason" className="me-2">
                    {t("reason")}: <strong className="text-danger">*</strong>
                  </label>
                  <input
                    type="text"
                    className="border p-1"
                    required
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-sm btn-primary d-flex gap-1"
                  disabled={reason === ""}
                  onClick={handleStatusUpdate}
                >
                  <RemixIcon.RiSendPlaneLine size={15} />
                  {t("change")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 bg-white">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleCancelButton}
          >
            {t("cancel_button")}
          </button>
        </Modal.Footer>
      </Modal>

      <SubscriptionCanvas
        ref={subscription}
        getPageData={getPageData}
        Title={t("add_subscription")}
      />
    </>
  );
};

export default CustomerSubscriptions;
