/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { getAllSecurityQuestions } from "../../services/authService";
import { executeDelete } from "../../services/service";
import { formPost } from "../../services/postForms";
import { useDispatch } from "react-redux";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import ErrorManager from "../../../appClient/errors";
import { ThemeContext } from "../../../context/ThemeContext";
import { RiDeleteBin2Line } from "react-icons/ri";
import { t } from "i18next";

const headersTitle = [{ label: t("question") }, { label: t("actions") }];

const initialInputs = {
  question: "",
};
const SecurityQuestions = () => {
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [inputs, setInputs] = useState(initialInputs);

  const getPageData = () => {
    setShowLoading(true);
    getAllSecurityQuestions()
      .then((v) => {
        const data = v.data.data;
        setTableData(
          data.map((d) => [
            d.question,
            <div className="action-button">
              {d.questionCount === 0 ? (
                <button
                  type="button"
                  onClick={() =>
                    executeDelete(
                      `/instConfig/deleteQuestion/${d.id}`,
                      dispatch,
                      "DELETE_QUESTION",
                      getPageData,
                    )
                  }
                  className="btn btn-icon-xxs text-danger"
                >
                  <RiDeleteBin2Line />
                </button>
              ) : (
                ""
              )}
            </div>,
          ]),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
    setShowLoading(false);
  }, []);

  const handleChange = (event) => {
    event.persist();
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value.toUpperCase(),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    const formAction = event.target.getAttribute("action");
    const type = event.target.getAttribute("type");
    formPost(formAction, data, type)
      .then((res) => {
        getPageData();
        setInputs(initialInputs);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (
    <>
      <MainPageTitle
        mainTitle={t("Configurations")}
        pageTitle={t("security_questions")}
        parentTitle={t("Configurations")}
      />
      <div className="container-fluid">
        <div className="card shadow rounded-0">
          <div className="row card-body">
            <div style={{ minHeight: "30rem" }} className="col-xl-6">
              <form
                action={`/instConfig/addQuestion`}
                onSubmit={handleSubmit}
                className="row"
              >
                <div className="col-xl-8">
                  <FormInput
                    onchange={handleChange}
                    value={inputs.question}
                    label={t("enter_security_question")}
                    name="question"
                    type="text"
                  />
                </div>
                <div className="col-xl-4 d-flex align-items-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm me-1"
                    disabled={showLoading}
                  >
                    {t("save_button")}
                  </button>
                  <Link
                    onClick={(e) => setInputs(initialInputs)}
                    to={"#"}
                    className="btn btn-outline-danger btn-sm ms-1"
                  >
                    {t("clear_button")}
                  </Link>
                </div>
                <div className="col-12">
                  <DataTable
                    headersTitle={headersTitle}
                    tableData={tableData}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SecurityQuestions;
