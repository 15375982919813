/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAppPaths } from "../../services/authService";
import {
  DELETE_PATH,
  executeDelete,
  executeGet,
  ROLES_PATHS,
} from "../../services/service";
import { SVGICON } from "../../../common/constant/theme";
import {
  formPost,
  submitFailed,
  submitSuccess,
} from "../../services/postForms";
import { ThemeContext } from "../../../context/ThemeContext";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { FormInput } from "../../../common/jsx/components/FormInput";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import * as RemixIcon from "react-icons/ri";
import ErrorManager from "../../../appClient/errors";
import { toast } from "react-toastify";
import AdminRoute from "../../../data/adminRoute";
import { t } from "i18next";

export const tabs = [
  {
    title: t("modules"),
    module: "MODULE",
    icon: "",
    form: {},
  },
  {
    title: t("menus"),
    module: "MENU",
    icon: "",
    form: {},
  },
];

const initialPathList = {
  paths: [],
  moduleList: [],
  parents: [],
};
const MenuManagement = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [pathsList, setState] = useState(initialPathList);
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const [tabDatas, setInputs] = useState(tabs);
  const [isFormEmpty, setIsFormEmpty] = useState(true);

  const tableHeaders = {
    modules: [
      { label: t("name") },
      { label: t("position") },
      { label: t("path") },
      { label: t("action") },
    ],
    menus: [
      { label: t("name") },
      { label: t("position") },
      { label: t("path") },
      { label: t("module") },
      { label: t("parent") },
      { label: t("action") },
    ],
  };

  const menuTypes = [
    {
      value: t("SINGLE"),
      label: "SINGLE",
    },
    {
      value: t("DOUBLE"),
      label: "DOUBLE",
    },
    {
      value: t("TAG"),
      label: "TAG",
    },
  ];

  const menuIcons = Object.keys(SVGICON).map((k) => ({
    value: k,
    label: (
      <span className="nav-text">
        {SVGICON[k.icon]} {k}
      </span>
    ),
  }));

  const handleChange = (formIndex, event) => {
    event.persist();
    setInputs((forms) => {
      const updatedForms = [...forms];
      updatedForms[formIndex].form = {
        ...updatedForms[formIndex].form,
        [event.target.name]: event.target.value,
      };
      return updatedForms;
    });
  };

  const getPageData = () => {
    setShowLoading(true);
    getAppPaths()
      .then((v) => {
        const data = v.data.data;
        setState({
          ...pathsList,
          menus: data
            .filter((p) => p.type === "MENU")
            .sort((a, b) => a.menuLevel - b.menuLevel)
            .map((d) => [
              <span>
                {SVGICON[d.icon]} {d.name}
              </span>,
              <span className="badge-custom">{d.menuLevel}</span>,
              d.path ? (
                d.path
              ) : (
                <span className="badge badge-success light border-0">
                  {" "}
                  {d.menuStyle}{" "}
                </span>
              ),
              d.module ? d.module.name : "",
              d.parent ? d.parent.name : "",
              <div className="action-button">
                <button
                  type="button"
                  onClick={() =>
                    executeGet(
                      `/authz/findPath/${d.id}`,
                      dispatch,
                      ROLES_PATHS,
                      setUpadtePath,
                    )
                  }
                  className="btn text-primary btn-icon-xxs"
                >
                  <RemixIcon.RiPencilLine />
                </button>{" "}
                <button
                  type="button"
                  onClick={() =>
                    executeDelete(
                      `/authz/deleteAppPath/${d.id}`,
                      dispatch,
                      DELETE_PATH,
                      getPageData,
                    )
                  }
                  className="btn text-danger btn-icon-xxs"
                >
                  <RemixIcon.RiDeleteBin2Line />
                </button>
              </div>,
            ]),
          modules: data
            .filter((p) => p.type === "MODULE")
            .map((d) => [
              <span>
                {SVGICON[d.icon]} {d.name}
              </span>,
              <span className="badge-custom">{d.menuLevel}</span>,
              d.path,
              <div className="action-button">
                <button
                  type="button"
                  onClick={() =>
                    executeGet(
                      `/authz/findPath/${d.id}`,
                      dispatch,
                      ROLES_PATHS,
                      setUpadtePath,
                    )
                  }
                  className="btn btn-icon-xxs text-primary"
                >
                  <RemixIcon.RiPencilLine />
                </button>{" "}
                <button
                  type="button"
                  onClick={() =>
                    executeDelete(
                      `/authz/deleteAppPath/${d.id}`,
                      dispatch,
                      DELETE_PATH,
                      getPageData,
                    )
                  }
                  className="btn btn-icon-xxs text-danger"
                >
                  <RemixIcon.RiDeleteBin2Line />
                </button>
              </div>,
            ]),
          moduleList: data
            .filter((p) => p.type === "MODULE")
            .sort((a, b) => a.menuLevel - b.menuLevel)
            .map((v) => ({
              value: v.id,
              label: v.name,
            })),
          paths: AdminRoute.map((v) => ({
            value: `/${v.url}`,
            label: `/${v.url}`,
            name: `${data.title}_path`,
          })).filter(
            ({ value: idA }) =>
              !data
                .filter((m) => m.type === "MENU")
                .some(({ path: idB }) => idA === idB),
          ),
          parents: data
            .filter((p) => p.isDouble === true)
            .map((v) => ({
              value: v.id,
              label: v.name,
            })),
        });
        dispatch(submitSuccess(data, ""));
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
        dispatch(submitFailed({}, ""));
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getPageData();
    setInputs(tabs);
    setShowLoading(false);
  }, []);

  const updateFormEmptyState = () => {
    const isAllFieldsEmpty = tabDatas.every((data) =>
      Object.values(data.form).every((value) => value.trim() === ""),
    );
    setIsFormEmpty(isAllFieldsEmpty);
  };

  useEffect(() => {
    updateFormEmptyState();
  }, [tabDatas]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoading(true);
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    const formAction = event.target.getAttribute("action");
    formPost(formAction, data)
      .then((res) => {
        getPageData();
        toast.success("Save", {
          position: "top-right",
        });
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const setUpadtePath = (data) => {
    setState((list) => {
      const newOption = { value: data.path, label: data.path };
      const exist = list.paths.find((v) => v.value === data.path);
      if (!exist) {
        list.paths = [...list.paths, ...[newOption]];
      }
      return list;
    });

    setInputs((forms) => {
      const updatedForms = [...forms];
      const FormToUpdate = updatedForms.find(
        (v) => v.module === data.type,
      ).form;
      updatedForms.find((v) => v.module === data.type).form = {
        ...FormToUpdate,
        name: data.name,
        path: data.path,
        icon: data.icon,
        moduleId: data.module ? data.module.id : null,
        parentId: data.parent ? data.parent.id : null,
        menuStyle: data.menuStyle,
        order: data.menuLevel,
      };
      return updatedForms;
    });
  };

  return (
    <>
      <MainPageTitle
        mainTitle="Security"
        pageTitle="manage-menus"
        parentTitle="Security"
      />
      <div className="container-fluid">
        <div className="row p-3 pt-0">
          <div className="custom-tab-1 bg-white shadow">
            <Tab.Container
              defaultActiveKey={tabDatas ? tabDatas[0].title.toLowerCase() : ""}
            >
              <Nav as="ul" className="nav-tabs">
                {tabDatas.map((data, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link eventKey={data.title.toLowerCase()}>
                      <i className={`la la-${data.icon} me-2`} />
                      {data.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {tabDatas.map((data, i) => (
                  <Tab.Pane eventKey={data.title.toLowerCase()} key={i}>
                    <div className="row">
                      <div className="col-xl-4">
                        <div className="card-body">
                          <form
                            action={`/authz/createNewPath/${data.module}`}
                            onSubmit={handleSubmit}
                            className="row border-custom pt-2 pb-3"
                            key={i}
                            ref={data.ref}
                          >
                            <div className="col-xl-12 ">
                              <FormInput
                                name="path"
                                value={data.form.path ?? ""}
                                required={data.title === "modules"}
                                label={`${data.title}_path`}
                                type="select"
                                data={pathsList.paths}
                              />
                            </div>
                            {data.title === "menus" && (
                              <div className="col-xl-12">
                                <FormInput
                                  name="moduleId"
                                  value={data.form.moduleId ?? ""}
                                  type="select"
                                  label="module"
                                  data={pathsList.moduleList}
                                />
                              </div>
                            )}
                            {data.title === "menus" && (
                              <div className="col-xl-6 col-lg-6">
                                <FormInput
                                  name="menuStyle"
                                  value={data.form.menuStyle ?? ""}
                                  data={menuTypes}
                                  label="menu_type"
                                  type="select"
                                />
                              </div>
                            )}
                            <div className="col-xl-6 col-lg-6">
                              <FormInput
                                label="name"
                                name="name"
                                value={data.form.name ?? ""}
                                onchange={(event) => handleChange(i, event)}
                              />
                            </div>
                            <div className="col-xl-6 col-lg-6">
                              <FormInput
                                label="icon"
                                name="icon"
                                value={data.form.icon ?? ""}
                                type="select"
                                data={menuIcons}
                              />
                            </div>
                            {data.title === "menus" && (
                              <div className="col-xl-6 col-lg-6">
                                <FormInput
                                  required={false}
                                  label={`parent_${data.title}`}
                                  name="parentId"
                                  value={data.form.parent ?? ""}
                                  data={pathsList.parents}
                                  type="select"
                                />
                              </div>
                            )}
                            <div className="col-xl-6 col-lg-6">
                              {" "}
                              <FormInput
                                type="number"
                                name="order"
                                value={data.form.order ?? ""}
                                onchange={(event) => handleChange(i, event)}
                                label="display_order"
                              />{" "}
                            </div>
                            <div className="d-flex align-items-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm me-1"
                                disabled={isFormEmpty || showLoading}
                              >
                                {t("save_button")}
                              </button>
                              <Link
                                to={"#"}
                                className="btn btn-outline-danger btn-sm light ms-1"
                              >
                                {t("clear_button")}
                              </Link>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div className="col-xl-8">
                        <div className="filter cm-content-box rounded-0 box-primary">
                          <div className="card-body">
                            <DataTable
                              headersTitle={tableHeaders[data.title]}
                              tableData={pathsList[data.title]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default MenuManagement;
