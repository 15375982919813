import React, { useContext, useEffect, useState } from "react";
import * as RemixIcon from "react-icons/ri";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  accountConfiguration,
  getAccountConfiguration,
  updateClientConfig,
} from "../../services/acountServices";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { t } = useTranslation();
  const [configuration, setConfiguration] = useState([]);
  const { setShowLoading } = useContext(ThemeContext);
  const [config, setConfig] = useState({
    id: undefined,
    alertAmount: false,
    minAmount: 0,
    maxAmount: 0,
    alertTrans: false,
    transLimit: 0,
  });

  const Navigate = useNavigate();

  const configTableHeaders = [
    { label: t("account_name") },
    { label: t("alert_amount") },
    { label: t("alert_transaction") },
    { label: t("all") },
    { label: t("action") },
  ];

  const getListAccount = () => {
    accountConfiguration().then((res) => {
      setConfiguration(
        res.data.data.map((b) => [
          b.accountName,
          b.id ? (
            <div className="form-check form-switch">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                id={`alertAmount-${b.id}`}
                checked={b.alertAmount}
                onChange={(event) => handleAlertAmountChange(event, b.id)}
              />
            </div>
          ) : (
            t("not_set")
          ),
          b.id ? (
            <div className="form-check form-switch">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                id={`alertTrans-${b.id}`}
                checked={b.alertTrans}
                onChange={(event) => handleAlertTransChange(event, b.id)}
              />
            </div>
          ) : (
            t("not_set")
          ),
          b.id ? (
            <div className="form-check form-switch">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                id={`disableAll-${b.id}`}
                checked={b.alertAmount && b.alertTrans}
                onChange={(event) => handleDisableAllChange(event, b.id)}
              />
            </div>
          ) : (
            t("not_set")
          ),
          <div className="action-button">
            <button
              type="button"
              onClick={() =>
                Navigate(
                  `/notification/config?an=${b.accountNumber}&idc=${b.id}`,
                )
              }
              className="btn btn-outline-primary btn-sm"
            >
              {" "}
              {t("config_button")} <RemixIcon.RiSettings2Line />
            </button>
          </div>,
        ]),
      );
    });
    setShowLoading(false);
  };

  const handleAlertAmountChange = (event, id) => {
    const isChecked = event.target.checked;
    if (id > 0) {
      const type = "alertAmount";
      setShowLoading(true);
      getAccountConfiguration(id)
        .then((res) => {
          setConfig(res.data.data);
          finalEndPoint(id, isChecked, type);
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const handleAlertTransChange = (event, id) => {
    const isChecked = event.target.checked;
    if (id > 0) {
      const type = "alertTrans";
      setShowLoading(true);
      getAccountConfiguration(id)
        .then((res) => {
          setConfig(res.data.data);
          finalEndPoint(id, isChecked, type);
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const handleDisableAllChange = (event, id) => {
    const isChecked = event.target.checked;
    if (id > 0) {
      const type = "all";
      setShowLoading(true);
      getAccountConfiguration(id)
        .then((res) => {
          setConfig(res.data.data);
          finalEndPoint(id, isChecked, type);
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  const finalEndPoint = (id, isChecked, type) => {
    config.id = id;

    if (type === "alertAmount") {
      config.alertAmount = isChecked;
    } else if (type === "alertTrans") {
      config.alertTrans = isChecked;
    } else {
      config.alertAmount = isChecked;
      config.alertTrans = isChecked;
    }

    setShowLoading(false);
    updateClientConfig(config)
      .then((res) => {
        getListAccount();
        toast.success(isChecked ? "actived" : "desactived", {
          position: "top-right",
        });
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getListAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{t("notification")}</h4>
      <div className="row top-50">
        <div className="col-xl-12">
          <div className="card bg-white border-0 shadow">
            <div className="card-body">
              <div className="row ">
                <div className="col-xl-12 pb-2">
                  <DataTable
                    headersTitle={configTableHeaders}
                    tableData={configuration}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
