/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { getCustomerAccounts } from "../../services/acountServices";
import { connect } from "react-redux";
import { ThemeContext } from "../../../context/ThemeContext";
import { makeAccountWithdrawal } from "../../services/mobileBankService";
// import { Nav, Tab } from "react-bootstrap";
import { useQuery } from "../../../common/services";
import { FormInput } from "../../../common/jsx/components/FormInput";
import ErrorManager from "../../errors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const airTimeProviders = [
  {
    label: "ORANGE AIRTIME",
    value: "OMDEPO",
  },
  {
    label: "MTN AIRTIME",
    value: "MOMODE",
  },
];

const AirTimePurchase = (props) => {
  const { t } = useTranslation();

  const [optionAccounts, setOptionAccounts] = useState([]);
  const [selectAccount, setSelectedAccount] = useState(
    props.user.primaryAccount,
  );
  const { setShowLoading } = useContext(ThemeContext);
  // const [transactionDetails, setTransactionDetails] = useState(null);

  let query = useQuery();
  const [, setActiveKey] = useState(query.get("page"));

  const Navigate = useNavigate();

  const handleAccountChange = (e) => {
    setSelectedAccount(e.value);
  };

  const getPageData = async () => {
    setShowLoading(true);
    try {
      try {
        const v = await getCustomerAccounts();
        const data = v.data.data;
        setOptionAccounts(
          data.map((act) => ({
            value: act.accountID,
            label: `${act.accountID} -${act.accountName}`,
          })),
        );
        return data;
      } catch (err) {
        ErrorManager(err, Navigate);
      }
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getPageData().then((v) => setShowLoading(false));
  }, []);

  useEffect(() => {
    setActiveKey(query.get("page"));
  }, [query]);

  const handleValidate = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const postData = Object.fromEntries(formData.entries());

    setShowLoading(true);
    makeAccountWithdrawal(postData)
      // .then((v) => {
      //     const data = v.data.data
      // })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  return (
    <div className="container">
      <div className="row top-50">
        <div className="col-xl-12">
          <div className="card bg-white border-0 ">
            <div className="card-body">
              <div className="custom-tab-1 bg-white">
                <div className="row ">
                  <div className="col-xl-4 bg-light rounded border-left-2 pb-3 pt-2">
                    <div className="">
                      <h4 className="card-title fw-bold pb-3">
                        {t("momo_operation")}{" "}
                      </h4>
                    </div>
                    <form method="POST" onSubmit={handleValidate}>
                      <div className="row ">
                        <div className="col-xl-12 ">
                          <FormInput
                            onchange={(event) => handleAccountChange(event)}
                            data={optionAccounts}
                            value={selectAccount}
                            label={t("select_account_to_credit")}
                            required={true}
                            name="accountId"
                            type="select"
                          />
                        </div>
                        <div className="col-xl-12 ">
                          <FormInput
                            data={airTimeProviders}
                            label={t("select_operator")}
                            required={true}
                            name="typeOp"
                            type="select"
                          />
                        </div>
                        <div className="col-xl-12 ">
                          <FormInput
                            label={t("user_phone")}
                            required={true}
                            name="phoneNumber"
                            type="number"
                          />
                        </div>
                        <div className="col-xl-12 ">
                          <FormInput
                            label={t("amount")}
                            required={true}
                            name="amount"
                            type="number"
                          />
                        </div>
                        <div className="col-xl-12 ">
                          <FormInput
                            label={t("popos_of_trnfs")}
                            required={true}
                            name="description"
                            type="text"
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary btn-sm me-3"
                          disabled={false}
                          type="button"
                        >
                          {t("validate_button")}
                        </button>
                        <button
                          className="btn btn-outline-primary btn-sm"
                          disabled={false}
                          type="submit"
                        >
                          {t("cancel_button")}
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="col-xl-4 text-center">
                    <h4 className="fw-bold">{t("transaction_details")}</h4>
                    <div>
                      <span> {t("account_details")} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userDao,
  };
};
export default connect(mapStateToProps)(AirTimePurchase);
