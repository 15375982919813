import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Login from "../appClient/pages/auth/Login";
import ForgotPassword from "../appClient/pages/auth/ForgotPassword";
import SetNewPassword from "../appClient/pages/auth/SetNewPassword";
import Register from "../appClient/pages/auth/Register";
import Register2 from "../appClient/pages/auth/Register2";
import FirstEndPointSuccess from "../appClient/pages/auth/FirstEndPointSuccess";
import SecondEndPointSuccess from "../appClient/pages/auth/SecondEndPointSuccess";
import AutOtpPage from "../common/jsx/pages/AutOtpPage";
import DiasporaDepositResponse from "../appClient/pages/transactions/DiasporaDepositResponse";
import { AuthLayout } from "../common/jsx/pages";
import SetSecurityQuestion from "../appClient/pages/auth/SetSecurityQuestion";
import { isAuthenticated } from "../common/store/selectors/authSelectors";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import { checkSessionIntegrity } from "../common/services";
import { baseUrlClient } from "../common/constant";
import Subscribe from "../appClient/pages/auth/Subscribe";

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
};

const AuthRouter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkSessionIntegrity(dispatch, navigate, baseUrlClient);
  }, [dispatch, navigate]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password/:guid" element={<SetNewPassword />} />
        <Route path="page-register" element={<Register />} />
        <Route path="page-register/next-step/:uuid" element={<Register2 />} />
        <Route
          path="page-register/first-end-point-success"
          element={<FirstEndPointSuccess />}
        />
        <Route
          path="page-register/second-end-point-success"
          element={<SecondEndPointSuccess />}
        />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="otp-verification" element={<AutOtpPage />} />
        <Route
          path="diaspora-deposit-response"
          element={<DiasporaDepositResponse />}
        />
        <Route
          path="set-security-questions"
          element={<SetSecurityQuestion />}
        />
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    userDto: state.auth.userDao,
  };
};

export default withRouter(connect(mapStateToProps)(AuthRouter));
