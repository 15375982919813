/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import * as RemixIcon from "react-icons/ri";
import registration from "../../../common/images/login/8.jpg";
import "../../../styles/register.css";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { baseUrlClient } from "../../../common/constant";
import { Logout } from "../../../common/services";
import { useTranslation } from "react-i18next";

const SecondEndPointSuccess = () => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const { setShowLoading, setShowFooter } = useContext(ThemeContext);
  const [shouldRenderContent, setShouldRenderContent] = useState(false);

  useEffect(() => {
    const storedDate = sessionStorage.getItem(".,mndbfsvdaa!@#$%^&*()_szdx");
    const storedTimestamp = new Date(storedDate).getTime();
    const currentTimestamp = new Date().getTime();
    if (!storedDate || currentTimestamp - storedTimestamp > 60000) {
      setShowFooter(false);
      goHome();
    } else {
      setShouldRenderContent(true);
    }

    setShowLoading(false);
  }, [Navigate]);

  function goHome() {
    sessionStorage.removeItem("secondEndPoint");
    sessionStorage.removeItem("zwz%098123jh.fdr");
    Logout(Navigate, baseUrlClient);
  }

  return (
    shouldRenderContent && (
      <div className="subscribe success-end-point">
        <div className="left">
          <img className="login-image" src={registration} alt={registration} />
        </div>
        <div className="right">
          <div className="all-step">
            <div className="form-step">
              <div className="content-4">
                <p> {t("thank_you_sucess")}</p>
                {/* <p>{t('print-request')}<strong style={{ cursor: 'pointer' }}>{t('print-request-btn')}</strong></p> */}
              </div>
            </div>
            <div className="step-button">
              <button type="button" onClick={goHome}>
                <RemixIcon.RiHome2Line />
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SecondEndPointSuccess;
