import React, { useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { DataTable } from "../../../common/jsx/components/DataTable";
import ReactQuill from "react-quill";
import {
  listNotificationConfigs,
  updateNotificationTemplate,
} from "../../services/authService";
import { ThemeContext } from "../../../context/ThemeContext";
import { RiPencilLine } from "react-icons/ri";
import ErrorManager from "../../../appClient/errors";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { toast } from "react-toastify";

const tableHeaders = [
  { label: t("event_code") },
  { label: t("subject") },
  { label: t("status") },
  { label: t("actions") },
];

const smsTableHeaders = [
  { label: t("event_code") },
  { label: t("status") },
  { label: t("actions") },
];

const status = [
  { label: "ACTIVE", value: t("ACTIVE") },
  { label: "INACTIVE", value: t("INACTIVE") },
];

const NotificationTemplates = () => {
  const [displayTab, setDisplayTab] = useState("email-tab");
  const [notificationTemplates, setNotificationTemplates] = useState([]);
  const [smsTemplates, setSmsTemplate] = useState([]);
  const [emailTemplates, setEmailTemplate] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [templateType, setTemplateType] = useState();
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const navigate = useNavigate();

  const edithTemplate = (id, type) => {
    const event = notificationTemplates.find((v) => v.id === id);
    if (event) {
      setSelectedEvent((prevState) => {
        return event;
      });
      setTemplateType(type);
    }
  };

  useEffect(() => {
    setShowLoading(true);
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSmsTemplate(
      notificationTemplates
        .filter((v) => v.notificationType === "SMS")
        .map((templ) => [
          templ.eventCode,
          templ.template ? t("CONFIGURED") : t("NOT_CONFIGURED"),
          <button
            type="button"
            onClick={(e) => edithTemplate(templ.id, "SMS")}
            className="btn text-primary btn-icon-xxs"
          >
            <RiPencilLine />
          </button>,
        ]),
    );

    setEmailTemplate(
      notificationTemplates
        .filter((v) => v.notificationType === "MAIL")
        .map((templ) => [
          templ.eventCode,
          templ.subject ? templ.subject : t("ADD_A_SUBJECT"),
          templ.template ? t("CONFIGURED") : t("NOT_CONFIGURED"),
          <button
            type="button"
            onClick={(e) => edithTemplate(templ.id, "MAIL")}
            className="btn text-primary btn-icon-xxs"
          >
            <RiPencilLine />
          </button>,
        ]),
    );
    setShowLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTemplates]);

  const getPageData = () => {
    setShowLoading(true);
    return listNotificationConfigs()
      .then((v) => {
        const data = v.data.data;
        setNotificationTemplates(data);
        return data;
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleTemplateChange = (value) => {
    setSelectedEvent((prevState) => {
      return {
        ...prevState,
        template: value,
      };
    });
  };

  const handleSubjectChange = (e) => {
    setSelectedEvent((prevState) => {
      return {
        ...prevState,
        subject: e.target.value,
      };
    });
  };

  const handleStatusChange = (e) => {
    setSelectedEvent((prevState) => {
      return {
        ...prevState,
        status: e.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoading(true);
    updateNotificationTemplate(selectedEvent)
      .then((v) => {
        getPageData();
        setTemplateType("");
        toast.success(t("changes_saved_successfully"), {
          position: "top-right",
        });
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
  ];

  return (
    <>
      <MainPageTitle
        mainTitle={t("Configurations")}
        pageTitle={t("templates")}
        parentTitle={t("notifications")}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card bg-white border-0 shadow">
              <div className="card-body">
                <div className="custom-tab-1 bg-white">
                  <Tab.Container
                    activeKey={displayTab}
                    defaultActiveKey={displayTab}
                  >
                    <Nav as="ul" className="nav-tabs">
                      <Nav.Item
                        onClick={(e) => setDisplayTab("email-tab")}
                        as="li"
                        key="email-tab-nav"
                      >
                        <Nav.Link eventKey="email-tab">
                          {t("email_templates")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item
                        onClick={(e) => setDisplayTab("sms-tab")}
                        as="li"
                        key="sms-tab-nav"
                      >
                        <Nav.Link eventKey="sms-tab">
                          {t("sms_templates")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content className="pt-4">
                      <Tab.Pane eventKey="email-tab" key={`content_email-tab`}>
                        <DataTable
                          tableData={emailTemplates}
                          headersTitle={tableHeaders}
                        />
                      </Tab.Pane>

                      <Tab.Pane eventKey="sms-tab" key={`content_sms-tab`}>
                        <DataTable
                          tableData={smsTemplates}
                          headersTitle={smsTableHeaders}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" size="lg" show={templateType === "MAIL"} centered>
        <Modal.Header className="bg-primary">
          <Modal.Title className="text-white">
            {t("set_email_template")} {selectedEvent.eventCode} :{" "}
            {selectedEvent.notificationType}{" "}
          </Modal.Title>
          <Button
            variant=""
            onClick={(e) => setTemplateType("")}
            className="btn-close"
          ></Button>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="row">
            <div className="col-xl-6">
              <FormInput
                label="subject"
                onchange={handleSubjectChange}
                value={selectedEvent.subject ?? ""}
                name="subject"
              />
            </div>
            <div className="col-xl-6">
              <FormInput
                onchange={handleStatusChange}
                value={selectedEvent.status ?? ""}
                label="status"
                type="select"
                name="status"
                data={status}
              />
            </div>
            <div className="col-xl-12" style={{ minHeight: "250px" }}>
              <ReactQuill
                id="templateContent"
                value={selectedEvent.template ?? ""}
                onChange={handleTemplateChange}
                name="template"
                formats={formats}
                className="h-75"
                modules={{
                  toolbar: [
                    [{ header: "1" }, { header: "2" }, { font: [] }],
                    [{ size: [] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image", "video"],
                    ["clean"],
                    [{ color: [] }, { background: [] }], // Add color options
                  ],
                }}
                placeholder={t("compose_your_email_template")}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-sm me-3">
              {t("save_button")}
            </button>
            <button
              type={"button"}
              onClick={(e) => setTemplateType("")}
              className="btn btn-outline-primary btn-sm"
            >
              {t("cancel_button")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal className="fade" size="lg" show={templateType === "SMS"} centered>
        <Modal.Header className="bg-primary">
          <Modal.Title className="text-white">
            {t("set_sms_template")} {selectedEvent.status} :{" "}
            {selectedEvent.notificationType}{" "}
          </Modal.Title>
          <Button
            variant=""
            onClick={(e) => setTemplateType("")}
            className="btn-close"
          ></Button>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="row">
            <div className="col-xl-6">
              <FormInput
                label="sender_id"
                onchange={handleSubjectChange}
                value={selectedEvent.subject ?? ""}
                name="subject"
              />
            </div>
            <div className="col-xl-6">
              <FormInput
                onchange={handleStatusChange}
                value={selectedEvent.status ?? ""}
                label="status"
                type="select"
                name="status"
                data={status}
              />
            </div>
            <div className="col-xl-12">
              <div className="form-group mb-3">
                <label className="form-label" htmlFor={"val-template"}>
                  {" "}
                  {t("template")} <span className="text-danger">*</span>{" "}
                </label>
                <textarea
                  className="form-control form-control-sm"
                  id="message"
                  onChange={(e) =>
                    setSelectedEvent((prevState) => {
                      return {
                        ...prevState,
                        template: e.target.value,
                      };
                    })
                  }
                  value={selectedEvent.template ?? ""}
                  name="template"
                  rows="4"
                  cols="50"
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary btn-sm me-3"
              disabled={showLoading}
            >
              {t("save_button")}
            </button>
            <button
              type="button"
              onClick={(e) => setTemplateType("")}
              className="btn btn-outline-primary btn-sm"
            >
              {t("cancel_button")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NotificationTemplates;
