import {
  axiosAuthInstance,
  adminAxiosInstance,
} from "../../common/axiosInstance";
import { AUTH_FORM_SUBMIT_FAILED } from "../../common/store/actionTypes";
import swal from "sweetalert";

export function formPost(path, data, action) {
  if (action === "PUT") {
    return swal({
      title: "Are you sure?",
      text: "Update information!",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((willUpdate) => {
      if (willUpdate) {
        return adminAxiosInstance.put(`${path}`, data);
      }
    });
  } else {
    return adminAxiosInstance.post(`${path}`, data);
  }
}

function authFormPost(path, data, action) {
  return axiosAuthInstance.post(`${path}`, data);
}

export function AuthFormAction(path, data, action) {
  return (dispatch) => {
    authFormPost(path, data, action)
      .then((response) => {
        const resData = response.data.data;
        dispatch(submitSuccess(resData, action));
      })
      .catch((error) => {
        dispatch(submitFailed(error, AUTH_FORM_SUBMIT_FAILED));
      });
  };
}

export function FormAction(path, data, action) {
  return async (dispatch) => {
    try {
      const response = await formPost(path, data, action);
      const resData = response.data.data;
      dispatch(submitSuccess(resData, action));
    } catch (error) {
      dispatch(submitFailed(error, AUTH_FORM_SUBMIT_FAILED));
    }
  };
}

export function submitSuccess(data, type) {
  return {
    type: type,
    payload: data,
  };
}

export function submitFailed(data, type) {
  return {
    type: type,
    payload: data,
  };
}
