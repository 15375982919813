import React from "react";

const SearchInputFilter = ({
  filter,
  placeholder,
  ariaLabel,
  value,
  onChange,
}) => {
  return (
    <div className="all-option-filter-box">
      <input
        className="input search"
        type={filter === "createdAt" ? "date" : "text"}
        placeholder={placeholder}
        aria-label={ariaLabel}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInputFilter;
