import { useTranslation } from "react-i18next";
import React from "react";

const AppTranslation = () => {
  const { t } = useTranslation();

  const translateWithLineBreaks = (key, options) => {
    const translation = t(key, options);
    return translation.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < translation.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return {
    tb: translateWithLineBreaks,
  };
};

export default AppTranslation;
