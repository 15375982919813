import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import { ThemeContext } from "../../../context/ThemeContext";

export const DataTablePaginate = (props) => {
  const { tableLoading } = useContext(ThemeContext);

  const changePage = (newPage) => {
    props.setPage(newPage);
  };

  // Calculate the indices of the first and last items
  const firstItemIndex =
    props.totalElements === 0 ? 0 : props.currentPage * props.size + 1;
  const lastItemIndex = Math.min(
    (props.currentPage + 1) * props.size,
    props.totalElements,
  );

  // Function to go to the next page
  const nextPage = () => {
    if (props.currentPage < props.totalPages - 1) {
      props.setPage(props.currentPage + 1);
    }
  };

  const prevPage = () => {
    if (props.currentPage > 0) {
      props.setPage(props.currentPage - 1);
    }
  };

  return (
    <>
      <div className="card border-custom rounded-0">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            {props.tableTitle ? (
              <div className="tbl-caption">
                <h4 className="heading mb-0">{props.tableTitle}</h4>
                <div></div>
              </div>
            ) : (
              <></>
            )}

            <div
              id={props.tableTitle + "_tbl_wrapper"}
              className="dataTables_wrapper no-footer"
            >
              <table
                id="table"
                className="table ItemsCheckboxSec dataTable no-footer mb-0"
              >
                <thead>
                  <tr>
                    {props.headersTitle.map((item, index) => (
                      <th key={`${index}_${item.label}`} className="fw-bold">
                        {item.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                {!tableLoading && (
                  <tbody className="table-responsive scroll-body">
                    {props?.tableData?.map((item, index) => (
                      <tr key={`${index}_tr`}>
                        {item.map((val, i) => (
                          <td
                            className={`text-wrap ${i === 3 ? "fourth-column-style" : ""}`}
                            key={`${i}_${val}`}
                          >
                            {i === 3 ? (
                              <span
                                className={
                                  val === "PENDING"
                                    ? "bg-warning p-2 rounded-5 "
                                    : val === "PROCESS"
                                      ? "bg-info p-2 rounded-5 "
                                      : val === "REJECTED"
                                        ? "bg-danger p-2 rounded-5 "
                                        : ""
                                }
                              >
                                {val}
                              </span>
                            ) : (
                              val
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {tableLoading ? (
                <div className="d-flex justify-content-center align-items-center mt-1 mb-1">
                  <Spinners.TailSpin
                    height="30"
                    width="30"
                    ariaLabel="tail-spin-loading"
                    radius="4"
                    color="#000"
                  />
                </div>
              ) : (
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {firstItemIndex} to {lastItemIndex} of{" "}
                    {props.totalElements} entries
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example2_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled d-flex justify-content-center align-items-center"
                      to="#"
                      onClick={prevPage}
                    >
                      <i>
                        <RemixIcon.RiArrowDropLeftLine size={25} />
                      </i>
                    </Link>
                    <span>
                      {Array.from({ length: props.totalPages }, (_, i) => (
                        <Link
                          key={i}
                          to="#"
                          className={`btn-icon-xxs paginate_button  ${
                            props.currentPage === i ? "current" : ""
                          } `}
                          onClick={() => changePage(i)}
                        >
                          {i + 1}
                        </Link>
                      ))}
                    </span>
                    {/*<button type="button"  onClick={ () => executeGet(`/authz/getRolePaths/${r.id}`, dispatch, ROLES_PATHS, setEdithRole )} className="btn btn-primary btn-icon-xxs"><i className="fas fa-pencil-alt"></i></button>{" "}*/}

                    <Link
                      className="paginate_button next d-flex justify-content-center align-items-center"
                      to="#"
                      onClick={nextPage}
                    >
                      <i>
                        <RemixIcon.RiArrowDropRightLine size={25} />
                      </i>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DataTablePaginate.defaultProps = {
  headersTitle: [],
  tableData: [],
  tableTitle: "",
  sort: 5,
  // ...
};
