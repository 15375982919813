import { setModuleInLocalStorage } from "../services/authService";
import {
  ADMIN_LOGIN_CONFIRMED_ACTION,
  LOADING_TOGGLE_ACTION,
  LOGIN_FAILED_ACTION,
  MODULE_SELECTED,
} from "../../common/store/actionTypes";
import { baseUrlAdmin } from "../../common/constant";

export function moduleSelected(moduleId, path, navigate) {
  return (dispatch) => {
    setModuleInLocalStorage(moduleId);
    dispatch(moduleSelectedFunction(moduleId));
    navigate(baseUrlAdmin + path);
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function moduleSelectedFunction(moduleId) {
  return {
    type: MODULE_SELECTED,
    payload: moduleId,
  };
}

export function adminLoginConfirmedAction(data) {
  return {
    type: ADMIN_LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
