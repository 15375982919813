import { customerAxiosInstance } from "../../common/axiosInstance";
import { currentMonthFirstDate } from "../../common/constant";

export const getCustomerAccounts = () => {
  return customerAxiosInstance.get("accounts/listAll");
};

export const getCustomerAccountDetails = (accountId) => {
  return customerAxiosInstance.get(`accounts/getDetails/${accountId}`);
};

export const getAllCustomerAccountDetails = () => {
  return customerAxiosInstance.get(`accounts/getDetails`);
};

export const getRecentActivity = (
  accountId = "",
  startDate = currentMonthFirstDate(),
  endDate = new Date(),
  count = 10,
) => {
  return customerAxiosInstance.post("accounts/getRecentActivity", {
    accountId: accountId,
    openingDate: startDate,
    closingDate: endDate,
    count: count,
  });
};

export const findAccountByAccountId = (accountId) => {
  return customerAxiosInstance.get(`accounts/findAccount/${accountId}`);
};

export const getAccountHistory = (accountHistoryFilter) => {
  return customerAxiosInstance.post(
    "accounts/getStatement",
    accountHistoryFilter,
  );
};

export const statementReport = (accountHistoryFilter) => {
  return customerAxiosInstance.post(
    "accounts/statement-report?mimiType=application/pdf",
    accountHistoryFilter,
    { responseType: "blob" },
  );
};

export const accountReport = (accountId) => {
  return customerAxiosInstance.post(
    "accounts/account-report?mimiType=application/pdf",
    { accountId },
    {
      responseType: "blob",
    },
  );
};

export const eStatementReport = (body) => {
  return customerAxiosInstance.post(
    "accounts/e-statement-report?mimiType=application/pdf",
    body,
    {
      responseType: "blob",
    },
  );
};
export const saveBeneficiary = (
  benefactorAccountNumber,
  mobile,
  securityQuestions,
) => {
  return customerAxiosInstance.post(
    `accounts/save-beneficiary`,
    { benefactorAccountNumber: benefactorAccountNumber, mobile },
    {
      headers: {
        "X-User-Security": securityQuestions,
      },
    },
  );
};

export const saveMobilMoneyeBeneficiary = (body, securityQuestions) => {
  return customerAxiosInstance.post(`accounts/save-beneficiary`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};

export const listBeneficiary = () => {
  return customerAxiosInstance.get(`accounts/list-beneficiaries`);
};

export const deleteBeneficiary = (id, securityQuestions) => {
  return customerAxiosInstance.delete(`accounts/delete-beneficiary/${id}`, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};

export const listCustomerChecksByAccount = (accountId) => {
  return customerAxiosInstance.get(`accounts/check-series/${accountId}`);
};

export const saveCheckBookRequest = (data, securityQuestions) => {
  const body = {
    ...data,
    accountId: data.accountId,
    numberPages: data.numberPages,
    description: data.reason,
  };

  return customerAxiosInstance.post(`accounts/check-book-request`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};

export const listCheckBookRequest = () => {
  return customerAxiosInstance.get(`accounts/list-checkbook-request`);
};

export const deleteCheckBookRequest = (id, securityQuestions) => {
  return customerAxiosInstance.delete(
    `accounts/delete-checkbook-request/${id}`,
    {
      headers: {
        "X-User-Security": securityQuestions,
      },
    },
  );
};

export const searchPayable = (body) => {
  return customerAxiosInstance.post(`transactions/searchPayable`, body);
};

export const listStopCheck = (accountId) => {
  return customerAxiosInstance.get(`accounts/list-stop-checks/${accountId}`);
};

export const stopCheckPayment = (body, securityQuestions) => {
  return customerAxiosInstance.post(`accounts/stop-check-payment`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};

export const openCheckPayment = (body, securityQuestions) => {
  return customerAxiosInstance.post(`accounts/open-check-payment`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};

export const accountConfiguration = () => {
  return customerAxiosInstance.get(`config/list-client-config`);
};

export const getAccountConfiguration = (id) => {
  return customerAxiosInstance.get(`config/client-config/${id}`);
};

export const saveClientConfig = (body) => {
  return customerAxiosInstance.post(`config/save-client-config`, body);
};

export const updateClientConfig = (body) => {
  return customerAxiosInstance.patch(`config/update-client-config`, body);
};

export const generateReport = () => {
  return customerAxiosInstance.get(`/generate-report`);
};

export const getDetailsUser = (clientid) => {
  return customerAxiosInstance.get(`/config/client-details/${clientid}`);
};

export const updateProfile = (body, securityQuestions) => {
  return customerAxiosInstance.put(`/update-profile`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};

export const updatePrimaryAccount = (body, securityQuestions) => {
  return customerAxiosInstance.put(`/update-primary-account`, body, {
    headers: {
      "X-User-Security": securityQuestions,
    },
  });
};
