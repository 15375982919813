import React, { Component } from "react";
import classNames from "classnames";
import { AjaxSelect2, DataSelect } from "./SelectInput";

export class FormInput extends Component {
  render() {
    const classes = classNames(
      "form-control form-control-sm border-custom rounder-0",
      this.props.classes,
    );
    if (this.props.type === "ajax-select") {
      return (
        <>
          <div className="form-group mb-2">
            <label className="form-label" htmlFor={"val-" + this.props.name}>
              {" "}
              {this.props.label}{" "}
              {this.props.required ? (
                <span className="text-danger">*</span>
              ) : (
                " "
              )}{" "}
            </label>
            <AjaxSelect2
              onchange={this.props.onchange}
              id={this.props.id ?? this.props.name}
              name={this.props.name}
              url="get-data"
              required={this.props.required}
            />
          </div>
        </>
      );
    } else if (this.props.type === "select") {
      return (
        <>
          <div className="form-group rounder-0 mb-3">
            <label className="form-label" htmlFor={"val-" + this.props.name}>
              {" "}
              {this.props.label}{" "}
              {this.props.required ? (
                <span className="text-danger">*</span>
              ) : (
                " "
              )}{" "}
            </label>
            <DataSelect
              id={this.props.id ?? this.props.name}
              name={this.props.name}
              value={this.props.value}
              options={this.props.data}
              onChange={this.props.onchange ?? (() => {})}
              required={this.props.required}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="form-group rounder-0 mb-3">
            {this.props.label ? (
              <label className="form-label" htmlFor={"val-" + this.props.name}>
                {" "}
                {this.props.label}{" "}
                {this.props.required ? (
                  <span className="text-danger">*</span>
                ) : (
                  " "
                )}{" "}
              </label>
            ) : (
              <></>
            )}
            <input
              type={this.props.type}
              required={this.props.required}
              disabled={this.props.disable}
              className={classes}
              id={this.props.id ?? this.props.name}
              name={this.props.name}
              placeholder={this.props.placeholder}
              onChange={this.props.onchange}
              value={this.props.value}
              style={{ borderRadius: "0" }}
              autoComplete="off"
              autoFocus={this.props.autoFocus}
              // maxLength={this.props.maxLength}
              // key={ this.props.key}
            />
            {this.props.errorMsg && (
              <small className="text-danger fw-bold">
                {this.props.errorMsg}
              </small>
            )}
          </div>
        </>
      );
    }
  }
}

FormInput.defaultProps = {
  url: "",
  name: "name",
  type: "text",
  classes: " ",
  required: true,
  placeholder: " ",
  disable: false,
  autoFocus: false,
  autoComplete: "off",
  errorMsg: "",
  maxLength: "30",
  label: "",
  // key: '',
  options: [],
  data: [],
};
