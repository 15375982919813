/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import {
  getAllCustomerAccountDetails,
  getRecentActivity,
  getCustomerAccountDetails,
} from "../services/acountServices";
import { stringDate } from "../../common/constant";
import { ThemeContext } from "../../context/ThemeContext";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Transfer from "../../common/images/icon/transfer.png";
import Bills from "../../common/images/icon/bills.png";
import Beneficiary from "../../common/images/icon/beneficiary.png";
import Data from "../../common/images/icon/data.png";
import Utility from "../../common/images/icon/utility.png";
import Airtime from "../../common/images/icon/airtime.png";
import "../../styles/custom.css";
import * as RemixIcon from "react-icons/ri";
import ErrorManager from "../errors";
import { useTranslation } from "react-i18next";
import { formatAmount } from "../../utils/utilsFormattedAmount";

const pageObjectsLoaded = {
  details: false,
  activities: false,
};

const CustomerHome = () => {
  const { t } = useTranslation();

  const [accounts, setAccounts] = useState([]);
  const [clientActivity, setClientActivity] = useState([]);
  const [balanceVisibility, setBalanceVisibility] = useState([]);
  const [accountBalances, setAccountBalances] = useState({});
  const [ObjectsLoaded, setPageObjectsLoaded] = useState(pageObjectsLoaded);

  const { setShowLoading } = useContext(ThemeContext);
  const navigate = useNavigate();

  const hideNumber = (number) => {
    let prefixe = number.substring(0, 3);
    let x = "x".repeat(number.length - 3);
    return prefixe + x;
  };

  const getAccountDetails = () => {
    setShowLoading(true);
    return getAllCustomerAccountDetails()
      .then((v) => {
        const data = v.data.data;
        setAccounts(data);
        setBalanceVisibility(Array(data.length).fill(false));
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setPageObjectsLoaded((objec) => {
          return {
            ...objec,
            details: true,
          };
        });
      });
  };

  const listRecentActivity = () => {
    setShowLoading(true);
    getRecentActivity()
      .then((v) => {
        const data = v.data.data.sort((a, b) => b.serie - a.serie);
        setClientActivity(data);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setPageObjectsLoaded((objec) => {
          return {
            ...objec,
            activities: true,
          };
        });
      });
  };

  useEffect(() => {
    getAccountDetails();
    listRecentActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loaded = ObjectsLoaded.activities && ObjectsLoaded.details;
    setShowLoading(!loaded);
  }, [ObjectsLoaded]);

  useEffect(() => {
    if (accounts.length > 0 && clientActivity.length > 0) setShowLoading(false);
  }, [accounts, clientActivity]);

  const toggleBalanceVisibility = (index, accountID) => {
    const updatedVisibility = [...balanceVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setBalanceVisibility(updatedVisibility);

    if (updatedVisibility[index]) {
      // Check if RiEyeOffLine is active
      setShowLoading(true);
      getCustomerAccountDetails(accountID)
        .then((v) => {
          const balance = v.data.data.clearBalance;
          setAccountBalances((prev) => ({
            ...prev,
            [accountID]: balance ? balance : "0.0",
          }));
        })
        .catch((err) => {
          ErrorManager(err, navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-8">
          <div
            className="card shadow border-0 bg-white"
            style={{ borderRadius: "15px", position: "relative" }}
          >
            <div className="card-header pb-0">
              <h4 className="card-title fw-bold">
                {" "}
                {t("my_account")} | {accounts.length} {t("accounts_display")}
              </h4>
            </div>
            {accounts?.length === 1 ? (
              <div
                className="card-body d-flex justify-content-start border-0 ms-1 row gap-3 scroll-body"
                style={{ maxHeight: "300px" }}
              >
                {accounts.map((item, index) => (
                  <div
                    key={index}
                    className="card-content position-relative d-flex flex-column justify-content-center gap-3 p-3 col-xl-5 col-md-12 col-sm-12 h-auto border-custom"
                    style={{ background: "#f5f4f479", borderStyle: "dashed" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-bold">N° {item.accountID}</span>
                      <span className="fw-bold">
                        | {item.acstatus.toUpperCase()}
                      </span>
                    </div>
                    <div>
                      <span className="fw-bold">
                        {t("user_acc_type_title")}
                      </span>{" "}
                      {item.accountName.toUpperCase()}
                    </div>

                    <div className="balance-container d-flex align-items-center">
                      <span className="fw-bold">{t("user_balance")} </span>{" "}
                      <span className="ms-2">
                        {balanceVisibility[index]
                          ? accountBalances[item.accountID]
                            ? formatAmount(
                                accountBalances[item.accountID],
                                item.currency,
                              )
                            : t("laoding_balance")
                          : "xxxxxx"}
                      </span>
                      <span
                        className="ms-2"
                        onClick={() =>
                          toggleBalanceVisibility(index, item.accountID)
                        }
                      >
                        {balanceVisibility[index] ? (
                          <RemixIcon.RiEyeOffLine />
                        ) : (
                          <RemixIcon.RiEyeLine />
                        )}
                      </span>
                    </div>
                    <Dropdown className="position-relative text-end z-1">
                      <Dropdown.Toggle as="div" className="btn-link i-false">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                            stroke="#737B8B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                            stroke="#737B8B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                            stroke="#737B8B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          boxShadow: "none",
                          background: "#fff",
                        }}
                        className="shadow"
                        align="end"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            navigate(
                              `/account?view=account-details&a=${item.accountID}`,
                            )
                          }
                        >
                          {t("view_details")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            navigate(
                              `/account?view=account-statement&a=${item.accountID}`,
                            )
                          }
                        >
                          {t("view_statement")}{" "}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className="card-body d-flex justify-content-around border-0 row gap-3 scroll-body"
                style={{ maxHeight: "300px" }}
              >
                {accounts.map((item, index) => (
                  <div
                    key={index}
                    className="card-content position-relative d-flex flex-grow-1 flex-column justify-content-center gap-3 p-3 col-xl-5 col-md-12 col-sm-12 border-custom"
                    style={{
                      background: "#f5f4f479",
                      borderStyle: "dashed",
                      height: "150px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="fw-bold">N° {item.accountID}</span>
                      <span className="fw-bold">
                        | {item.acstatus.toUpperCase()}
                      </span>
                    </div>
                    <div>
                      <span className="fw-bold">
                        {t("user_acc_type_title")}
                      </span>{" "}
                      {item.accountName.toUpperCase()}
                    </div>

                    <div className="balance-container d-flex align-items-center">
                      <span className="fw-bold">{t("user_balance")} </span>{" "}
                      <span className="ms-2">
                        {balanceVisibility[index]
                          ? accountBalances[item.accountID]
                            ? formatAmount(
                                accountBalances[item.accountID],
                                "XAF",
                              )
                            : t("laoding_balance")
                          : "xxxxxx"}
                      </span>
                      <span
                        className="ms-2"
                        onClick={() =>
                          toggleBalanceVisibility(index, item.accountID)
                        }
                      >
                        {balanceVisibility[index] ? (
                          <RemixIcon.RiEyeOffLine />
                        ) : (
                          <RemixIcon.RiEyeLine />
                        )}
                      </span>
                    </div>
                    <Dropdown
                      className="z-1"
                      style={{ position: "absolute", right: "10px", bottom: 0 }}
                    >
                      <Dropdown.Toggle as="div" className="btn-link i-false">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                            stroke="#737B8B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z"
                            stroke="#737B8B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                          <path
                            d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z"
                            stroke="#737B8B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          boxShadow: "none",
                          background: "#fff",
                        }}
                        className="shadow"
                        align="end"
                      >
                        <Dropdown.Item
                          onClick={() =>
                            navigate(
                              `/account?view=account-details&a=${item.accountID}`,
                            )
                          }
                        >
                          {t("view_details")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            navigate(
                              `/account?view=account-statement&a=${item.accountID}`,
                            )
                          }
                        >
                          {t("view_statement")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-4">
          <div
            className="card shadow border-0 bg-white"
            style={{ borderRadius: "15px", position: "relative" }}
          >
            <div className="card-header border-0 pb-0">
              <h4 className="card-title fw-bold">{t("quick_access")}</h4>
            </div>
            <div className="card-body border-0">
              <div className="quick-links row">
                <li className="col-3">
                  <NavLink to="/transfers">
                    <img src={Transfer} alt="" width="50" height="50" />
                    <p>{t("quick_transfer")}</p>
                  </NavLink>
                </li>
                <li className="col-3">
                  <NavLink to="/account/beneficiary">
                    <img src={Beneficiary} alt="" width="50" height="50" />
                    <p>{t("quick_beneficiary")}</p>
                  </NavLink>
                </li>
                <li className="col-3">
                  <NavLink to="/account?view=account-statement">
                    <img src={Bills} alt="" width="50" height="50" />
                    <p>{t("quick_statement")}</p>
                  </NavLink>
                </li>
                <li className="col-3">
                  <NavLink to="/client-services?view=bills_payment">
                    <img src={Airtime} alt="" width="50" height="50" />
                    <p>{t("quick_airtime")}</p>
                  </NavLink>
                </li>
                <li className="col-3">
                  <NavLink>
                    <img src={Data} alt="" width="50" height="50" />
                    <p>{t("quick_data")}</p>
                  </NavLink>
                </li>
                <li className="col-3">
                  <NavLink to="/notification">
                    <img src={Utility} alt="" width="50" height="50" />
                    <p>{t("quick_other")}</p>
                  </NavLink>
                </li>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div
            className="card shadow border-0"
            style={{ borderRadius: "15px" }}
          >
            <div className="card-header border-0 pb-0 d-flex align-content-between">
              <h4 className="card-title fw-bold">{t("recent_activities")} </h4>
              {/*<FormInput type="select" required={false}/>*/}
            </div>
            <div className="card-body border-0">
              <div className="scroll-body" style={{ maxHeight: "250px" }}>
                <table className="table" style={{ maxHeight: "200px" }}>
                  <thead>
                    <tr>
                      <th className="fw-bold">{t("activity_day")}</th>
                      <th className="fw-bold hide-on-mobile">
                        {t("activity_descr")}
                      </th>
                      <th className="fw-bold">{t("activity_acc_name")}</th>
                      <th className="fw-bold text-end">
                        {t("activity_balance")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!ObjectsLoaded.activities ? (
                      <tr>
                        <td colSpan="4" className="text-center p-0">
                          {t("activities_laoding")}
                        </td>
                      </tr>
                    ) : clientActivity.length > 0 ? (
                      clientActivity.map((item, index) => (
                        <tr key={index}>
                          <td style={{ fontSize: "14px" }}>
                            {stringDate(item.operationDate)}
                          </td>
                          <td
                            className="hide-on-mobile"
                            style={{
                              fontSize: "14px",
                              minWidth: "250px",
                              overflowWrap: "break-word",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                            }}
                          >
                            {item.description}
                          </td>
                          <td>
                            <span className="show-on-mobile">
                              {item.accountName}
                            </span>
                            <span className="hide-on-mobile">
                              {hideNumber(item.accountID) +
                                " " +
                                item.accountName}
                            </span>
                          </td>
                          <td className="text-end">
                            <span
                              className={`p-1 badge ${item.credit ? " bg-success p-1" : "bg-danger"}`}
                              style={{ fontSize: "14px" }}
                            >
                              {item.credit > 0
                                ? formatAmount(item.credit, item.currency) +
                                  " Cr"
                                : formatAmount(item.debit, "XAF") + " Dr"}
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center p-0">
                          {t("no_activity")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer d-flex p-4 text-white">
              <span
                className="btn btn-outline-primary btn-sm p-2 rounded-1"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/account?view=account-statement")}
              >
                {t("activity_downlaod")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerHome;
