import React from "react";

import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import TopLoader from "../../components/TopLoader";

const JobieNav = () => {
  return (
    <>
      <NavHader />
      <Header />
      <TopLoader />
      <SideBar />
    </>
  );
};

export default JobieNav;
