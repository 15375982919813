import ConfigurationsDashboard from "../appAdmin/pages/dashboard/Configurations";
import SecurityDashBoard from "../appAdmin/pages/dashboard/SecurityDashboard";
import SubscriptionDashBoard from "../appAdmin/pages/dashboard/Subscription";
import AccountRequest from "../appAdmin/pages/admin/AccountRequest";
import DetailsAccountRequest from "../appAdmin/pages/admin/DetailsAccountRequest";
import InstitutionConfig from "../appAdmin/pages/admin/InstitutionConfig";
import NotificationTemplates from "../appAdmin/pages/admin/NotificationTemplates";
import SecurityQuestions from "../appAdmin/pages/admin/SecurityQuestions";
import CustomerSubscriptions from "../appAdmin/pages/admin/Subscriptions";
import TermsAndConditions from "../appAdmin/pages/admin/TermsAndConditions";
import MenuManagement from "../appAdmin/pages/athz/MenuManagement";
import UserManagement from "../appAdmin/pages/athz/UserManagement";
import UserRoles from "../appAdmin/pages/athz/UserRoles";
import TransactionMessages from "../appAdmin/pages/admin/TransactionMessages";

const AdminRoute = [
  { url: `security-dashboard`, component: <SecurityDashBoard /> },
  { url: "subscriptions-dashboard", component: <SubscriptionDashBoard /> },
  { url: "configuration-dashboard", component: <ConfigurationsDashboard /> },
  { url: "notification-template", component: <NotificationTemplates /> },
  { url: "status-messages", component: <TransactionMessages /> },

  { url: "security-questions", component: <SecurityQuestions /> },

  { url: "customer-subscription", component: <CustomerSubscriptions /> },

  { url: "configurations", component: <InstitutionConfig /> },

  { url: "menu-management", component: <MenuManagement /> },
  { url: "security-roles", component: <UserRoles /> },
  { url: "user-management", component: <UserManagement /> },
  { url: "account-register", component: <AccountRequest /> },
  { url: "list-account-register/:uuid", component: <DetailsAccountRequest /> },
  { url: "terms-conditions", component: <TermsAndConditions /> },
];

export default AdminRoute;
