import React from "react";
import { Helmet } from "react-helmet";
import { META_TITLE, META_DESCRIPTION, META_IMAGE, META_URL } from "./config";

const MetaData = () => (
  <Helmet>
    <title>{META_TITLE}</title>
    <meta name="description" content={META_DESCRIPTION} />
    <meta property="og:title" content={META_TITLE} />
    <meta property="og:description" content={META_DESCRIPTION} />
    <meta property="og:image" content={META_IMAGE} />
    <meta property="og:url" content={META_URL} />
  </Helmet>
);

export default MetaData;
