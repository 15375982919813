/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { ThemeContext } from "../../context/ThemeContext";
import { connect } from "react-redux";
import { getVerificationQuestions } from "../services/clientAuthService";
import ErrorManager from "../errors";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import PasswordInput from "../../common/jsx/components/PasswordInput";

const SecurityQuestionModal = (props) => {
  const [securityQuestions, setSecurityQuestions] = useState();
  const [questionPairs, setQuestionPairs] = useState([]);
  const {
    verifySecureQuestions,
    setVerifySecureQuestions,
    verificationCallBack,
    setShowLoading,
    showLoading,
  } = useContext(ThemeContext);
  const [trialsLeft, setTrialsLeft] = useState(0);
  const [maxTrails, setMaxTrails] = useState(0);
  const [trialsClass, setTrialsClass] = useState("");
  const [errorMsg, setErrorMsg] = useState({});

  const navigate = useNavigate();

  const getPageData = () => {
    setShowLoading(true);
    return getVerificationQuestions()
      .then((v) => {
        const data = v.data.data;
        setSecurityQuestions(data.verificationObject);
        setMaxTrails(data.maxTrials);
        setTrialsLeft(data.trials);
        return data.verificationObject;
      })
      .catch((err) => {
        ErrorManager(err, navigate);
        setVerifySecureQuestions(false);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (verifySecureQuestions) {
      setShowLoading(true);
      getPageData()
        .then((v) => {
          setQuestionPairs(
            v.map((q) => ({
              securityQuestionId: q.id,
              securityAns: "",
            })),
          );
        })
        .catch((err) => {
          ErrorManager(err, navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      setQuestionPairs([]);
      setSecurityQuestions(undefined);
    }
  }, [verifySecureQuestions, navigate]);

  const answerChange = (e) => {
    const index = questionPairs.findIndex(
      (v) => Number(v.securityQuestionId) === Number(e.target.name),
    );
    questionPairs[index].securityAns = e.target.value;
    setQuestionPairs([...questionPairs]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoading(true);
    verificationCallBack(JSON.stringify(questionPairs))
      .then(() => {
        setVerifySecureQuestions(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          const object = err.response.data.object;
          setMaxTrails(object.maxTrials);
          setTrialsLeft(object.trials);
          setErrorMsg({
            message: err.response.data.message,
          });
        } else {
          setVerifySecureQuestions(false);
          ErrorManager(err, navigate);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (trialsLeft === 1) {
      setTrialsClass("text-danger");
    } else if (trialsLeft < maxTrails / 2) {
      setTrialsClass("text-warning");
    } else {
      setTrialsClass("");
    }

    if (!verifySecureQuestions) {
      setErrorMsg({});
    }
  }, [trialsLeft, verifySecureQuestions]);

  return (
    <Modal className="fade" size="md" show={verifySecureQuestions} centered>
      <Modal.Header className="bg-primary rounded-0">
        <Modal.Title className="text-white">
          {t("verify_security_questions")}
        </Modal.Title>
        <Button
          onClick={() => setVerifySecureQuestions(false)}
          variant=""
          className="btn-close"
        ></Button>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <h5 className="mb-3 fs-5">{t("sec_quest_auth_info")}</h5>
          <div>
            <div>
              {securityQuestions
                ? securityQuestions.map((q, index) => (
                    <div key={index} className="mb-3">
                      <label
                        htmlFor={`question-${q.id}`}
                        className="form-label"
                      >
                        {q.question}
                      </label>
                      <div className=" position-relative">
                        <PasswordInput
                          id={`question-${q.id}`}
                          value={questionPairs[index].securityAns}
                          onChange={answerChange}
                          placeholder={t("login_userpass")}
                          name={q.id}
                          showPasswordToggle={true}
                          className="form-control"
                          autoFocus={index === 0}
                          autoComplete="off"
                        />
                      </div>
                      {/* <input
                    className="form-control masked-input"
                    id={`question-${q.id}`}
                    name={q.id}
                    type="text"
                    onChange={answerChange}
                    autoFocus={index === 0}
                    autoComplete='off'
                  /> */}
                      {securityQuestions.length - 1 === index &&
                        errorMsg.message && (
                          <div className="text-danger mt-2">
                            {errorMsg.message}
                          </div>
                        )}
                    </div>
                  ))
                : null}
            </div>
          </div>
          <h4 className={trialsClass}>
            {t("verify_trials")} <span>{trialsLeft}</span>
          </h4>
          <div className="d-flex gap-2 justify-content-end">
            <button
              type="button"
              onClick={() => setVerifySecureQuestions(false)}
              className="btn btn-outline-primary btn-sm"
            >
              {t("cancel_button")}
            </button>
            <button
              className="btn btn-primary btn-sm me-3"
              disabled={showLoading}
            >
              {t("validate_button")}
            </button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    userDto: state.auth.userDao,
  };
};
export default connect(mapStateToProps)(SecurityQuestionModal);
