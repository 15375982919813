/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getCustomerAccounts,
  listStopCheck,
  openCheckPayment,
} from "../../services/acountServices";
import { Modal } from "react-bootstrap";
import { useQuery } from "../../../common/services";
import { FormInput } from "../../../common/jsx/components/FormInput";
import Benefit from "../../../common/images/svg/benefit.svg";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { stringDate } from "../../../common/constant";
import swal from "sweetalert";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
import ErrorManager from "../../errors";
import { useTranslation } from "react-i18next";

const UnblockCheck = () => {
  const { t } = useTranslation();
  const [checkBookList, setCheckbookList] = useState([]);
  // const [accountList, setAccountsList] = useState([])
  const [optionAccounts, setOptionAccounts] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [hasCurrentAccount, setHasCurrentAccount] = useState(true);
  // const [showPendingModal, setShowPendingModal] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);

  const query = useQuery();
  const Navigate = useNavigate();

  const checkRequestTableHeaders = [
    { label: t("date_block") },
    { label: t("check_number") },
    { label: t("period_(day)") },
    { label: t("action") },
  ];

  const handleAccountId = (e) => {
    setAccountId(e.value);
    if (query.get("ac")) {
      Navigate("/account/unblock-check");
    }
  };

  const handleUnblock = (accountId, checkNumber) => {
    const body = {
      account: accountId,
      checkNumber: checkNumber,
    };

    const unblockCheck = (securityQuestions) => {
      setShowLoading(true);
      return openCheckPayment(body, securityQuestions)
        .then((v) => {
          getPageData();
        })
        .catch((err) => {
          getPageData();
          return Promise.reject(err);
        })
        .finally(() => {
          setShowLoading(false);
        });
    };

    return swal({
      title: t("are_you_sure"),
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willUnblock) => {
        if (willUnblock) {
          setVerifyCallback(unblockCheck);
          setVerifySecureQuestions(true);
        }
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const getListAccount = () => {
    setShowLoading(true);
    // setShowPendingModal(true);

    getCustomerAccounts()
      .then((v) => {
        const data = v.data.data;
        // setAccountsList(data)
        const currentAccounts = data.filter(
          (v) => v.productType.trim() === "CURRENT",
        );
        if (currentAccounts.length === 0) {
          setHasCurrentAccount(false);
        } else {
          setOptionAccounts(
            currentAccounts.map((act) => ({
              value: act.accountID,
              label: `${act.accountID} -${act.accountName}`,
            })),
          );
          setHasCurrentAccount(true);
        }
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        // setShowPendingModal(false);
        setInitialLoad(false);
      });
  };

  const getPageData = () => {
    const defaultAccount = query.get("ac") ? query.get("ac") : accountId;
    if (defaultAccount) {
      setShowLoading(true);
      setAccountId(defaultAccount);
      listStopCheck(defaultAccount)
        .then((res) => {
          setCheckbookList(
            res.data.data.map((b) => [
              stringDate(b.oppositionDate),
              b.checkNumber,
              b.blockPeriod,
              <div className="action-button">
                <button
                  type="button"
                  onClick={() => handleUnblock(b.account, b.checkNumber)}
                  className="btn btn-outline-primary btn-sm"
                >
                  {t("unblock_button")}
                  <RemixIcon.RiUnpinLine size={15} />
                </button>
              </div>,
            ]),
          );
        })
        .catch((err) => {
          ErrorManager(err, Navigate);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  // const closeCheckBookPage = () => {
  //     Navigate('/home')
  // }

  useEffect(() => {
    getListAccount();
  }, []);

  useEffect(() => {
    getPageData();
  }, [accountId]);

  const handleCloseModal = () => {
    // setShowPendingModal(false)
  };

  return (
    <>
      <div className="container-fluid">
        <h4 className="fw-bold">{t("unblock_check_payment")}</h4>
        <div className="card bg-white border-0 shadow min-vh-100">
          <div className="card-body border-0">
            <div className="row ">
              <div className="col-xl-8 border-0">
                <div className="row d-flex align-items-center justify-content-between">
                  <div className="col-xl-8">
                    <FormInput
                      name="accountId"
                      label={t("select_account")}
                      data={optionAccounts}
                      value={accountId}
                      type="select"
                      onchange={handleAccountId}
                    />
                  </div>
                  <div className="col-xl-4 text-end">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => Navigate("/account/stop-check")}
                    >
                      <RemixIcon.RiAddLine size="15" /> {t("block_new_check")}
                    </button>
                  </div>
                </div>
                <DataTable
                  headersTitle={checkRequestTableHeaders}
                  tableData={checkBookList}
                />
              </div>

              <div className="col-xl-4">
                <div className="card pb-0 border-0">
                  <div className="card-body rounded-0">
                    <div className="media me-2 pb-5 d-flex justify-content-center">
                      <img
                        width="100"
                        height="100"
                        src={Benefit}
                        alt="benefit"
                      />
                    </div>
                    <div
                      style={{ height: "300px" }}
                      id="DZ_W_Todo3"
                      className="widget-media dz-scroll height270  ps--active-y"
                    >
                      <ul className="timeline">
                        <li>
                          {/* <h5 className="mb-2 fs-5 d-flex justify-content-center">
                                                    {t('change_pass_educate')}
                                                    </h5>
                                                    <h2 className="mb-2 fs-5 text-warning">
                                                    {t('change_pass_force')}
                                                    </h2> */}
                          <p className="pl-5 fs-5 d-flex justify-content-center text-center">
                            {t("check_unblock_check_body")}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        size="sm"
        show={initialLoad || (!showLoading && !hasCurrentAccount)}
        centered
      >
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
          {!showLoading && !hasCurrentAccount && (
            <div className="text-center">
              <h5 className="mb-2 fs-5 text-center">
                {t("Sorry_you_do_not_have_a_current_accounts")}
              </h5>
              <button
                className="btn btn-warning btn-sm me-3"
                onClick={handleCloseModal}
              >
                {t("ok_button")}
              </button>
            </div>
          )}

          {showLoading && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Spinners.TailSpin
                height="40"
                width="40"
                ariaLabel="tail-spin-loading"
                radius="1"
                color="#000"
              />
              <p>Recherche des comptes cheques</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UnblockCheck;
