import React, { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FlagEN from "../../images/flag/en.png";
import FlagFR from "../../images/flag/fr.png";
import { ThemeContext } from "../../../context/ThemeContext";
import { RiArrowDropUpLine } from "react-icons/ri";

const Footer = () => {
  const { t } = useTranslation();
  const { lang, setLang } = useContext(ThemeContext);

  const changeLanguage = (lang) => {
    setLang(lang);
  };
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);

  const d = new Date();
  return (
    <div
      className="footer position-relative bottom-0 w-100 d-flex align-items-center justify-content-center"
      style={{ marginTop: "0", position: "absolute", bottom: "0" }}
    >
      <div className="copyright">
        <p className="text-white">
          {t("copy_right")} {t("dev_by")}{" "}
          {/* <a href="https://betterplanning.io/" target="_blank" rel="noreferrer"> */}
          {t("better_plnx")}
          {/* </a> */} {d.getFullYear()}
        </p>
      </div>
      <Dropdown
        className="footer-lang"
        show={langDropdownOpen}
        onClick={() => setLangDropdownOpen(!langDropdownOpen)}
      >
        <Dropdown.Toggle
          as="div"
          className="btn-link i-false text-white d-flex align-items-center gap-0"
        >
          {lang === "en" ? (
            <img src={FlagEN} alt="FlagEN" width="20px" height="20px" />
          ) : (
            <img src={FlagFR} alt="FlagFR" width="20px" height="20px" />
          )}
          <RiArrowDropUpLine size={15} />
        </Dropdown.Toggle>
        <Dropdown.Menu
          style={{
            position: "absolute",
            right: 0,
            boxShadow: "none",
            background: "#fff",
            top: "-90px",
          }}
          className="shadow"
          align="end"
        >
          <div className="card border-0 mb-0">
            <div
              className="item-lang card-footer py-2 text-nowrap d-flex gap-2"
              onClick={() => changeLanguage("fr")}
            >
              <img src={FlagFR} alt="FlagFR" />
              {t("french_lang")}
            </div>
            <div
              className="item-lang card-footer py-2 text-nowrap d-flex gap-2"
              onClick={() => changeLanguage("en")}
            >
              <img src={FlagEN} alt="FlagEN" />
              {t("english_lang")}
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Footer;
