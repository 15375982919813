import CustomerHome from "../appClient/pages/CustomerHome";
import AddBeneficiary from "../appClient/pages/accounts/AddBeneficiary";
import Beneficiary from "../appClient/pages/accounts/Beneficiary";
import CheckBookRequest from "../appClient/pages/accounts/CheckBookRequest";
import CheckBookRequestStatus from "../appClient/pages/accounts/CheckBookRequestStatus";
import StopCheck from "../appClient/pages/accounts/StopCheck";
import UnblockCheck from "../appClient/pages/accounts/UnblockCheck";
import AllModals from "../appClient/pages/auth/Allmodals";
import ChangePassword from "../appClient/pages/auth/ChangePassword";
import CustomNotification from "../appClient/pages/othersServices/CustomNotification";
import Notification from "../appClient/pages/othersServices/Notification";
import ClientServices from "../appClient/pages/transactions/ClientServices";
import BeforeProcess from "../appClient/pages/transactions/BeforeProcess";
import DiasporaDeposit from "../appClient/pages/transactions/DiasporaDeposit";
import AutOtpPage from "../common/jsx/pages/AutOtpPage";
import ProfileTab from "../appClient/pages/auth/Profile";
import AccountDetailsPage from "../appClient/pages/accounts/AccountDetails";
import TransfersPage from "../appClient/pages/transactions/Transfers";
import MobileMoneyPage from "../appClient/pages/transactions/MobileMoney";
import AirTimePurchase from "../appClient/pages/transactions/AirType";
import SecurityQuestion from "../appClient/pages/othersServices/SecurityQuestion";

const ClientRoutes = [
  { url: "/", component: <CustomerHome /> },
  { url: "home", component: <CustomerHome /> },

  { url: "view-profile", component: <ProfileTab /> },

  { url: "account", component: <AccountDetailsPage /> },
  { url: "transfers", component: <TransfersPage /> },
  { url: "diaspora-deposit", component: <DiasporaDeposit /> },
  { url: "mobile-money", component: <MobileMoneyPage /> },
  { url: "air-time", component: <AirTimePurchase /> },
  { url: "account/beneficiary", component: <Beneficiary /> },
  { url: "otp-verification", component: <AutOtpPage /> },
  { url: "client-services", component: <ClientServices /> },
  { url: "client-services/before-process", component: <BeforeProcess /> },
  { url: "security-questions", component: <SecurityQuestion /> },

  { url: "modal-test", component: <AllModals /> },
  { url: "add-beneficiary", component: <AddBeneficiary /> },
  { url: "change-password", component: <ChangePassword /> },

  { url: "account/check-book-request", component: <CheckBookRequest /> },
  { url: "account/stop-check", component: <StopCheck /> },
  { url: "account/unblock-check", component: <UnblockCheck /> },
  {
    url: "account/check-book-request-status",
    component: <CheckBookRequestStatus />,
  },

  { url: "notification", component: <Notification /> },
  { url: "notification/config", component: <CustomNotification /> },
];

export default ClientRoutes;
