import React, { useState, useEffect, useContext } from "react";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { ThemeContext } from "../../../context/ThemeContext";
import { DataTablePaginate } from "../../../common/jsx/components/DataTablePaginate";
import { RiEyeLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { listClientRequest } from "../../services/subscriptionService";
import SelectOptionFilter from "../../../common/jsx/components/SelectOptionFilter";
import SearchInputFilter from "../../../common/jsx/components/SearchInputFilter";
import { filterType, sortOption } from "../../../data/optionFilter";
import ErrorManager from "../../../appClient/errors";
import { baseUrlAdmin } from "../../../common/constant";
import { t } from "i18next";

const AccountRequest = () => {
  const Navigate = useNavigate();
  const { setShowLoading, setTableLoading } = useContext(ThemeContext);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [size] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("PENDING");
  const [order, setOrder] = useState("asc");
  const [filter, setFilter] = useState("name");
  const [search, setSearch] = useState("");

  // GET TYPE VALUE
  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  // GET TYPE VALUE
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  // GET ORDER VALUE
  const handleOrderChange = (e) => {
    setOrder(e.target.value);
  };

  // GET FILTER VALUE
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // GET RESEARCH VALUE
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const tableHeader = [
    { label: t("firstname") },
    { label: t("lastname") },
    { label: t("country") },
    { label: t("dob") },
    { label: t("gender") },
    { label: t("profession") },
    { label: t("email") },
    { label: t("address1") },
    { label: t("phone_number") },
    { label: t("actions") },
  ];

  // FILTER SELECT TAG DATA
  const filterOptions = [
    { value: "name", label: t("firstname") },
    { value: "surname", label: t("lastname") },
    { value: "countryOfResidence", label: t("country") },
    { value: "birthDate", label: t("dob") },
    { value: "gender", label: t("gender") },
    { value: "profession", label: t("profession") },
    { value: "email", label: t("email") },
    { value: "phoneNumber1 ", label: t("phone_number") },
  ];

  const statusType = [
    { value: "PENDING", label: t("pending") },
    { value: "", label: t("all") },
    { value: "REJECTED", label: t("rejected") },
    { value: "DOWNLOADED", label: t("downloaded") },
  ];

  const getPageData = () => {
    setShowLoading(true);
    setTableLoading(true);
    listClientRequest(order, filter, search, type, status, page, size)
      .then((res) => {
        setTotalElements(res.data?.data?.totalElements);
        setTotalPages(res.data?.data?.totalPages);
        setTableData(
          res.data?.data?.data.map((d) => [
            d.name,
            d.surname,
            d.countryOfResidence,
            d.birthDate,
            d.gender,
            d.profession,
            d.email,
            d.address1,
            d.phoneNumber1,
            <div>
              <button
                type="submit"
                className="d-flex align-items-center gap-2 btn btn-outline-primary btn-sm me-1 text-nowrap"
                onClick={() =>
                  Navigate(`${baseUrlAdmin}/list-account-register/${d.uuid}`)
                }
              >
                <RiEyeLine /> {t("details_button")}
              </button>
            </div>,
          ]),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    setPage(0);
    getPageData();
    setShowLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, filter, search, type, status, size]);

  useEffect(() => {
    getPageData();
    setShowLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <MainPageTitle
        mainTitle="Account request"
        pageTitle="account request"
        parentTitle="Subscriptions"
      />
      <div className="container-fluid">
        <div className="row p-3 pt-0">
          <div className="card rounded-0 shadow card-bx m-b30">
            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center p-0 mb-3">
              <h4 className="heading mb-0 mt-3">{t("all_subscriptions")}</h4>
            </div>
            <div className="option-filter-table">
              <SelectOptionFilter
                label={t("status")}
                id="status"
                name={status}
                value={status}
                onChange={handleStatusChange}
                options={statusType}
              />

              <SelectOptionFilter
                label={t("customer_type")}
                id="type"
                name={type}
                value={type}
                onChange={handleTypeChange}
                options={filterType}
              />

              <SelectOptionFilter
                label={t("sort_by")}
                id="sort"
                name={order}
                value={order}
                onChange={handleOrderChange}
                options={sortOption}
              />

              <SelectOptionFilter
                label={t("filter_by")}
                id="filter"
                name={filter}
                value={filter}
                onChange={handleFilterChange}
                options={filterOptions}
              />

              <SearchInputFilter
                filter={filter}
                placeholder={t("type_here")}
                ariaLabel="Search"
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            <DataTablePaginate
              headersTitle={tableHeader}
              tableData={tableData}
              totalPages={totalPages}
              currentPage={page}
              size={size}
              totalElements={totalElements}
              setPage={setPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountRequest;
