import React, { useContext, useState } from "react";
import { Row, Col, Card, Tab, Nav, Table, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorManager from "../../../appClient/errors";
import { ThemeContext } from "../../../context/ThemeContext";
import Swal from "sweetalert2";
import "../../../styles/profile.css";
import { updatePassword } from "../../services/userService";
import Highlight from "react-highlight";
import { toast } from "react-toastify";

const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const [showModalPassword, setShowModalPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [data, setData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  const handleSubmitDetailsProfile = (e) => {
    e.preventDefault();
    if (oldPassword !== "" && newPassword !== "" && confirmPassword !== "") {
      if (!validatePassword(newPassword)) {
        setErrorMsg("password_requirements_details");
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.warn(t("password_mismatch"), { position: "top-right" });
        return;
      }
      setShowLoading(true);
      const body = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };

      updatePassword(body)
        .then((res) => {
          toast.success(t("changes_saved_successfully"), {
            position: "top-right",
          });
          setShowModalPassword(false);
          setErrorMsg("");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setData({ ...data });
        })
        .catch((err) => {
          ErrorManager(err, navigate);
        })
        .finally(() => {
          setShowLoading(false);
          setErrorMsg(""); // Clear error message on successful update
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: t("required_fields"),
        text: t("please_fill_all_fields"),
      });
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setErrorMsg(""); // Clear error message on input change
  };

  const isDisable =
    oldPassword === "" ||
    newPassword === "" ||
    confirmPassword === "" ||
    showLoading;

  const tabData = [
    {
      name: t("user_profile_tab"),
      key: "user_profile_tab",
      content: (
        <div className="row">
          <div className="col-xl-12 col-sm-12 overflow-scroll">
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th className="fw-bold">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fw-bold">{t("user_name")} : </td>
                  <td>{props.userDao.username}</td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("user_role")} : </td>
                  <td>{props.userDao.name}</td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("password")} : </td>
                  <td>
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => setShowModalPassword(true)}
                    >
                      {t("db_change_password")}
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <Row>
        <Col xl={12}>
          <Tab.Container defaultActiveKey="Preview">
            <Card name="custom-tab" className="dz-card border-0 shadow">
              <Tab.Content>
                <Tab.Pane eventKey="Preview">
                  <Card.Body>
                    <div className="custom-tab-1">
                      <Tab.Container
                        defaultActiveKey={tabData[0].key.toLowerCase()}
                      >
                        <Nav as="ul" className="nav-tabs">
                          {tabData.map((data, i) => (
                            <Nav.Item as="li" key={i}>
                              <Nav.Link eventKey={data.key.toLowerCase()}>
                                <i className={`la la-${data.icon} me-2`} />
                                {data.name}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                        <Tab.Content className="pt-4">
                          {tabData.map((data, i) => (
                            <Tab.Pane eventKey={data.key.toLowerCase()} key={i}>
                              {data.content}
                            </Tab.Pane>
                          ))}
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="Code">
                  <div className="card-body pt-0 p-0 code-area">
                    <pre className="mb-0">
                      <code className="language-html">
                        <Highlight></Highlight>
                      </code>
                    </pre>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <Modal className="rounded-0" size="md" show={showModalPassword} centered>
        <Modal.Body className="row rounded-0">
          <div className="col-xl-12 col-sm-12 overflow-scroll">
            <Table>
              <thead>
                <tr>
                  <th colSpan={2} className="text-center fw-bold">
                    {t("db_change_password")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fw-bold">{t("enter_current_password")} : </td>
                  <td>
                    <input
                      type="password"
                      className="form-control-profil"
                      value={oldPassword}
                      onChange={handleInputChange(setOldPassword)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("enter_new_password")} : </td>
                  <td>
                    <input
                      type="password"
                      className="form-control-profil"
                      value={newPassword}
                      onChange={handleInputChange(setNewPassword)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("confirm_password")} : </td>
                  <td>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={handleInputChange(setConfirmPassword)}
                      className="form-control-profil"
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      disabled={isDisable}
                      onClick={handleSubmitDetailsProfile}
                    >
                      {t("update_button")}
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {errorMsg && (
              <p
                className="text-danger text-center"
                style={{ wordWrap: "break-word" }}
                colSpan={2}
              >
                {t(errorMsg)}
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="rounded-0">
          <button
            className="btn btn-sm btn-danger"
            onClick={() => setShowModalPassword(false)}
          >
            {t("cancel_button")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDao: state.auth.userDao,
  };
};

export default connect(mapStateToProps)(Profile);
