import axios from "axios";
import { store } from "./store";
import {
  internetBankServerAddress,
  mobileBankServerAddress,
  userDetailsKey,
} from "./constant";
// import { LOGOUT_ACTION } from "./store/actionTypes";
import { Logout } from "./services";

export const customerMobileBankAxiosInstance = axios.create({
  baseURL: `${mobileBankServerAddress}/API-1.0/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const adminAxiosInstance = axios.create({
  baseURL: `${internetBankServerAddress}/api/v1/admin`,
  timeout: 60 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const customerAxiosInstance = axios.create({
  baseURL: `${internetBankServerAddress}/api/v1/client`,
  timeout: 60 * 1000,
});

export const axiosAuthInstance = axios.create({
  baseURL: `${internetBankServerAddress}/api/v1/auth`,
  timeout: 60 * 1000,
});

export function createClientInterceptor(dispatch, navigate) {
  // Add a request interceptor
  customerAxiosInstance.interceptors.request.use((config) => {
    // const state = store.getState();
    const originalTokenDetailsString = sessionStorage.getItem(userDetailsKey);
    const tokenParse = JSON.parse(originalTokenDetailsString);
    // const tokenDetails = state.auth.auth;
    const lang = sessionStorage.getItem("lang");
    const browserLanguage = lang === "fr" ? "fr" : "en" || navigator.language;

    config.params = config.params || {};
    config.headers["Authorization"] =
      `Bearer ${tokenParse?.authInfo?.token || ""}`;
    config.headers["Content-Type"] = `application/json`;
    config.headers["Accept-Language"] = browserLanguage;
    return config;
  });
  // Add a response interceptor
  customerAxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (!error.response) {
        throw error;
      } else if (error.response && error.response.status === 401) {
        dispatch(Logout(navigate));
      }
      throw error;
    },
  );
}

export function createAdminInterceptor(dispatch, navigate) {
  adminAxiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.token;
    const lang = sessionStorage.getItem("lang");
    const browserLanguage = lang === "fr" ? "fr" : "en" || navigator.language;

    config.params = config.params || {};
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = `application/json`;
    config.headers["Accept-Language"] = browserLanguage;
    return config;
  });

  adminAxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (!error.response) {
        throw error;
      } else if (error.response && error.response.status === 401) {
        dispatch(Logout(navigate));
      }
      throw error;
    },
  );
}

// const logout = () => (dispatch) => {
//     sessionStorage.removeItem('userDetails');
//     dispatch({ type: LOGOUT_ACTION });
// };
