export const organizeCheckedNodes = (nodes, checked) => {
  const result = [];
  const traverse = (node) => {
    if (checked.includes(String(node.value))) {
      if (node.children) {
        const organized = {};
        organized.moduleId = node.value;
        organized.menuIds = node.children
          .filter((obj) => checked.includes(String(obj.value)))
          .map((child) => child.value);
        result.push(organized);
        // node.children.forEach(child => traverse(child));
      }
    }
  };

  nodes.forEach((node) => {
    const setChildren = (child) => {
      if (child.children) {
        node.children = [...node.children, ...child.children];
        child.children.forEach((ch) => setChildren(ch));
      }
    };

    if (node.children) {
      node.children.forEach((n) => setChildren(n));
      traverse(node);
    } else {
      traverse(node);
    }
  });
  return result;
};
