/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Success from "../../../common/images/icon/succes.png";
import Alert from "../../../common/images/icon/alerte.png";
import { useLocation } from "react-router-dom";
import { internetBankServerAddress } from "../../../common/constant";
import { ThemeContext } from "../../../context/ThemeContext";
import * as Spinners from "react-loader-spinner";

const DiasporaDepositResponse = () => {
  const { setShowLoading } = useContext(ThemeContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const i = params.get("i");
  const t = params.get("t");

  const [showContent, setShowContent] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("SUCCESS");

  const getImageStatus = () => {
    if (status === "CANCELED" || status === "FAILED") {
      return Alert;
    } else if (status === "SUCCESS") {
      return Success;
    } else {
      return Alert;
    }
  };

  const okButton = () => {
    window.close();
  };

  useEffect(() => {
    setShowLoading(false);
    if (i && t) {
      const baseUrl = `${internetBankServerAddress}/api/v1/client/transactions`;
      const eventSource = new EventSource(
        `${baseUrl}/transactionStatus/${i}?token=${t}`,
      );

      eventSource.addEventListener("status", (event) => {
        const data = JSON.parse(event.data);
        if (data.status) {
          setShowContent(true);
          setStatus(data.status);
          setMessage(data.message);
        } else {
          window.close();
        }
        eventSource.close();
      });
    } else {
      window.close();
    }
  }, [i, t]);

  return (
    showContent && (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 35px)" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center text-center w-50 h-50 border-0 shadow">
          {message === "PENDING" ? (
            <Spinners.TailSpin
              height="40"
              width="40"
              ariaLabel="tail-spin-loading"
              radius="1"
              color="#000"
            />
          ) : (
            <img
              className="object-fit-cover"
              src={getImageStatus()}
              alt="icone message"
              width={100}
            />
          )}
          <p className="ps-3 pe-3">
            {message === "PENDING" ? "Loading ..." : { message }}
          </p>
          <button className="btn btn-dark" onClick={okButton}>
            Ok
          </button>
        </div>
      </div>
    )
  );
};

export default DiasporaDepositResponse;
