import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { subscribe } from "../../services/clientAuthService";
import { CUSTOMERPORTAL } from "../../../common/store/selectors/authSelectors";
import { toast } from "react-toastify";
import ErrorManager from "../../errors";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import "../../../styles/login.css";
import ImageLogin from "../../../common/images/login/1.svg";
import AppTranslation from "../../../common/jsx/components/AppTranslation";
import { useTranslation } from "react-i18next";
import { encryptData } from "../../../utils/utilsCrypto";
import logo from "../../../common/images/logo/logo.png";
import { COMPANY_NAME_FULL } from "../../../config";

const Subscribe = (props) => {
  const { tb } = AppTranslation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [accountId, setAccountId] = useState("");
  const [errors, setErrors] = useState({
    userName: "",
    accountId: "",
    otherMsg: "",
  });
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
    setErrors((prev) => ({
      ...prev,
      userName: "",
      otherMsg: "",
    }));
  };

  const handleAccountIdChange = (e) => {
    setAccountId(e.target.value);
    setErrors((prev) => ({
      ...prev,
      accountId: "",
      otherMsg: "",
    }));
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (accountId === "") {
      setErrors((prev) => ({
        ...prev,
        accountId: t("accountId_is_required"),
      }));
      hasErrors = true;
    }

    if (userName === "") {
      setErrors((prev) => ({
        ...prev,
        userName: t("login_username_error"),
      }));
      hasErrors = true;
    } else if (userName.length < 5) {
      setErrors((prev) => ({
        ...prev,
        userName: t("login_username_length_error"),
      }));
      hasErrors = true;
    } else if (userName.includes(" ")) {
      setErrors((prev) => ({
        ...prev,
        userName: t("login_username_spaces_error"),
      }));
      hasErrors = true;
    }

    if (!hasErrors) {
      setShowLoading(true);
      const body = {
        accountId: accountId,
        userLogin: userName,
      };
      subscribe(body)
        .then((res) => {
          const resData = res.data.data;
          resData.portal = CUSTOMERPORTAL;
          const dataCrypted = encryptData(resData);
          navigate(`/auth/otp-verification?m=${dataCrypted}`);
        })
        .catch((err) => {
          setErrors({
            userName: "",
            accountId: "",
            otherMsg: "",
          });
          if (err.response) {
            if (err.response.status === 401) {
              toast.warning(err.response.data.message, {
                position: "top-right",
              });
            } else if (err.response.status === 422) {
              setErrors({
                otherMsg: err.response.data.message,
              });
            } else {
              ErrorManager(err, navigate);
            }
          } else {
            ErrorManager(err, navigate);
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });

  return (
    <div className="conteneur">
      <div className="content">
        <form onSubmit={handleSubscribe} className="left">
          <div className="sm-welcome">
            <h1>{t("login_title")}</h1>
            <p>{tb("login_page_intro")}</p>
          </div>
          <img src={logo} width="35%" alt="logo" />
          <h2 className="head-title">{t("subscribe_title")}</h2>
          <small className="input-error">{errors.otherMsg}</small>
          <div className="input-box">
            <div className="icon">
              <RemixIcon.RiBankCardLine size={20} />
            </div>
            <input
              type="text"
              value={accountId}
              onChange={handleAccountIdChange}
              placeholder={t("accountId_placeholder")}
              autoComplete="off"
              autoFocus
            />
          </div>
          <small className="input-error">{errors.accountId}</small>

          <div className="input-box">
            <div className="icon">
              <RemixIcon.RiUserLine size={20} />
            </div>
            <input
              type="text"
              value={userName}
              onChange={handleUsernameChange}
              placeholder={t("forgot_pass_placeholder")}
              autoComplete="off"
              maxLength={16}
            />
          </div>
          <small className="input-error">{errors.userName}</small>

          <button className="btn-custom" type="submit" disabled={showLoading}>
            {showLoading ? (
              <Spinners.TailSpin
                height="20"
                width="20"
                ariaLabel="tail-spin-loading"
                radius="4"
                color="#fff"
              />
            ) : (
              <RemixIcon.RiArrowRightLine />
            )}
            {t("subscribe_button")}
          </button>
          <a className="btn-link" onClick={() => navigate("/auth/login")}>
            <RemixIcon.RiHomeLine />
            {t("back_to_login")}
          </a>
        </form>
        <div className="right right-forgot-password">
          <img className="image-login" src={ImageLogin} alt={ImageLogin} />
          <h1 className="fw-bold">{COMPANY_NAME_FULL}</h1>
          <p>{tb("login_page_intro")}</p>
          <button onClick={() => navigate("/auth/page-register")}>
            {t("pass_create_acc_ques")}
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Subscribe);
