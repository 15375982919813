import { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

const TopLoader = ({ className }) => {
  const { showLoading } = useContext(ThemeContext);

  return (
    <div
      className={`se-pre-con load-bar ${showLoading ? "" : "hide"} ${className}`}
      style={{ zIndex: 10 }}
    >
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};
export default TopLoader;
