import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ClientRoutes from "../data/clientRoute";
import Error400 from "../common/jsx/pages/errorpages/Error400";
import Error403 from "../common/jsx/pages/errorpages/Error403";
import Error404 from "../common/jsx/pages/errorpages/Error404";
import Error500 from "../common/jsx/pages/errorpages/Error500";
import Error503 from "../common/jsx/pages/errorpages/Error503";
import MainLayout from "../common/jsx/pages";
import ScrollToTop from "../common/jsx/layouts/ScrollToTop";
import { isAuthenticated } from "../common/store/selectors/authSelectors";
import { connect } from "react-redux";

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
};

const CustomerRouter = (props) => {
  const NotFound = () => {
    const url = ClientRoutes.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
    return null;
  };

  return (
    <>
      <Routes>
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route path="/" element={<MainLayout />}>
          {ClientRoutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={data.url}
              element={
                props.isAuthenticated ? (
                  props.userDto.securityQuestionCounts > 0 ? (
                    data.component
                  ) : (
                    <Navigate to={`/auth/set-security-questions`} />
                  )
                ) : (
                  <Error403 />
                )
              }
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    userDto: state.auth.userDao,
  };
};

export default withRouter(connect(mapStateToProps)(CustomerRouter));
