/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getCustomerAccounts,
  listCheckBookRequest,
  deleteCheckBookRequest,
} from "../../services/acountServices";
import { Modal } from "react-bootstrap";
import Benefit from "../../../common/images/svg/benefit.svg";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { stringDate } from "../../../common/constant";
import swal from "sweetalert";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import ErrorManager from "../../errors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CheckBookRequestStatus = () => {
  const { t } = useTranslation();

  const [checkBookList, setCheckbookList] = useState([]);
  // const [optionAccounts, setOptionAccounts] = useState([])
  // const [accountList, setAccountsList] = useState([])
  const [hasCurrentAccount, setHasCurrentAccount] = useState(false);
  const {
    setShowLoading,
    showLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);
  const [initialLoad, setInitialLoad] = useState(true);

  const Navigate = useNavigate();
  const checkRequestTableHeaders = [
    { label: t("request_date") },
    { label: t("pages") },
    { label: t("reason") },
    { label: t("status") },
    { label: t("comment") },
    { label: t("action") },
  ];

  let selectedId;

  const executeDeleteCheckBookRequestDelete = (securityQuestions) => {
    setShowLoading(true);
    return deleteCheckBookRequest(selectedId, securityQuestions)
      .then((v) => {
        toast.success(t("deleted_success"), { position: "top-right" });
        getPageData();
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const checkBookRequestDelete = (id) => {
    return swal({
      title: t("are_you_sure"),
      // text: t("Once_deleted_you_will_not_be_able_to_recover_this"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          selectedId = id;
          setShowLoading(true);
          setVerifyCallback(executeDeleteCheckBookRequestDelete);
          setVerifySecureQuestions(true);
        }
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      });
  };

  const getPageData = () => {
    setShowLoading(true);
    listCheckBookRequest()
      .then((v) => {
        const data = v.data.data;
        setCheckbookList(
          data.map((b) => [
            stringDate(b.dateCreation),
            b.numberPages,
            b.description,
            b.etat,
            b.rejectReason,
            b.etat === "PENDING" ? (
              <div className="action-button">
                <button
                  type="button"
                  onClick={() => checkBookRequestDelete(b.id)}
                  className="btn btn-icon-xxs text-danger"
                >
                  <RemixIcon.RiDeleteBin2Line />
                </button>
              </div>
            ) : null,
          ]),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });

    return getCustomerAccounts()
      .then((v) => {
        const data = v.data.data;
        // setAccountsList(data)
        const currentAccounts = data.filter(
          (v) => v.productType.trim() === "CURRENT",
        );
        if (currentAccounts.length === 0) {
          setHasCurrentAccount(false);
        } else {
          // setOptionAccounts(currentAccounts.map((act) => ({
          //     value: act.accountID,
          //     label: `${act.accountID} - ${act.accountName}`
          // })))
          setHasCurrentAccount(true);
        }
        return data;
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setInitialLoad(false);
      });
  };

  useEffect(() => {
    getPageData().then((v) => {
      setShowLoading(false);
    });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <h4 className="fw-bold">{t("check_book_request_status")}</h4>
        <div className="card bg-white border-0 shadow">
          <div className="card-body border-0">
            <div className="row ">
              <div className="col-xl-8 border-0">
                <DataTable
                  headersTitle={checkRequestTableHeaders}
                  tableData={checkBookList}
                />
              </div>

              <div className="col-xl-4">
                <div className="card pb-0 border-0">
                  <div className="card-body rounded-0">
                    <div className="media me-2 pb-5 d-flex justify-content-center">
                      <img
                        width="100"
                        height="100"
                        src={Benefit}
                        alt="benefit"
                      />
                    </div>
                    <div
                      style={{ height: "300px" }}
                      id="DZ_W_Todo3"
                      className="widget-media dz-scroll height270  ps--active-y"
                    >
                      <ul className="timeline">
                        <li>
                          <h5 className="mb-2 fs-5 d-flex justify-content-center">
                            {t("check_book_status_title")}
                          </h5>
                          {/* <h2 className="mb-2 fs-5 text-warning">
                                                    {t('change_pass_force')}
                                                    </h2> */}
                          <p className="pl-5 fs-5 d-flex justify-content-center text-center">
                            {t("check_book_status_body")}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="fade"
        size="sm"
        show={initialLoad || (!showLoading && !hasCurrentAccount)}
        centered
      >
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">
          {!showLoading && !hasCurrentAccount && (
            <div className="text-center">
              <h5 className="mb-2 fs-5 text-center">
                {t("Sorry_you_do_not_have_a_current_accounts")}
              </h5>
              <button
                className="btn btn-warning btn-sm me-3"
                onClick={() => Navigate("/home")}
              >
                {t("ok_button")}
              </button>
            </div>
          )}

          {showLoading && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <Spinners.TailSpin
                height="40"
                width="40"
                ariaLabel="tail-spin-loading"
                radius="1"
                color="#000"
              />
              <p>Recherche des comptes cheques</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CheckBookRequestStatus;
