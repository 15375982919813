/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import * as RemixIcon from "react-icons/ri";
import registration from "../../../common/images/login/6.jpg";
import "../../../styles/login.css";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { Logout } from "../../../common/services";
import { baseUrlClient } from "../../../common/constant";
import { useTranslation } from "react-i18next";

const FirstEndPointSuccess = () => {
  const { t } = useTranslation();

  const Navigate = useNavigate();
  const { setShowLoading, setShowFooter } = useContext(ThemeContext);
  const [shouldRenderContent, setShouldRenderContent] = useState(false);

  useEffect(() => {
    const storedDate = sessionStorage.getItem(".,mndwfsvdawe!@#$%^&*()_szdx");
    const storedTimestamp = new Date(storedDate).getTime();
    const currentTimestamp = new Date().getTime();

    if (!storedDate || currentTimestamp - storedTimestamp > 60000) {
      setShowFooter(false);
      goHome();
    } else {
      setShouldRenderContent(true);
    }

    setShowLoading(false);
  }, [Navigate]);

  function goHome() {
    sessionStorage.removeItem("firstEndPoint");
    sessionStorage.removeItem(".grchchfe_lvcv");
    Logout(Navigate, baseUrlClient);
  }

  return (
    shouldRenderContent && (
      <div className="subscribe">
        <div className="left">
          <img className="login-image" src={registration} alt={registration} />
        </div>
        <div className="right">
          <div className="all-step">
            <div className="form-step">
              <h1>{t("check_email")}</h1>
              <div className="content-4">
                <p>{t("check_email_info")}</p>
              </div>
            </div>
            <div className="step-button">
              <button type="button" onClick={goHome}>
                <RemixIcon.RiHome2Line />
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FirstEndPointSuccess;
