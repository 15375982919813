import React, { useEffect } from "react";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { t } from "i18next";

const ConfigurationsDashboard = () => {
  useEffect(() => {}, []);
  return (
    <>
      <MainPageTitle
        mainTitle="Dashboard"
        pageTitle={t("Dashboard")}
        parentTitle="Configuration"
      />
      <div className="container-fluid">
        <div className="row"></div>
      </div>
    </>
  );
};

export default ConfigurationsDashboard;
