import { toast } from "react-toastify";
import { store } from "../../common/store";
import {
  ADMINPORTAL,
  CUSTOMERPORTAL,
} from "../../common/store/selectors/authSelectors";
import { accesPortalKey, baseUrlAdmin } from "../../common/constant";
import { t } from "i18next";

const ErrorManager = (err, nav) => {
  const storeState = store.getState();
  const accessPortal = storeState.auth.accessPortal;
  const getPortal = () => {
    if (sessionStorage.getItem(accesPortalKey) === CUSTOMERPORTAL) {
      return "";
    } else if (accessPortal === ADMINPORTAL) {
      return baseUrlAdmin;
    }
  };

  if (err.response) {
    if (err.response.status === 500) {
      toast.error(t("internal_server_error_try_again_later"), {
        position: "top-right",
      });
      nav(`${getPortal()}/home`);
    } else {
      toast.error(err.response.data.message, {
        position: "top-right",
      });
    }
  } else {
    toast.warning(t("check_your_internet_connection"), {
      position: "bottom-center",
    });
  }
};

export default ErrorManager;
