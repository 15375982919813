import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import registration from "../../../common/images/login/6.jpg";
import "../../../styles/register.css";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import ErrorManager from "../../errors";
import {
  accountResquest,
  getTermsByCode,
} from "../../services/clientAuthService";
import { Modal } from "react-bootstrap";
import { baseUrlClient } from "../../../common/constant";
import { Logout } from "../../../common/services";
import { useTranslation } from "react-i18next";

const accountTypes = [
  { label: "CURRENT", value: "CURRENT" },
  { label: "SAVING", value: "SAVING" },
];

const Register = () => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const Navigate = useNavigate();

  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);
  const [termsMessage, setTermsMessage] = useState(<></>);

  const step = parseInt(params.get("step")) || 1;

  useEffect(() => {
    const storedValue = sessionStorage.getItem(".grchchfe_lvcv");
    const storedStep = storedValue ? parseInt(storedValue.slice(-1)) || 1 : 1;
    if (step !== storedStep) {
      Navigate(`?step=${storedStep}`);
    }
  }, [Navigate, step]);

  const [firstEndPoint, setFirstEndPoint] = useState(() => {
    return (
      getFromSessionStorage("firstEndPoint") || {
        accountType: "",
        firstname: "",
        lastname: "",
        countryOfResidence: "",
        birthDate: "",
        profession: "",
        nationality: "",
        customerType: "Physique",
        gender: "",
        email: "",
        poBox: "",
        address1: "",
        address2: "",
        phoneNumber: "",
        identity: "",
        phoneNumber1: "",
        phoneNumber2: "",
      }
    );
  });

  const [errors, setErrors] = useState({
    accountType: "",
    firstname: "",
    lastname: "",
    countryOfResidence: "",
    birthDate: "",
    profession: "",
    nationality: "",
    customerType: "Physique",
    gender: "",
    email: "",
    poBox: "",
    address1: "",
    address2: "",
  });

  useState(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  });

  let stepComponent;

  switch (step) {
    case 1:
      stepComponent = stepForm1();
      break;
    case 2:
      stepComponent = stepForm2();
      break;
    case 3:
      stepComponent = stepForm3();
      break;
    case 4:
      stepComponent = stepForm4();
      break;
    default:
      stepComponent = stepForm1();
      break;
  }

  useEffect(() => {
    saveToSessionStorage("firstEndPoint", firstEndPoint);
  }, [firstEndPoint]);

  function handleShowTermsCondition() {
    setShowLoading(true);
    const code = "ACCOUNT_REQUES";
    getTermsByCode(code)
      .then((res) => {
        setTermsMessage(res.data.data.text);
        setShowModal(true);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function saveToSessionStorage(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  function getFromSessionStorage(key) {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  function handleSubmitFirstEndPoint() {
    setShowLoading(true);
    accountResquest(firstEndPoint)
      .then(() => {
        sessionStorage.removeItem("firstEndPoint");
        sessionStorage.removeItem(".grchchfe_lvcv");
        sessionStorage.setItem(
          ".,mndwfsvdawe!@#$%^&*()_szdx",
          new Date().toISOString(),
        );
        Navigate("/auth/page-register/first-end-point-success");
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  function handleContinue(e) {
    e.preventDefault();
    const currentStep = step;
    let isValid = true;

    switch (currentStep) {
      case 1:
        if (!firstEndPoint.accountType) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            accountType: t("select_acc_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            accountType: "",
          }));
        }
        break;
      case 2:
        if (!firstEndPoint.name) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: t("f_name_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: "",
          }));
        }
        if (!firstEndPoint.countryOfResidence) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            countryOfResidence: t("country_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            countryOfResidence: "",
          }));
        }
        if (!firstEndPoint.gender) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            gender: t("gender_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            gender: "",
          }));
        }
        if (!firstEndPoint.birthDate) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            birthDate: t("dob_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            birthDate: "",
          }));
        }
        if (!firstEndPoint.profession) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            profession: t("profession_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            profession: "",
          }));
        }
        if (!firstEndPoint.nationality) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            nationality: t("nationality_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            nationality: "",
          }));
        }
        break;
      case 3:
        if (!firstEndPoint.email) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: t("sub_email_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        }
        if (!firstEndPoint.phoneNumber1) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber1: t("sub_phone1_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber1: "",
          }));
        }
        if (!firstEndPoint.address1) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            address1: t("sub_address_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            address1: "",
          }));
        }
        if (!firstEndPoint.poBox) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            poBox: t("po_box_error"),
          }));
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            poBox: "",
          }));
        }
        break;
      default:
        break;
    }

    if (isValid) {
      if (currentStep <= 3) {
        const newStep = step + 1;
        const newStoredValue = `ejhrugktrmllyjui5hijbjygyvhbhvcgcdmlf,l,lmknjhvgc12${newStep}`;
        sessionStorage.setItem(".grchchfe_lvcv", newStoredValue);
        Navigate(`?step=${newStep}`);
      } else {
        if (
          firstEndPoint.email &&
          firstEndPoint.phoneNumber1 &&
          firstEndPoint.address1 &&
          firstEndPoint.poBox
        ) {
          e.preventDefault();
          handleSubmitFirstEndPoint(currentStep);
        }
      }
    }
  }

  function handleBack() {
    if (step > 1) {
      Navigate(`?step=${step - 1}`);
    } else {
      Logout(Navigate, baseUrlClient);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFirstEndPoint((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function stepForm1() {
    return (
      <div className="form-step form-step-1" id="form">
        <h1>{t("acc_type_title")}</h1>
        <div className="content-2">
          <div className="input-registration">
            <div className="input-content">
              <label>{t("select_acc_type")}</label>
              <select
                name="accountType"
                onChange={handleChange}
                value={firstEndPoint.accountType}
              >
                <option></option>
                {accountTypes.map((account, index) => (
                  <option key={index} value={account.value}>
                    {account.label}
                  </option>
                ))}
              </select>
            </div>
            <small className="error">{errors.accountType}</small>
          </div>
        </div>
      </div>
    );
  }

  function stepForm2() {
    return (
      <div className="form-step pad">
        <h1>{t("basic_info")}</h1>
        <div className="content">
          <div className="input-registration">
            <div className="input-content">
              <label>{t("firstname")} </label>
              <input
                type="text"
                name="name"
                value={firstEndPoint.name}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.name}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("lastname")} </label>
              <input
                type="text"
                name="surname"
                value={firstEndPoint.surname}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.surname}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("gender")}</label>
              <select
                name="gender"
                value={firstEndPoint.gender}
                onChange={handleChange}
              >
                <option>---</option>
                <option value="male">{t("male")}</option>
                <option value="female">{t("female")}</option>
              </select>
            </div>
            <small className="error">{errors.gender}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("dob")}</label>
              <input
                type="date"
                name="birthDate"
                value={firstEndPoint.birthDate}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.birthDate}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("country")}</label>
              <input
                type="text"
                name="countryOfResidence"
                value={firstEndPoint.countryOfResidence}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.countryOfResidence}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("profession")}</label>
              <input
                type="text"
                name="profession"
                value={firstEndPoint.profession}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.profession}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("nationality")}</label>
              <input
                type="text"
                name="nationality"
                value={firstEndPoint.nationality}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.nationality}</small>
          </div>
        </div>
      </div>
    );
  }

  function stepForm3() {
    return (
      <div className="form-step">
        <h1>{t("contact_info_title")}</h1>
        <div className="content">
          <div className="input-registration">
            <div className="input-content">
              <label>{t("subscribe_email")}</label>
              <input
                type="text"
                name="email"
                value={firstEndPoint.email}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.email}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("subscribe_phone1")}</label>
              <input
                type="text"
                name="phoneNumber1"
                value={firstEndPoint.phoneNumber1}
                onChange={handleChange}
                placeholder={t("subscribe_phone2_placeholder")}
              />
            </div>
            <small className="error">{errors.phoneNumber1}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("subscribe_phone2")}</label>
              <input
                type="text"
                name="phoneNumber2"
                value={firstEndPoint.phoneNumber2}
                onChange={handleChange}
                placeholder="start with country code, ex: +234"
              />
            </div>
            <small className="error">{errors.phoneNumber2}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("subscribe_current_address")}</label>
              <input
                type="text"
                name="address1"
                value={firstEndPoint.address1}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.address1}</small>
          </div>
          <div className="input-registration">
            <div className="input-content">
              <label>{t("po_box")}</label>
              <input
                type="text"
                name="poBox"
                value={firstEndPoint.poBox}
                onChange={handleChange}
              />
            </div>
            <small className="error">{errors.poBox}</small>
          </div>
        </div>
      </div>
    );
  }

  function stepForm4() {
    return (
      <div className="form-step">
        <h1>{t("sub_t&c_title")}</h1>
        <div className="content-4">
          <p> {t("sub_t&c")}</p>
          <p
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleShowTermsCondition}
          >
            {t("sub_tou")}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="subscribe">
      <div className="left">
        <img className="login-image" src={registration} alt={registration} />
      </div>
      <div className="right">
        <div className="all-step">
          {stepComponent}
          <div className="step-button">
            <button type="button" onClick={handleBack}>
              <RemixIcon.RiArrowLeftLine />
              {t("back_button")}
            </button>
            <button
              type="button"
              onClick={handleContinue}
              disabled={showLoading ? true : false}
            >
              {step === 4 ? "Submit" : t(" Continue")}
              {step === 4 && showLoading ? (
                <Spinners.TailSpin
                  height="20"
                  width="20"
                  ariaLabel="tail-spin-loading"
                  radius="4"
                  color="#fff"
                />
              ) : (
                <RemixIcon.RiArrowRightLine />
              )}
            </button>
          </div>
        </div>
      </div>
      <Modal className="fade" size="md" show={showModal} centered>
        <Modal.Header style={{ height: "3rem" }} className="bg-primary">
          <Modal.Title className="text-white">{t("sub_tou")}</Modal.Title>
          {/* <Button variant="" onClick={() => setShowModal(false)} className="btn btn-outline-danger btn-close text-white"></Button> */}
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {termsMessage ? (
            <div dangerouslySetInnerHTML={{ __html: termsMessage }} />
          ) : (
            <h5 className="mb-2 fs-5 text-center">
              {termsMessage ?? t("not_set")}
            </h5>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            onClick={() => setShowModal(false)}
            type={"button"}
            className="btn btn-outline-primary btn-sm"
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Register;
