import React, { useContext, useEffect } from "react";

/// React router dom
import { Outlet, useNavigate } from "react-router-dom";

/// Css
import "./index.css";

/// Layout
import { ThemeContext } from "../../../context/ThemeContext";
import Nav from "../layouts/nav";
import Footer from "../layouts/Footer";
import TopLoader from "../components/TopLoader";
import SecurityQuestionModal from "../../../appClient/components/SecurityQuestionModal";
import OtpModal from "../../../appClient/components/OtpModal";
import LoadingTranModal from "../../../appClient/components/LoadingTransactionModal";
import { checkAutoLogin } from "../../services";
import { connect, useDispatch } from "react-redux";
import { isAuthenticated } from "../../store/selectors/authSelectors";

export const handleBeforeUnload = (e) => {
  // Customize your alert message here
  const message = "Are you sure you want to refresh?";
  e.returnValue = message; // For Chrome
  e.preventDefault();
  return message; // For other browsers
};

function MainLayout(props) {
  const { menuToggle, showFooter } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch, navigate]);

  return (
    props.isAuthenticated && (
      <div
        id="main-wrapper"
        className={`show ${menuToggle ? "menu-toggle" : ""}`}
      >
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: "calc(100vh - 35px)" }}
        >
          <Outlet />
        </div>
        <SecurityQuestionModal />
        <OtpModal />
        <LoadingTranModal />
        {showFooter && <Footer />}
      </div>
    )
  );
}

export function AuthLayout() {
  const { showFooter } = useContext(ThemeContext);

  return (
    <div className="main-wrapper position-relative bg-white min-vh-100">
      <TopLoader className="top-0" />
      <div>
        <Outlet />
      </div>
      {showFooter && <Footer />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(MainLayout);
