import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkAutoLogin } from "../common/services";

const AuthGuard = ({ baseUrl, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAutoLogin(dispatch, navigate, baseUrl);
  }, [dispatch, navigate, baseUrl]);

  return children;
};

export default AuthGuard;
