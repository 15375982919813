/**
 * Truncate a text to a specified length and add ellipsis if the text is longer.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} size - The maximum length of the truncated text.
 * @returns {string} The truncated text with ellipsis if necessary.
 */

export const substringText = (text, size) => {
  if (text.length > size) {
    return text.substring(0, size) + "...";
  }
  return text;
};
