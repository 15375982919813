import React, { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmployeeOffcanvas from "../../components/EmployeeOffcanvas";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import { listAllUsers, getAppRoles } from "../../services/authService";
import { DataTable } from "../../../common/jsx/components/DataTable";
import * as RemixIcon from "react-icons/ri";
import ErrorManager from "../../../appClient/errors";
import { ThemeContext } from "../../../context/ThemeContext";
import { t } from "i18next";
import { Modal, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { updateProfileOfUsers } from "../../services/userService";

const tableHeaders = [
  { label: t("employee_id") },
  { label: t("employee_name") },
  { label: t("actions") },
];

const UserManagement = (props) => {
  const [userList, setUserList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [roleListData, setRoleListData] = useState([]);

  const Navigate = useNavigate();
  const employee = useRef();
  const { setShowLoading, showLoading } = useContext(ThemeContext);

  const [data, setData] = useState({});
  const [editRole, setEditRole] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [inputValues, setInputValues] = useState({
    roleId: "",
    password: "",
  });
  const [inputValuesDouble, setInputValuesDouble] = useState({
    roleId: "",
    password: "",
  });
  const [visibleInput, setVisibleInput] = useState({
    password: true,
    confirmPassword: true,
  });

  const handleEditRole = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      roleId: "",
    }));
    setEditRole(true);
  };

  const handleEditPassword = () => {
    setInputValues((prevValues) => ({
      ...prevValues,
      password: "",
    }));
    setEditPassword(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: name === "doubleAuthentication" ? JSON.parse(value) : value,
    });
  };

  const handleShowModal = (data) => {
    setShowDetails(true);
    setData(data);
  };

  const getEmployeeList = () => {
    setShowLoading(true);
    listAllUsers()
      .then((v) => {
        const data = v.data.data;

        setUserList(
          data.map((u, index) => [
            u.userLogin,
            u.name,
            <div className="action-button">
              {props.userDao.userLogin !== u.userLogin && (
                <button
                  type="button"
                  onClick={() => handleShowModal(data[index])}
                  className="btn text-primary btn-icon-xxs"
                >
                  <RemixIcon.RiPencilLine />
                </button>
              )}{" "}
              {/* <button type="button" className="btn text-danger btn-icon-xxs"><RemixIcon.RiDeleteBin2Line /></button> */}
            </div>,
          ]),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const getRoleList = () => {
    getAppRoles()
      .then((v) => {
        const data = v.data.data;
        setRoleListData(
          data.map((r) => ({
            label: r.name,
            value: r.id,
          })),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      });
  };

  const handleUpdate = () => {
    if (
      !hasChanges ||
      inputValues.password !== inputValues.confirmPassword ||
      inputValues.password.length <= 7
    ) {
      return;
    }

    setShowLoading(true);
    const updates = {};
    if (inputValues.roleId !== "") {
      updates.roleId = inputValues.roleId;
    }
    if (inputValues.password !== "") {
      updates.password = inputValues.password;
    }

    return updateProfileOfUsers(data.uuid, updates)
      .then((res) => {
        toast.success(t("changes_saved_successfully"), {
          position: "top-right",
        });
        setEditRole(false);
        setEditPassword(false);
        setInputValues({
          roleId: "",
          password: "",
          confirmPassword: "",
        });

        setInputValuesDouble({
          roleId: "",
          password: "",
          confirmPassword: "",
        });

        setData({});
        setShowDetails(false);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    getEmployeeList();
    getRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isModified =
      JSON.stringify(inputValues) !== JSON.stringify(inputValuesDouble);
    const isValidPassword =
      inputValues.password.length > 7 &&
      inputValues.password === inputValues.confirmPassword;
    setHasChanges(isModified && isValidPassword);
  }, [inputValues, inputValuesDouble]);

  return (
    <>
      <MainPageTitle
        mainTitle="Employee"
        pageTitle={"Employee"}
        parentTitle={"Home"}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8">
            <div className="card rounded-0 shadow p-3">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center p-0 mb-3">
                    <h4 className="heading mb-0">{t("employees")}</h4>
                    <div>
                      <Link
                        to={"#"}
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="offcanvas"
                        onClick={() => employee.current.showEmployeModal()}
                      >
                        + {t("addemployee")}
                      </Link>{" "}
                    </div>
                  </div>
                  <div>
                    <DataTable
                      sort={10}
                      tableData={userList}
                      headersTitle={tableHeaders}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeOffcanvas
        getEmployeeList={getEmployeeList}
        ref={employee}
        Title={t("addemployee")}
      />

      <Modal className="rounded-0" size="md" show={showDetails} centered>
        <Modal.Body className="row rounded-0">
          <div className="col-xl-12 col-sm-12 overflow-scroll">
            <Table>
              <thead>
                <tr>
                  {/* <th></th>
                                    <th></th>
                                    <th className="fw-bold">{t('actions')}</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="fw-bold">{t("user_role")} : </td>
                  <td>
                    {editRole ? (
                      <select
                        name="roleId"
                        value={inputValues.roleId}
                        onChange={handleInputChange}
                        className="form-control-profil"
                      >
                        <option value="">{t("select_role")}</option>
                        {roleListData.map((role) => (
                          <option key={role.value} value={role.value}>
                            {role.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      // (data.name ?? t('no_role'))
                      "- - -"
                    )}
                  </td>
                  <td>
                    {editRole ? (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setEditRole(false)}
                      >
                        <RemixIcon.RiCloseLine size={15} /> {t("cancel_button")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleEditRole}
                      >
                        <RemixIcon.RiEditBoxLine size={15} /> {t("edit_button")}
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="fw-bold">{t("password")} : </td>
                  <td className="position-relative">
                    {editPassword ? (
                      <>
                        <input
                          type="text"
                          name="password"
                          value={inputValues.password}
                          autoComplete="off"
                          onChange={handleInputChange}
                          className={`${inputValues.password && visibleInput.password && "masked-input"} form-control-profil`}
                        />
                        {inputValues.password && visibleInput.password && (
                          <RemixIcon.RiEyeLine
                            className="position-absolute"
                            style={{
                              right: "15px",
                              top: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setVisibleInput((prev) => ({
                                ...prev,
                                password: false,
                              }))
                            }
                          />
                        )}
                        {inputValues.password && !visibleInput.password && (
                          <RemixIcon.RiEyeOffLine
                            className="position-absolute"
                            style={{
                              right: "15px",
                              top: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setVisibleInput((prev) => ({
                                ...prev,
                                password: true,
                              }))
                            }
                          />
                        )}
                      </>
                    ) : (
                      "- - -"
                    )}
                  </td>
                  <td>
                    {editPassword ? (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setEditPassword(false)}
                      >
                        <RemixIcon.RiCloseLine size={15} /> {t("cancel_button")}
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={handleEditPassword}
                      >
                        <RemixIcon.RiEditBoxLine size={15} /> {t("edit_button")}
                      </button>
                    )}
                  </td>
                </tr>
                {editPassword && (
                  <tr>
                    <td className="fw-bold">{t("confirm_new_pass")} : </td>
                    <td className="position-relative">
                      <input
                        type="text"
                        name="confirmPassword"
                        value={inputValues.confirmPassword}
                        autoComplete="off"
                        onChange={handleInputChange}
                        className={`${inputValues.confirmPassword && visibleInput.confirmPassword && "masked-input"} form-control-profil`}
                      />
                      {inputValues.confirmPassword &&
                        visibleInput.confirmPassword && (
                          <RemixIcon.RiEyeLine
                            className="position-absolute"
                            style={{
                              right: "15px",
                              top: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setVisibleInput((prev) => ({
                                ...prev,
                                confirmPassword: false,
                              }))
                            }
                          />
                        )}
                      {inputValues.confirmPassword &&
                        !visibleInput.confirmPassword && (
                          <RemixIcon.RiEyeOffLine
                            className="position-absolute"
                            style={{
                              right: "15px",
                              top: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setVisibleInput((prev) => ({
                                ...prev,
                                confirmPassword: true,
                              }))
                            }
                          />
                        )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <button
                      type="submit"
                      onClick={handleUpdate}
                      className="btn btn-primary btn-sm fs-5 mt-2"
                      disabled={!hasChanges || showLoading}
                    >
                      {t("save_button")}
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => setShowDetails(false)}
          >
            <RemixIcon.RiCloseLine size={15} /> {t("close")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userDao: state.auth.userDao,
});

export default connect(mapStateToProps)(UserManagement);
