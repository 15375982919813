import React, { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ScrollToTop from "../common/jsx/layouts/ScrollToTop";
import { connect, useDispatch } from "react-redux";
import {
  isAuthenticated,
  isAuthenticatedAdmin,
  isAuthenticatedCustomer,
} from "../common/store/selectors/authSelectors";
import AdminRoute from "../data/adminRoute";
import MainLayout from "../common/jsx/pages";
import Error400 from "../common/jsx/pages/errorpages/Error400";
import Error403 from "../common/jsx/pages/errorpages/Error403";
import Error404 from "../common/jsx/pages/errorpages/Error404";
import Error500 from "../common/jsx/pages/errorpages/Error500";
import Error503 from "../common/jsx/pages/errorpages/Error503";
import TemplateForm from "../appAdmin/pages/admin/TemplateForm";
import AuthModules from "../appAdmin/pages/athz/AuthModules";
import Profile from "../appAdmin/pages/athz/Profile";
import { createAdminInterceptor } from "../common/axiosInstance";
import { checkSessionIntegrity } from "../common/services";
import { baseUrlAdmin } from "../common/constant";

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
};

const AdminRouter = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    createAdminInterceptor(dispatch, navigate);
  }, [dispatch, navigate]);

  useEffect(() => {
    checkSessionIntegrity(dispatch, navigate, baseUrlAdmin);
  }, [dispatch, navigate]);

  const NotFound = () => {
    const url = AdminRoute.map((route) => route.url);
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    if (url.indexOf(path) <= 0) {
      return <Error404 />;
    }
    return null;
  };
  const AdminFormRoutes = [
    { url: "update-email-Template", component: <TemplateForm /> },
  ];

  return (
    <>
      <Routes>
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route
          path="/home"
          element={props.isAdmin ? <AuthModules /> : <Error403 />}
        />
        <Route path="/" element={<MainLayout />}>
          <Route
            path="/view-profile"
            element={props.isAdmin ? <Profile /> : <Error403 />}
          />
          {AdminFormRoutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={props.isAdmin ? data.component : <Error403 />}
            />
          ))}
          {AdminRoute.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={props.isAdmin ? data.component : <Error403 />}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isAdmin: isAuthenticatedAdmin(state),
    isClient: isAuthenticatedCustomer(state),
  };
};

export default withRouter(connect(mapStateToProps)(AdminRouter));
