import { combineReducers } from "redux";
import { AuthReducer } from "./reducers/authReducer";
import { configureStore } from "@reduxjs/toolkit";
import { OtpReducer } from "./reducers/otpReducer";

const reducers = combineReducers({
  auth: AuthReducer,
  otp: OtpReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
