/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import {
  accountReport,
  eStatementReport,
  getAccountHistory,
  getCustomerAccountDetails,
  getCustomerAccounts,
  statementReport,
} from "../../services/acountServices";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { useQuery } from "../../../common/services";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { DataTable } from "../../../common/jsx/components/DataTable";
import { stringDate } from "../../../common/constant";
import SelectOption from "../../../common/jsx/components/SelectOption";
import * as RemixIcon from "react-icons/ri";
import * as Spinners from "react-loader-spinner";
import ErrorManager from "../../errors";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { formatAmount } from "../../../utils/utilsFormattedAmount";

const initialDateFilter = {
  openingDate: "",
  closingDate: "",
  rangeSelector: "THIS_MONTH",
  transactions: "ALL",
  accountId: "",
};

const AccountDetailsPage = (props) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const [accountList, setAccountsList] = useState([]);
  const [optionAccounts, setOptionAccounts] = useState([]);
  const [selectAccount, setSelectedAccount] = useState("");
  const { setShowLoading, showLoading } = useContext(ThemeContext);
  const [detailsContent, setDetailsContent] = useState(null);
  const [isDateRange, setIsDateRange] = useState(false);
  let query = useQuery();
  const defaultKey = query.get("view");
  const [displayTab, setDisplayTab] = useState(defaultKey);
  const [dateFilter, setDateFilter] = useState(initialDateFilter);

  const [accountHistory, setAccountHistory] = useState();
  const [openingBalance, setOpenBalance] = useState(undefined);
  const [closingBalance, setCloseBalance] = useState(undefined);
  const [currency, setCurrency] = useState("");

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDownloadEStatement, setLoadingDownloadEStatement] =
    useState(false);
  const [showTable, setShowTable] = useState(false);

  const options1 = [
    { value: "THIS_MONTH", label: t("this_month") },
    { value: "PREVIOUS_MONTH", label: t("previous_month") },
    { value: "PREVIOUS_QUARTER", label: t("previous_quarter") },
    { value: "DATE_RANGE", label: t("date_range") },
  ];

  const options2 = [
    { value: "ALL", label: t("all") },
    { value: "CREDIT", label: t("credit") },
    { value: "DEBIT", label: t("debit") },
  ];

  const tableHeaders = [
    { label: t("opx_date") },
    { label: t("description") },
    { label: t("reference_no") },
    { label: t("debit") },
    { label: t("credit") },
    { label: t("balance") },
  ];

  const getAccountDetails = (accounts, currentAccount) => {
    const account = accounts.find((v) => v.accountID === currentAccount);
    setShowLoading(true);
    return getCustomerAccountDetails(currentAccount)
      .then((v) => {
        const data = v.data.data;
        setDetailsContent([
          {
            title: t("customer_info"),
            content: [
              {
                label: t("customer_id"),
                value: account.client,
              },
              {
                label: t("customer_name"),
                value: data.name,
              },
              {
                label: t("customer_type"),
                value: account.clientType,
              },
            ],
          },
          {
            title: t("account_info"),
            content: [
              {
                label: t("account_number"),
                value: account.accountID,
              },
              {
                label: t("account_type"),
                value: account.accountName,
              },
              {
                label: t("branch"),
                value: `${account.branchName} - ${account.ourBranchID}`,
              },
            ],
          },
          {
            title: t("balance_info"),
            content: [
              {
                label: t("clear_balance"),
                value: formatAmount(data.clearBalance, data.currency),
              },
              {
                label: t("valid_balance"),
                value: formatAmount(data.validBalance, data.currency),
              },
              {
                label: t("available_balance"),
                value: formatAmount(data.clearBalance, data.currency),
              },
            ],
          },
        ]);
      })
      .catch((err) => ErrorManager(err, Navigate))
      .finally(() => setShowLoading(false));
  };

  const getPageData = () => {
    setShowLoading(true);
    setShowTable(true);
    return getCustomerAccounts()
      .then((v) => {
        const data = v.data.data;
        setAccountsList(data);
        setOptionAccounts(
          data.map((act) => ({
            value: act.accountID,
            label: `${act.accountID} -${act.accountName}`,
          })),
        );
        return data;
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setShowTable(false);
      });
  };

  useEffect(() => {
    const defaultAccount = query.get("a")
      ? query.get("a")
      : props.user.primaryAccount;
    setShowLoading(true);
    getPageData()
      .then((v) => {
        setShowLoading(true);
        setSelectedAccount(defaultAccount);
        dateFilter.accountId = defaultAccount;
        getAccountDetails(v, defaultAccount)
          .catch((err) => {
            ErrorManager(err, Navigate);
          })
          .finally(() => {
            setShowLoading(false);
          });
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
        setShowLoading(false);
      });
  }, [Navigate, props.user.primaryAccount]);

  useEffect(() => {
    if (defaultKey) setDisplayTab(defaultKey);
  }, [defaultKey]);

  const handleAccountChange = (e) => {
    setShowLoading(true);
    setSelectedAccount(e.target.value);
    setDetailsContent(null);
    getAccountDetails(accountList, e.target.value)
      .catch((err) => ErrorManager(err, Navigate))
      .finally(() => setShowLoading(false));

    if (query.get("a")) {
      Navigate("/account?view=account-details");
    }
  };

  const handleRangeSelectorChange = (e) => {
    if (e) {
      if (e.target.value === "DATE_RANGE") {
        setIsDateRange(true);
      } else {
        setIsDateRange(false);
      }
      setDateFilter((filter) => {
        return {
          ...filter,
          rangeSelector: e.target.value,
        };
      });
    } else {
      setIsDateRange(false);
      setDateFilter((filter) => {
        return {
          ...filter,
          rangeSelector: null,
        };
      });
    }
  };

  const handleChange = (e) => {
    setDateFilter((filter) => {
      return {
        ...filter,
        [e.target.name]: e.target.value,
      };
    });
  };

  // const handleTrChange = (e) => {
  //     setDateFilter(filter => {
  //         filter.transactions = e.value
  //         return filter
  //     })
  // }

  const applyFilter = (e) => {
    e.preventDefault();
    setAccountHistory([]);
    setOpenBalance("");
    setCloseBalance("");
    setShowLoading(true);
    setShowTable(true);
    // dateFilter.accountId = selectAccount
    getAccountHistory(dateFilter)
      .then((v) => {
        const data = v.data.data;
        setAccountHistory(
          data.historyDto.map((h) => [
            stringDate(h.operationDate),
            h.description.toUpperCase(),
            h.reference,
            h.debit > 0 ? (
              <span className="fw-bold">
                {formatAmount(h.debit, data.currency)}
              </span>
            ) : (
              "---"
            ),
            h.credit > 0 ? (
              <span className="fw-bold">
                {formatAmount(h.credit, data.currency)}
              </span>
            ) : (
              "---"
            ),
            <span className="fw-bold">
              {formatAmount(h.closingBalance, data.currency)}
            </span>,
          ]),
        );
        setOpenBalance(data.openingBalance);
        setCloseBalance(data.closingBalance);
        setCurrency(data.currency);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setShowTable(false);
      });
  };

  const downloadStatementPdf = () => {
    setShowLoading(true);
    setLoadingDownload(true);
    statementReport(dateFilter)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "statement.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast(t("your_document_is_ready"));
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setLoadingDownload(false);
      });
  };

  const downloadEStatementPdf = () => {
    setShowLoading(true);
    setLoadingDownloadEStatement(true);
    eStatementReport(dateFilter)
      .then(() => {
        toast(t("your_document_is_ready"));
        setTimeout(() => {
          toast.success(t("check_email"), { position: "top-right" });
        }, 2000);
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setLoadingDownloadEStatement(false);
      });
  };

  const downloadAccountPdf = () => {
    setShowLoading(true);
    setLoadingDownload(true);
    accountReport(selectAccount)
      .then((res) => {
        // create blob
        const blob = new Blob([res.data], { type: "application/pdf" });

        // link for download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "account_report.pdf"); // name file
        document.body.appendChild(link);
        link.click(); // download
        document.body.removeChild(link);
        toast(t("your_document_is_ready"));
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      })
      .finally(() => {
        setShowLoading(false);
        setLoadingDownload(false);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="card-title mb-3">{displayTab}</h2>
        <div className="row top-50">
          <div className="col-xl-12">
            <div className="card shadow bg-white border-0">
              {/*<div className="card-header border-0 pb-0">*/}
              {/*    <h4 className="card-title">Account Details</h4>*/}
              {/*</div>*/}
              <div
                className="card-body"
                style={{
                  filter: "drop-shadow(5px 5px 5px #f1f1f1)",
                  background: "#fff",
                }}
              >
                <div className="custom-tab-1 bg-white">
                  <Tab.Container
                    activeKey={displayTab}
                    defaultActiveKey={displayTab}
                  >
                    <Nav as="ul" className="nav-tabs">
                      <Nav.Item
                        onClick={(e) => setDisplayTab("account-details")}
                        as="li"
                        key="account-details-nav"
                      >
                        <Nav.Link eventKey="account-details">
                          {t("account_details")}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item
                        onClick={(e) => setDisplayTab("account-statement")}
                        as="li"
                        key="account-statement-nave"
                      >
                        <Nav.Link eventKey="account-statement">
                          {t("account_tatement")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>

                    <Tab.Content className="pt-4">
                      <Tab.Pane
                        eventKey="account-details"
                        key={`content_account-details`}
                      >
                        <div className="row">
                          <div className="col-xl-4">
                            <SelectOption
                              className="fw-bold account-details"
                              label={t("select_an_account")}
                              options={optionAccounts}
                              onChange={handleAccountChange}
                              value={selectAccount}
                              name="transactionTypeId"
                            />
                          </div>
                        </div>
                        <div className="row pt-4">
                          <div className="col-xl-12">
                            <div className="row mx-auto">
                              {detailsContent
                                ? detailsContent.map((v, i) => (
                                    <div
                                      key={`group_${i}`}
                                      className={`col-xl-${i < 1 ? "12" : "6"} col-sm-12 border-custom ${i === 0 ? "first-object" : ""}`}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mt-2 fw-bold">
                                          {v.title}
                                        </h4>
                                        {i < 1 ? (
                                          <button
                                            className="d-flex align-items-center justify-content-center gap-2 btn btn-outline-primary btn-sm p-1 text-nowrap"
                                            disabled={showLoading}
                                            onClick={downloadAccountPdf}
                                          >
                                            {t("download_button")}
                                            {loadingDownload ? (
                                              <Spinners.TailSpin
                                                height="20"
                                                width="20"
                                                ariaLabel="tail-spin-loading"
                                                radius="4"
                                                color="#024B68"
                                              />
                                            ) : (
                                              <RemixIcon.RiFilePdf2Line
                                                size={20}
                                              />
                                            )}
                                          </button>
                                        ) : null}
                                      </div>

                                      <hr />
                                      <div className={`content${i}`}>
                                        {v.content.map((c, j) => (
                                          <div
                                            className="d-flex mb-3 "
                                            key={`detail_${j}`}
                                          >
                                            <div>
                                              <span className="fw-bold">
                                                {c.label}
                                              </span>
                                              <h6>
                                                <Link to="#">{c.value}</Link>
                                              </h6>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))
                                : ""}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="account-statement"
                        key={`content_account-statement`}
                      >
                        <form className="row" onSubmit={applyFilter}>
                          <div className="col-xl-4">
                            <SelectOption
                              className="account-details"
                              label={t("select_an_account")}
                              options={optionAccounts}
                              onChange={handleChange}
                              value={dateFilter.accountId}
                              name="accountId"
                            />
                          </div>
                          <div className="col-xl-4">
                            <SelectOption
                              className="account-details"
                              label={t("view_options")}
                              options={options1}
                              onChange={handleRangeSelectorChange}
                              value={dateFilter.rangeSelector}
                              name="range_selector"
                            />
                          </div>
                          <div className="col-xl-4">
                            <SelectOption
                              className="account-details"
                              label={t("transaction")}
                              options={options2}
                              onChange={handleChange}
                              value={dateFilter.transactions}
                              name="transactions"
                            />
                          </div>
                          {isDateRange ? (
                            <>
                              <div className="col-xl-4 mt-1">
                                <FormInput
                                  label={t("from")}
                                  data={options1}
                                  onchange={handleChange}
                                  value={dateFilter.openingDate}
                                  name="openingDate"
                                  type="date"
                                />
                              </div>
                              <div className="col-xl-4">
                                <FormInput
                                  label={t("to")}
                                  data={options1}
                                  onchange={handleChange}
                                  value={dateFilter.closingDate}
                                  name="closingDate"
                                  type="date"
                                />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div className="col-xl-4 d-flex align-items-center ">
                            <button
                              className="btn btn-primary btn-sm me-3"
                              disabled={showLoading}
                            >
                              {t("apply_filter_button")}
                            </button>
                          </div>
                        </form>
                        <div className="row p-3 pb-0 ">
                          <div className="card border-custom rounded-0">
                            <div className="row card-header border-0 pb-1">
                              <div className="col-xl-4 d-flex align-items-center">
                                <p className="me-2 fw-bold">
                                  {t("opening_balance")}{" "}
                                </p>
                                <p>
                                  {openingBalance !== undefined
                                    ? formatAmount(openingBalance, currency)
                                    : ""}
                                </p>
                              </div>
                              <div className="col-xl-4 d-flex align-items-center">
                                <p className="me-2 fw-bold">
                                  {t("closing_balance")}{" "}
                                </p>
                                <p>
                                  {closingBalance !== undefined
                                    ? formatAmount(closingBalance, currency)
                                    : ""}
                                </p>
                              </div>
                              <div className="col-xl-4 fw-bold d-flex align-items-center justify-content-end">
                                {accountHistory && accountHistory.length > 0 ? (
                                  <button
                                    disabled={showLoading}
                                    onClick={downloadStatementPdf}
                                    className="d-flex align-items-center justify-content-center gap-2  btn btn-outline-primary text-nowrap btn-sm me-2"
                                  >
                                    {t("download_button")}{" "}
                                    {loadingDownload ? (
                                      <Spinners.TailSpin
                                        height="20"
                                        width="20"
                                        ariaLabel="tail-spin-loading"
                                        radius="4"
                                        color="#024B68"
                                      />
                                    ) : (
                                      <RemixIcon.RiFilePdf2Line size={20} />
                                    )}
                                  </button>
                                ) : null}
                                {accountHistory && accountHistory.length > 0 ? (
                                  <button
                                    disabled={showLoading}
                                    onClick={downloadEStatementPdf}
                                    className="d-flex align-items-center justify-content-center gap-2  btn btn-outline-primary text-nowrap btn-sm"
                                  >
                                    {t("e_statement")}{" "}
                                    {loadingDownloadEStatement ? (
                                      <Spinners.TailSpin
                                        height="20"
                                        width="20"
                                        ariaLabel="tail-spin-loading"
                                        radius="4"
                                        color="#024B68"
                                      />
                                    ) : (
                                      <RemixIcon.RiMailDownloadLine size={20} />
                                    )}
                                  </button>
                                ) : null}
                              </div>
                            </div>
                            <div className="stetment">
                              {showTable ? (
                                <p className="text-center d-flex align-items-center justify-content-center gap-2">
                                  Loading{" "}
                                  <Spinners.TailSpin
                                    height="20"
                                    width="20"
                                    ariaLabel="tail-spin-loading"
                                    radius="4"
                                    color="#024B68"
                                  />
                                </p>
                              ) : (
                                <DataTable
                                  headersTitle={tableHeaders}
                                  tableData={accountHistory}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userDao,
  };
};
export default connect(mapStateToProps)(AccountDetailsPage);
