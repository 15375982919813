import React from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ADMINPORTAL,
  CUSTOMERPORTAL,
  isAuthenticated,
} from "../../../store/selectors/authSelectors";
import { SVGICON } from "../../../constant/theme";
import { Logout } from "../../../services";
import { store } from "../../../store";
import { baseUrlAdmin, baseUrlClient } from "../../../constant";
import { useTranslation } from "react-i18next";

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
};

const LogoutPage = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storeState = store.getState();
  const accessPortal = storeState.auth.accessPortal;

  const onLogout = () => {
    const confirmLogout = window.confirm(t("user_logout_warning"));
    if (confirmLogout) {
      if (accessPortal === CUSTOMERPORTAL) {
        dispatch(Logout(navigate, baseUrlClient));
      } else if (accessPortal === ADMINPORTAL) {
        dispatch(Logout(navigate, baseUrlAdmin));
      }
    }
  };

  return (
    <>
      <button
        className="dropdown-item ai-icon ms-1 logout-btn"
        onClick={onLogout}
      >
        {SVGICON.Logout} <span className="ms-2">{t("user_logout_btn")}</span>
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LogoutPage));
