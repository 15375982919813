/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Benefit from "../../../common/images/svg/benefit.svg";
import {
  getConfigurations,
  listSecurityQuestions,
  setSecurityQuestions,
  updateVerificationQuestions,
} from "../../services/clientAuthService";
import { FormInput } from "../../../common/jsx/components/FormInput";
import { ThemeContext } from "../../../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Logout } from "../../../common/services";
import ErrorManager from "../../errors";
import { baseUrlClient, userDetailsKey } from "../../../common/constant";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as RemixIcon from "react-icons/ri";

const SetSecurityQuestion = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    showLoading,
    setShowLoading,
    setVerifySecureQuestions,
    setVerifyCallback,
  } = useContext(ThemeContext);
  const [questCount, setQuestCount] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [inputGroups, setInputGroups] = useState([]);
  const [questionPairs, setQuestionPairs] = useState([]);
  const [instConfig, setInstConfig] = useState({});
  const [canSubmit, setCanSubmit] = useState(true);
  const [answerVisibility, setAnswerVisibility] = useState({});

  const userDetails = sessionStorage.getItem(userDetailsKey);

  const props = JSON.parse(userDetails);
  const getPageData = () => {
    setShowLoading(false);
    return listSecurityQuestions(props.authInfo.token)
      .then((v) => {
        const data = v.data.data;
        const options = data.map((q) => ({
          label: q.question,
          value: q.id,
        }));
        setQuestions(options);
        return options;
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  useEffect(() => {
    if (props) {
      if (props.userDao.securityQuestionCounts > 0) {
        Logout(navigate, baseUrlClient);
      }
    } else {
      Logout(navigate, baseUrlClient);
    }

    getConfigurations()
      .then((v) => {
        const data = v.data.data;
        setInstConfig(data);
        setQuestCount(data.minSecurityQuest);
        setShowLoading(true);
        getPageData()
          .then((v) => {
            const initialPairs = [];
            for (let i = 1; i <= data.minSecurityQuest; i++) {
              initialPairs.push({
                id: i,
                questionId: 0,
                answer: "",
              });
            }
            setInputGroups(initialPairs.map((pair) => pair.id));
            setQuestionPairs(initialPairs);
          })
          .catch((err) => {
            ErrorManager(err, navigate);
          })
          .finally(() => {
            setShowLoading(false);
          });
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }, [navigate]);

  const addQuestion = () => {
    const index = questCount + 1;
    if (index <= instConfig.maxSecurityQuest) {
      setQuestCount(index);
      setInputGroups([...inputGroups, index]);
      setQuestionPairs([
        ...questionPairs,
        { id: index, questionId: 0, answer: "" },
      ]);
      checkFormValidity();
    }
  };

  const removeQuestion = () => {
    const index = questCount;
    if (index > instConfig.minSecurityQuest) {
      setQuestCount(index - 1);
      const updatedGroups = inputGroups.slice(0, -1);
      const updatedPairs = questionPairs.slice(0, -1);
      setInputGroups(updatedGroups);
      setQuestionPairs(updatedPairs);
      filterQuestions(updatedPairs);
      checkFormValidity();
    }
  };

  const answerChange = (e, id) => {
    const updatedPairs = questionPairs.map((pair) =>
      pair.id === id ? { ...pair, answer: e.target.value } : pair,
    );
    setQuestionPairs(updatedPairs);
    checkFormValidity();
  };

  const questionChange = (e, id) => {
    const updatedPairs = questionPairs.map((pair) =>
      pair.id === id ? { ...pair, questionId: e ? e.value : null } : pair,
    );
    setQuestionPairs(updatedPairs);
    filterQuestions(updatedPairs);
    checkFormValidity();
  };

  const checkFormValidity = () => {
    const allAnswersNotEmpty = questionPairs.every(
      (pair) => pair.answer.trim() !== "",
    );
    const allQuestionsSelected = questionPairs.every(
      (pair) => pair.questionId !== null && pair.questionId !== 0,
    );

    const isDisabled = !allAnswersNotEmpty || !allQuestionsSelected;
    setCanSubmit(isDisabled);
  };

  const filterQuestions = (pairs) => {
    const selectedIds = pairs.map((v) => v.questionId);
    const availableQuestions = questions.filter(
      (v) => !selectedIds.some((sel) => v.value === sel),
    );
    return availableQuestions;
  };

  const setVerificationQuestion = () => {
    setShowLoading(true);
    return setSecurityQuestions(questionPairs)
      .then((res) => {
        if (props.userDao.securityQuestionCounts === 0) {
          dispatch(Logout(navigate, baseUrlClient));
          toast.success(t("first_login_success1"), { position: "top-right" });
          setTimeout(() => {
            toast.success(t("first_login_success2"), { position: "top-right" });
          }, 1000);
        } else {
          navigate("/home");
        }
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const executeUpdateVerificationQuestion = (securityQuestion) => {
    setShowLoading(true);
    return updateVerificationQuestions(questionPairs, securityQuestion)
      .then((res) => {
        navigate("/home");
        toast.success(t("first_login_success1"), { position: "top-right" });
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (props.userDao.securityQuestionCounts > 0) {
      setVerifyCallback(executeUpdateVerificationQuestion);
      setVerifySecureQuestions(true);
    } else {
      setVerificationQuestion();
    }
  };

  const toggleAnswerVisibility = (id) => {
    setAnswerVisibility((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column justify-content-center">
      <div className="card bg-white border-0 mt-3 shadow card-2-custom">
        <h3 className="fw-bold p-3 pb-0"> {t("modal_set_quest")}</h3>
        <div className="card-body">
          <div className="row">
            <div className="col-xl-8" key={"security_questions"}>
              <form onSubmit={handleSubmit} className="border-custom p-3 h-100">
                {inputGroups.map((el, index) => {
                  const availableQuestions = filterQuestions(
                    questionPairs.filter((pair) => pair.id !== el),
                  );
                  return (
                    <div className="row" key={"group" + index}>
                      <div className="col-xl-6" key={`question_${el}`}>
                        <FormInput
                          onchange={(e) => questionChange(e, el)}
                          classes={" masked-input group" + el}
                          key={`question_input_${el}`}
                          label={"Security Question " + el}
                          name={"security_question-" + el}
                          type="select"
                          data={availableQuestions}
                        />
                      </div>
                      <div
                        className="col-xl-6 form-group position-relative"
                        key={`answer_${el}`}
                      >
                        <FormInput
                          onchange={(e) => answerChange(e, el)}
                          classes={`group${el} ${answerVisibility[el] ? "" : "masked-input"}`}
                          key={`answer_input_${el}`}
                          label={t("security_answer") + el}
                          name={"security_answer-" + el}
                          type="text"
                        />
                        {questionPairs[index]?.answer !== "" && (
                          <RemixIcon.RiEyeLine
                            className="position-absolute"
                            style={{
                              right: "25px",
                              top: "40px",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleAnswerVisibility(el)}
                          />
                        )}
                        {answerVisibility[el] && (
                          <RemixIcon.RiEyeOffLine
                            className="position-absolute"
                            style={{
                              right: "25px",
                              top: "40px",
                              cursor: "pointer",
                            }}
                            onClick={() => toggleAnswerVisibility(el)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="d-flex justify-content-end text-end">
                  {questCount > instConfig.minSecurityQuest ? (
                    <button
                      type="button"
                      onClick={removeQuestion}
                      className="btn btn-danger btn-sm me-2"
                    >
                      {t("remove_button")}
                    </button>
                  ) : (
                    ""
                  )}

                  {questCount < instConfig.maxSecurityQuest ? (
                    <button
                      type="button"
                      disabled={showLoading}
                      onClick={addQuestion}
                      className="btn btn-primary d-flex btn-sm"
                    >
                      {t("add_button")}
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mt-3">
                  <button
                    className="btn btn-primary btn-sm me-3"
                    disabled={showLoading || canSubmit}
                  >
                    {t("save_button")}
                  </button>
                  {/* <button className='btn btn-outline-primary btn-sm'>Cancel</button> */}
                </div>
              </form>
            </div>

            <div className="col-xl-4">
              <div className="card-body border-0">
                <div className="media me-2 pb-5 d-flex justify-content-center">
                  <img width="100" height="100" src={Benefit} alt="benefit" />
                </div>
                <div
                  style={{ height: "300px" }}
                  className="widget-media dz-scroll height270  ps--active-y"
                >
                  <div className="text-center">
                    <h3>{t("user_sec_ques")} </h3>
                  </div>
                  <ul>
                    <li key="desc_1" className="pb-2">
                      <strong>{t("enter_ques_instructn")}</strong>
                    </li>
                    <li key="desc_2">{t("sec_ques_benefit")}</li>
                    <h4 className="pt-3 pb-1">{t("change_pass_force")}</h4>
                    <li key="desc_3" className="pb-2">
                      {t("instruction_one")}{" "}
                    </li>
                    <li key="desc_4">{t("instruction_two")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDto: state.auth.userDao,
  };
};

export default connect(mapStateToProps)(SetSecurityQuestion);
