import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import { FormInput } from "../../common/jsx/components/FormInput";
import { getAppRoles, saveNewUser } from "../services/authService";
import ErrorManager from "../../appClient/errors";
import { t } from "i18next";

const status = [
  {
    label: t("active"),
    value: "ACTIVE",
  },
  {
    label: t("inactive"),
    value: "INACTIVE",
  },
];

const EmployeeOffcanvas = forwardRef((props, ref) => {
  const [addEmployee, setAddEmployee] = useState(false);
  const [roleListData, setRoleListData] = useState([]);

  const Navigate = useNavigate;

  useImperativeHandle(ref, () => ({
    showEmployeModal() {
      setAddEmployee(true);
    },
  }));
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    saveNewUser(data).then((v) => {
      setAddEmployee(false);
      props.getEmployeeList();
    });
  };

  useEffect(() => {
    getAppRoles()
      .then((v) => {
        const data = v.data.data;
        setRoleListData(
          data.map((r) => ({
            label: r.name,
            value: r.id,
          })),
        );
      })
      .catch((err) => {
        ErrorManager(err, Navigate);
      });
  }, []);

  return (
    <>
      <Offcanvas
        show={addEmployee}
        onHide={setAddEmployee}
        className="offcanvas-end bg-white"
        placement="end"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="modal-title" id="#gridSystemModal">
            {props.Title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddEmployee(false)}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="container-fluid">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <FormInput label={t("employee_id")} name="userLogin" />
                </div>
                <div className="col-xl-12">
                  <FormInput
                    label={t("user_role")}
                    name="userRoleId"
                    type="select"
                    data={roleListData}
                  />
                </div>
                <div className="col-xl-12">
                  <FormInput label={t("password")} name="password" />
                </div>
                <div className="col-xl-12">
                  <FormInput
                    label={t("confirm_password")}
                    name="confirm_password"
                  />
                </div>
                <div className="col-xl-12">
                  <FormInput
                    label={t("status")}
                    data={status}
                    name="status"
                    type="select"
                  />
                </div>
              </div>
              <div>
                <button type="submit" className="btn btn-primary btn-sm me-1">
                  {t("submit_button")}
                </button>
                <Link
                  to={"#"}
                  onClick={() => setAddEmployee(false)}
                  className="btn btn-outline-danger btn-sm ms-1"
                >
                  {t("cancel_button")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </Offcanvas>
    </>
  );
});

export default EmployeeOffcanvas;
