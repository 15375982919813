/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle";
import * as MaterialDesignIcon from "react-icons/md";
import { LuClipboardPaste } from "react-icons/lu";
import { LuClipboardCopy } from "react-icons/lu";
import { LuClipboardX } from "react-icons/lu";
import { LuCopyX } from "react-icons/lu";
import { BiRotateRight } from "react-icons/bi";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { dashboardStatus } from "../../services/subscriptionService";
import ErrorManager from "../../../appClient/errors";
import { ThemeContext } from "../../../context/ThemeContext";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const SubscriptionDashBoard = () => {
  const { setShowLoading } = useContext(ThemeContext);
  const [dashboardData, setDashboardData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    dashboardStatus()
      .then((res) => {
        setDashboardData(res.data.data);
      })
      .catch((err) => {
        ErrorManager(err, navigate);
      })
      .finally(() => {
        setShowLoading(false);
      });
  }, [navigate]);

  return (
    <>
      <MainPageTitle
        mainTitle="Subscribtions"
        pageTitle="Dashboard"
        parentTitle="Subscribtions"
      />
      <div className="container-fluid">
        <div className="row gap-4 p-3 pt-0">
          <div className="col-xl-9 flex-grow-1">
            <div className="row gap-4">
              <div className="col-xl-5 d-flex flex-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="details d-flex flex-column gap-3">
                  <span>{t("totalpending")}</span>
                  <span>{dashboardData.totalPending}</span>
                </div>
                <MaterialDesignIcon.MdPendingActions size={50} />
              </div>
              <div className="col-xl-5 d-flex flex-xl-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="details d-flex flex-column gap-3">
                  <span>{t("totalrejected")}</span>
                  <span>{dashboardData.totalRejected}</span>
                </div>
                <LuClipboardCopy size={50} />
              </div>
              <div className="col-xl-5 d-flex flex-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="details d-flex flex-column gap-3">
                  <span>{t("subscription_active")}</span>
                  <span>{dashboardData.subActive}</span>
                </div>
                <HiMiniClipboardDocumentCheck size={50} />
              </div>
              <div className="col-xl-5 d-flex flex-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="details d-flex flex-column gap-3">
                  <span>{t("subscription_inactive")}</span>
                  <span>{dashboardData.subInActive}</span>
                </div>
                <LuCopyX size={50} />
              </div>
              <div className="col-xl-5 d-flex flex-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="d-flex flex-column gap-3">
                  <span>{t("subscription_pending")}</span>
                  <span>{dashboardData.subPending}</span>
                </div>
                <BiRotateRight size={50} />
              </div>
              <div className="flex-grow-1 col-xl-5 d-flex justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
                <div className="d-flex flex-column gap-3">
                  <span>{t("subscription_suspended")}</span>
                  <span>{dashboardData.subSuspended}</span>
                </div>
                <LuClipboardX size={50} />
              </div>
            </div>
          </div>
          <div className="col-xl-5 d-flex flex-grow-1 justify-content-between align-items-center pt-2 pb-2 bg-white shadow">
            <div className="d-flex flex-column gap-3">
              <span>{t("total_approved")}</span>
              <span>{dashboardData.totalApproved}</span>
            </div>
            <LuClipboardPaste size={50} />
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscriptionDashBoard;
