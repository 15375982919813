import React, { createContext, useEffect, useReducer } from "react";
import i18next from "i18next";

export const ThemeContext = createContext();

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  sidebarposition: { value: "fixed", label: "Fixed" },
  sidebarLayout: { value: "vertical", label: "Vertical" },
  showLoading: true,
  tableLoading: false,
  verifySecureQuestions: false,
  verificationCallBack: Promise,
  windowWidth: 0,
  windowHeight: 0,
  verifyOtp: false,
  otpVerificationCallBack: undefined,
  walletOpLoading: false,
  walletOp: undefined,
  walletDeposit: undefined,
  showFooter: true,
  lang: sessionStorage.getItem("lang") || "en",
  securityQuestionUrl: "",
};

const ThemeContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    sidebarposition,
    sidebarLayout,
    showLoading,
    tableLoading,
    verificationCallBack,
    verifySecureQuestions,
    verifyOtp,
    otpVerificationCallBack,
    walletOpLoading,
    walletOp,
    walletDeposit,
    showFooter,
    lang,
    securityQuestionUrl,
  } = state;

  const setShowLoading = (status) => {
    dispatch({ showLoading: status });
  };

  const setTableLoading = (status) => {
    dispatch({ tableLoading: status });
  };

  const setVerifySecureQuestions = (status) => {
    dispatch({ verifySecureQuestions: status });
  };

  const setVerifyCallback = (callbackFunction) => {
    dispatch({ verificationCallBack: callbackFunction });
  };

  const setVerifyOtp = (status) => {
    dispatch({ verifyOtp: status });
  };

  const setOtpVerificationCallBack = (callbackFunction) => {
    dispatch({ otpVerificationCallBack: callbackFunction });
  };

  const setWalletOpLoading = (status) => {
    dispatch({ walletOpLoading: status });
  };

  const setWalletOp = (opObject) => {
    dispatch({ walletOp: opObject });
  };

  const setWalletDeposit = (opObject) => {
    dispatch({ walletDeposit: opObject });
  };

  const setShowFooter = (status) => {
    dispatch({ showFooter: status });
  };

  const setLang = (newLang) => {
    if (newLang !== lang) {
      setShowLoading(true);
      sessionStorage.setItem("lang", newLang);
      setTimeout(() => {
        dispatch({ lang: newLang });
        i18next.changeLanguage(newLang);
        setShowLoading(false);
      }, 1500);
    }
  };

  const setSecurityQuestionUrl = (value) => {
    dispatch({ securityQuestionUrl: value });
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.setAttribute("data-typography", "opensans");
    body.setAttribute("data-theme-version", "light");
    body.setAttribute("data-layout", "vertical");
    body.setAttribute("data-primary", "color_1");
    body.setAttribute("data-nav-headerbg", "color_3");
    body.setAttribute("data-headerbg", "color_5");
    body.setAttribute("data-sidebar-style", "overlay");
    body.setAttribute("data-sidebarbg", "color_3");
    body.setAttribute("data-secondary", "color_1");
    body.setAttribute("data-sidebar-position", "fixed");
    body.setAttribute("data-header-position", "fixed");
    body.setAttribute("data-container", "wide");
    body.setAttribute("direction", "ltr");

    let resizeWindow = () => {
      dispatch({ windowWidth: window.innerWidth });
      dispatch({ windowHeight: window.innerHeight });
      if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        body.setAttribute("data-sidebar-style", "mini");
      } else if (window.innerWidth <= 768) {
        body.setAttribute("data-sidebar-style", "overlay");
        body.setAttribute("data-layout", "vertical");
      } else {
        body.setAttribute("data-sidebar-style", "full");
      }
    };

    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        sidebarposition,
        sidebarLayout,
        showLoading,
        setShowLoading,
        tableLoading,
        setTableLoading,
        verifySecureQuestions,
        setVerifySecureQuestions,
        setVerifyCallback,
        verificationCallBack,
        verifyOtp,
        setVerifyOtp,
        setOtpVerificationCallBack,
        otpVerificationCallBack,
        walletOpLoading,
        walletOp,
        setWalletOp,
        walletDeposit,
        setWalletDeposit,
        setWalletOpLoading,
        showFooter,
        setShowFooter,
        lang,
        setLang,
        securityQuestionUrl,
        setSecurityQuestionUrl,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
